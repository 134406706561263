import React, { useRef, useEffect } from "react";
import Section from "../general/Section";
import SectionHeader from "../general/SectionHeader";
import { useHistory } from "../../util/router";
// import UserMetricsDashboard from "./UserMetricsDashboard";
import { completeOnboarding } from "../../util/db";
import { useAuth } from "../../util/auth";
// import { useMetricsByOwner } from "../../util/db";
// import GaugeChart from "./GaugeChart";
// import HistogramChart from "./HistogramChart";
import RefsContext from "../../util/RefsContext";


function DashboardSection(props) {
  const auth = useAuth();
  // const ownerId = auth.user?.uid;
  // const [userMetricsData, setUserMetricsData] = useState(null);
  const elementRef = useRef(null);
  const titleRef = useRef(null); // Ref for the title
  const subtitleRef = useRef(null);
  const history = useHistory();

  useEffect(() => {

  }, []); // Empty dependency array to log only on initial mount


  const shouldRenderButton = props.showButton;

  const handleSaveMetrics = async () => {
    try {
      if (auth?.user?.id) {
        await completeOnboarding(auth.user.id);
      } else {
        console.error("User ID not available for onboarding completion");
      }
      history.push("/dashboard");
    } catch (error) {
      console.error("Failed to complete onboarding:", error);
    }
  };

  // Rob  code set: Gauge chart value to be {radarValue}
  // useEffect(() => {
  //   if (data) {
  //     // Set userMetricsData when data is available
  //     setUserMetricsData(data);

  //     // Calculate average of all metrics for display
  //     loopData = 0;
  //     for (var i = 0; i < metrics.length; i++) {
  //       loopData += data[0][metrics[i]];
  //     }
  //     setRadarValue(Math.round(loopData / metrics.length));
  //   }
  // }, [data]);

  return (
    <RefsContext.Provider value={{ titleRef, subtitleRef }}>
      <Section
        size={props.size}
        bgColor={props.bgColor}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        textColor={props.textColor}
      >

        <div className="container" ref={elementRef}>
          <SectionHeader
            title={props.title} // Assuming title is passed down as a prop
            subtitle={props.subtitle} // Assuming subtitle is passed down as a prop
            strapline={props.strapline} // Assuming strapline is passed down as a prop
            titleRef={titleRef}
            subtitleRef={subtitleRef}
            className="text-center"
          />
          <div className="flex flex-wrap">
            <div className="p-1 w-full justify-center">

              <div className="flex items-center justify-center">
              </div>
              <div className="w-full">
                {props.renderComponent && (
                  <props.renderComponent onNextStep={handleSaveMetrics} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </RefsContext.Provider>
  );
}

export default DashboardSection;
