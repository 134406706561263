import React, {useState} from "react";
import Button from "../cosmetics/Button";
import Container from "./Container";
import LogoCloud from "./LogoCloud";
import HeroScreenshot from "../../images/screenshots/main-dashboard-screenshot.webp"
import VideoModal from "../modals/videoModal";

function Hero(props) {

  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

  const videoUrl = "https://www.youtube.com/embed/uwA5HJUz1xY?autoplay=1&vq=highres";
  return (
    <Container
    >
      {/* Hero section */}
      <div className="relative pt-14">
        
        <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            
            <div className="mx-auto max-w-3xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Benchmark Your Startup's People And Talent Metrics                </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Secure internal budget, build your team's data muscle and give your stakeholders the context they crave with a set of startup specific benchmarks. Early access unlocks the limited offer of £500 for year 1.

              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button variant="gradient" href="https://form.typeform.com/to/HeedvLiM">Get early access</Button>
                <button onClick={() => setModalOpen(true)} className="text-sm font-semibold leading-6 text-gray-900">
                  Watch Demo <span aria-hidden="true">→</span>
                </button>
              </div>
            </div>
            <div className="mt-16 flow-root sm:mt-24">
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  src={HeroScreenshot}
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
          </div>
        </div>
        <LogoCloud></LogoCloud>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      <VideoModal isOpen={modalOpen} onClose={() => setModalOpen(false)} videoUrl={videoUrl} />

    </Container>

  );
}

export default Hero;

