import React, { useEffect, forwardRef, useState } from 'react';
import { useRouter } from '../../util/router';
import { UsersIcon, ArrowTrendingUpIcon, ArrowLongRightIcon, ArrowTrendingDownIcon } from '@heroicons/react/20/solid';
import StatusPill from './StatusPill';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { history } from '../../util/router';
import { useMetrics } from '../../util/UserMetricsContext';
import useFeatureGate from '../../util/FeatureGate';
import UpgradePlanModal from '../modals/UpgradePlanModal';
import { stats, columnMapping } from '../../util/MetricsConfig';

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const UserMetricCard = forwardRef(({ metric, selectedHeadcount, selectedStage, selectedLocation, userPercentile, sampleSize, setSelectedMetricData, quarterlyTrend }, ref) => {
  const IconComponent = metric.icon || UsersIcon;
  const isMetricValueNA = metric.stat === 'N/A';
  const { push } = useRouter();
  const { metricsData, setMetricsData } = useMetrics();
  const { checkAccess, modalOpen, setModalOpen } = useFeatureGate(['active', 'trialing']);

  const invertedMetrics = ['Time To Offer', 'Cost Of Hire', 'Regrettable Attrition', 'Absenteeism Rate', 'IC : Manager Ratio', 'Time To Fill', 'General & Administrative Burn Rate', 'Early Attrition'];
  const isMetricInverted = invertedMetrics.includes(metric.name);

  const countMetricsAdded = (data) => {
    return Object.values(data).reduce((count, value) => {
      if (value !== null && typeof value === 'number') {
        count++;
      }
      return count;
    }, 0);
  };

  const countMetricsAddedByUser = countMetricsAdded(metricsData.userMetricsData);

  useEffect(() => {
    
  }, [selectedStage, selectedHeadcount, selectedLocation, metric]);

  useEffect(() => {
    
  }, [metric, userPercentile, quarterlyTrend]);

  const Tooltip = ({ children }) => (
    <div className="absolute -top-16 right-2 transform -translate-x-1/2 w-auto p-2 bg-indigo-500 text-white text-xs rounded-md z-10">
      {children}
    </div>
  );

  const [showTooltip, setShowTooltip] = useState(false);

  const renderSampleSizeCircles = (sampleSize) => {
    const circles = [];
    let filledCircles = 0;

    if (sampleSize < 50) filledCircles = 1;
    else if (sampleSize >= 50 && sampleSize < 200) filledCircles = 2;
    else if (sampleSize >= 200) filledCircles = 3;

    for (let i = 0; i < 3; i++) {
      circles.push(
        <div key={i} className={`h-2 w-2 rounded-full ml-1 ${i < filledCircles ? 'bg-gray-500 hover:bg-indigo-500' : 'bg-gray-300'}`}></div>
      );
    }
    return circles;
  };

  const metricConfig = stats.find(stat => columnMapping[stat.name] === metric.id);
  const isOpinionated = metricConfig?.opinionated ?? true; // Default to true if not specified

  const getTrendColor = (changeType) => {
    if (!metric.opinionated) return 'text-indigo-600';
    if (isMetricInverted) {
      return changeType === 'increase' ? 'text-red-600' : 'text-green-600';
    }
    return changeType === 'increase' ? 'text-green-600' : 'text-red-600';
  };

  const getTrendBackgroundColor = (changeType) => {
    if (!metric.opinionated) return 'bg-indigo-100';
    if (isMetricInverted) {
      return changeType === 'increase' ? 'bg-red-100' : 'bg-green-100';
    }
    return changeType === 'increase' ? 'bg-green-100' : 'bg-red-100';
  };

  const renderButtonContent = () => {
    return (
      <>
         <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className={`rounded-md p-2 ${isMetricValueNA ? 'bg-slate-500' : 'bg-indigo-500'}`}>
              <IconComponent className={`h-${isMetricValueNA ? '3' : '3'} w-${isMetricValueNA ? '3' : '3'} text-white`} />
            </div>
            <p className="ml-3 text-sm font-medium text-gray-500">{metric.name}</p>
          </div>
          {!isMetricValueNA && (
            <div className="flex flex-col items-center">
              {quarterlyTrend !== null ? (
                <div
                  className={classNames(
                    getTrendBackgroundColor(quarterlyTrend.changeType),
                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium'
                  )}
                >
                  {quarterlyTrend.changeType === 'increase' ? (
                    <ArrowTrendingUpIcon className={`-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center ${getTrendColor(quarterlyTrend.changeType)}`} aria-hidden="true" />
                  ) : quarterlyTrend.changeType === 'decrease' ? (
                    <ArrowTrendingDownIcon className={`-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center ${getTrendColor(quarterlyTrend.changeType)}`} aria-hidden="true" />
                  ) : (
                    <ArrowLongRightIcon className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-gray-500" aria-hidden="true" />
                  )}
                  <span className={getTrendColor(quarterlyTrend.changeType)}>{quarterlyTrend.change}</span>
                </div>
              ) : (
                // john you can style the trend arrow here for non 'current quarter metric cards'
                <div className="">
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-3 flex items-baseline">
          {isMetricValueNA ? renderNAMetric() : renderAvailableMetric()}
        </div>

        <div className="mt-4 flex items-center">
          {isMetricValueNA ? renderAddData() : renderViewResults()}
        </div>

        <div
          className="absolute bottom-2 right-2 flex items-center justify-center cursor-pointer"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {renderSampleSizeCircles(sampleSize)}
          {showTooltip && <Tooltip>Sample Size: {sampleSize} companies</Tooltip>}
        </div>
      </>
    );
  };

  const renderNAMetric = () => (
    <>
      <span className="ml-2 block text-sm font-semibold text-gray-900">Data</span>
      <div className="ml-4">
        <StatusPill
          metricName={metric.name}
          selectedHeadcount={selectedHeadcount}
          selectedLocation={selectedLocation}
          selectedStage={selectedStage}
          userPercentile={userPercentile}
        />
      </div>
    </>
  );

  const renderAvailableMetric = () => (
    <>
      <div className="flex items-center">
        {metric.unitLocation === 'front' && (
          <p className="mr-2 text-sm font-medium text-gray-600">{metric.unit}</p>
        )}
        <p className="text-lg font-semibold text-gray-900">{metric.stat}</p>
        {metric.unitLocation === 'end' && (
          <p className="ml-2 text-sm font-medium text-gray-600">{metric.unit}</p>
        )}
      </div>
      <div className="ml-4">
        <StatusPill
          metricValue={metric.percentile}
          metricName={metric.name}
          selectedHeadcount={selectedHeadcount}
          selectedLocation={selectedLocation}
          selectedStage={selectedStage}
          userPercentile={userPercentile}
        />
      </div>
    </>
  );

  const renderAddData = () => (
    <>
      <PlusCircleIcon className="ml-0 text-slate-600 h-6 w-6" />
      <span className="ml-2 text-slate-600 hover:text-indigo-500 font-medium text-xs focus:outline-none cursor-pointer">
        Add this quarter's data to unlock<span className="sr-only"> {metric.name} stats</span>
      </span>
    </>
  );

  const renderViewResults = () => (
    <span className="text-indigo-600 hover:text-indigo-500 font-medium text-xs focus:outline-none cursor-pointer">
      View your results<span className="sr-only"> {metric.name} stats</span>
    </span>
  );

  const handleButtonClick = () => {
    if (isMetricValueNA) {
      if (countMetricsAddedByUser >= 3 && !checkAccess()) {
        setModalOpen(true);
      } else {
        push('/edit-metrics');
      }
    } else {
      setSelectedMetricData(metric);
      setMetricsData(prevMetricsData => ({
        ...prevMetricsData,
        selectedMetric: metric,
      }));
      const updatedMetricString = JSON.stringify(metric);
      localStorage.setItem('selectedMetric', updatedMetricString);
      history.push(`/dashboard/metrics/${metric.name}`);
    }
  };

  return (
    <button
      ref={ref}
      onClick={handleButtonClick}
      className={`relative block w-full rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${isMetricValueNA ? 'border-2 border-dashed border-gray-300 p-2 text-center hover:border-gray-400' : 'overflow-hidden rounded-lg bg-white p-2 hover:bg-gray-100 hover:ring-2 hover:ring-indigo-500 cursor-pointer transition duration-300'}`}
    >
      {renderButtonContent()}
      {modalOpen && <UpgradePlanModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />}
    </button>
  );
});

export default UserMetricCard;