import React from "react";

const PercentileStatusPill = ({ userPercentile, metricName }) => {
  let bgColor = "";
  let textColor = "";

  const invertedMetrics = ['Time To Offer', 'Cost Of Hire', 'Regrettable Attrition', 'Absenteeism Rate','IC : Manager Ratio', 'Time To Fill', 'General & Administrative Burn Rate', 'Early Attrition'];
  const isMetricInverted = invertedMetrics.includes(metricName);

  // Function to get the appropriate suffix for a number
  const getNumberSuffix = (number) => {
    if (number >= 11 && number <= 13) {
      return 'th';
    }
    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // Check if userPercentile is undefined or not a number
  if (userPercentile == null || isNaN(userPercentile)) {
    bgColor = "bg-gray-100";
    textColor = "text-gray-600";
  } else if (isMetricInverted) {
    // Inverted metrics logic
    if (userPercentile >= 75) {
      bgColor = "bg-red-100";
      textColor = "text-red-700";
    } else if (userPercentile >= 40) {
      bgColor = "bg-yellow-100";
      textColor = "text-yellow-800";
    } else {
      bgColor = "bg-green-100";
      textColor = "text-green-700";
    }
  } else {
    // Normal metrics logic
    if (userPercentile >= 75) {
      bgColor = "bg-green-100";
      textColor = "text-green-700";
    } else if (userPercentile >= 40) {
      bgColor = "bg-yellow-100";
      textColor = "text-yellow-800";
    } else {
      bgColor = "bg-red-100";
      textColor = "text-red-700";
    }
  }

  // Status text
  const statusText = userPercentile != null && !isNaN(userPercentile)
    ? `${userPercentile}${getNumberSuffix(userPercentile)} percentile`
    : "Unavailable";

  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-full ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}
    >
      {statusText}
    </span>
  );
};

export default PercentileStatusPill;
