import React from "react";
import Meta from "../components/settings/Meta";
import Hero from "../components/landingPage/Hero";
import { PrimaryFeatures } from "../components/landingPage/PrimaryFeatures";
import FAQSection from "../components/landingPage/FAQSection";
import TestimonialWall from "../components/landingPage/TestimonialWall";
import HowItWorksSection from "../components/landingPage/HowItWorksSection";

function IndexPage(props) {
  return (

    <>

      <Meta />
      <Hero />
      <PrimaryFeatures />
      <HowItWorksSection />
      <TestimonialWall />
      <FAQSection />
    </>
  );
}

export default IndexPage;
