
import WaitlistWelcome from './WaitlistWelcome';

export function WelcomeLayout({ children }) {
  return (
    <>
          <WaitlistWelcome></WaitlistWelcome>
    </>
  );
}
