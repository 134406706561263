import React, { useState } from "react";
import {
  ChevronDownIcon,
  Squares2X2Icon,
  CogIcon,
  LockClosedIcon,
  Bars3Icon
} from "@heroicons/react/24/solid";
import { Menu, Transition } from "@headlessui/react";
import Section from "../general/Section";
import { Link } from "../../util/router";
import Button from "../cosmetics/Button";
import { useAuth } from "../../util/auth";
import Logo from '../../images/transparent-logo.png';


function Navbar(props) {
  const auth = useAuth();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const classes = {
    navLink:
      "inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 transition duration-200 ease-in-out",
    navLinkIcon:
      "opacity-50 transform transition duration-200 ease-in-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in-out opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
        "bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
        "text-xs uppercase font-semibold tracking-wider text-blue-800 mb-5",
      link:
        "text-gray-600 hover:text-blue-600 font-medium text-sm flex items-center space-x-2 transition duration-200 ease-in-out",
      icon: "opacity-25 inline-block w-5 h-5",
      feature:
        "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm transition duration-200 ease-in-out",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10 ",
      inner:
        "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link:
        "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in-out",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
        "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700 transition duration-200 ease-in-out",
      icon: "opacity-50 inline-block w-5 h-5",
    },
  };

  return (
    <Section>
      <div className="container py-4">
        <div className="flex justify-between items-center">
          <Link
            to="/"
            className="inline-flex items-center space-x-2 text-lg font-bold tracking-wide text-black-600 hover:text-blue-400"
          >
            <img src={Logo} alt="Logo" className="opacity-75 inline-block w-6 h-6 text-blue-600"></img>
            <span>peoplemetrics.fyi</span>
          </Link>

          {/* Mobile Hamburger Menu Icon */}
          <Button
            variant="simple"
            size="sm"
            className="lg:hidden"
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
            endIcon={<Bars3Icon className="inline-block w-5 h-5" />}
          />

          {/* Navigation for large screens */}
          <div className="hidden lg:flex items-center ml-auto space-x-4">
            {auth.user ? (
              <>
                {/* When user is signed in */}
                <Link to="/dashboard" className={classes.navLink}>
                  <span>My Dashboard</span>
                </Link>
                <Link
                  to="#"
                  className={classes.navLink}
                  onClick={(e) => {
                    e.preventDefault();
                    auth.signout();
                  }}
                >
                  <span>Sign out</span>
                </Link>
              </>
            ) : (
              <>
                {/* When no user is signed in */}
                <Link to="/auth/signin" className={classes.navLink}>
                  <span>Sign in</span>
                </Link>
              </>
            )}
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        <div className={`${mobileNavOpen ? "" : "hidden"} lg:hidden`}>
          <div className="p-3 space-y-6">
            {auth.user ? (
              <>
                {/* User is signed in */}
                <Link to="/dashboard" className={`${classes.dropdown.link}`}>
                  <Squares2X2Icon className={classes.dropdown.icon} />
                  <span>Dashboard</span>
                </Link>
                <Link
                  to="#"
                  className={`${classes.dropdown.link}`}
                  onClick={(e) => {
                    e.preventDefault();
                    auth.signout();
                  }}
                >
                  <LockClosedIcon className={classes.dropdown.icon} />
                  <span>Sign out</span>
                </Link>
              </>
            ) : (
              <>
                {/* No user is signed in */}
                <Link to="/auth/signin" className={`${classes.dropdown.link}`}>
                  Sign in
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}


export default Navbar;