import React, { useEffect } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { useFilters } from '../../util/FiltersContext';
import { UserGroupIcon, RocketLaunchIcon, GlobeEuropeAfricaIcon, CalendarIcon } from '@heroicons/react/20/solid';
import { regionOptions } from '../../util/MetricsConfig';

const animatedComponents = makeAnimated();

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#f9fafb', // Light gray background for the select control
    borderColor: '#e5e7eb', // Border color matching the light gray background
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#d1d5db' // Darker gray border on hover
    }
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#818cf8", // Indigo background for selected options
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white", // White text color for selected options
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#818cf8", // Darker indigo background on hover
      color: "#6B46C1", // Different color on hover
    },
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#818cf8' : '#fff', // Indigo background for focused option
    color: isFocused ? 'white' : 'black', // White text color for focused option
    '&:active': {
      backgroundColor: '#6366F1', // Darker indigo background for active option
    },
    fontSize: '14px', // Consistent font size for option text
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#4b5563', // Dark gray text color for selected single option
    fontSize: '14px', // Consistent font size for selected single option
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#9ca3af', // Light gray text color for placeholder
    fontSize: '14px', // Consistent font size for placeholder text
  }),
  menu: (styles) => ({
    ...styles,
    fontSize: '14px', // Consistent font size for option text
  }),
};

const timePeriodOptions = [
  { value: 'Current Quarter', label: 'Current Quarter' },
  { value: 'Previous Quarter', label: 'Previous Quarter' },
  { value: 'Previous Quarter minus 1', label: 'Previous Quarter minus 1' },
  { value: 'Previous Quarter minus 2', label: 'Previous Quarter minus 2' },
  { value: 'Average of last 6 months', label: 'Average of last 6 months' },
  { value: 'Average of last 12 months', label: 'Average of last 12 months' }
];

const Filters = ({ headcountOptions, stageOptions, locationOptions }) => {
  const {
    selectedHeadcount,
    selectedStage,
    selectedLocation,
    selectedTimePeriod,
    handleHeadcountChange,
    handleStageChange,
    handleLocationChange,
    handleTimePeriodChange,
  } = useFilters();

  useEffect(() => {
 
  }, [selectedHeadcount, selectedStage, selectedLocation, selectedTimePeriod]);

  return (
    <div className="flex flex-col space-y-4">
      {/* Time Period Filter */}
      <div className="flex space-x-4">
        <div className="flex items-center flex-grow">
          <CalendarIcon className="w-5 h-5 text-indigo-500 mr-2" />
          <label className="text-sm font-medium text-gray-500 w-20 mr-4">Benchmark</label>
          <Select
            value={timePeriodOptions.find(option => option.value === selectedTimePeriod)}
            name="time_period"
            components={animatedComponents}
            options={timePeriodOptions}
            className="flex-grow"
            onChange={handleTimePeriodChange}
            styles={customStyles}
          />
        </div>
      </div>
      {/* Location, Headcount, Stage Filters */}
      <div className="flex space-x-4">
        {/* Location Filter */}
        <div className="flex items-center flex-grow">
          <GlobeEuropeAfricaIcon className="w-5 h-5 text-indigo-500 mr-2" />
          <label className="text-sm font-medium text-gray-500 w-20 mr-4">Location</label>
          <Select
            value={selectedLocation}
            isMulti
            name="location"
            components={animatedComponents}
            options={locationOptions}
            className="flex-grow"
            onChange={handleLocationChange}
            styles={customStyles}
          />
        </div>
        {/* Headcount Filter */}
        <div className="flex items-center flex-grow">
          <UserGroupIcon className="w-5 h-5 text-indigo-500 mr-2" />
          <label className="text-sm font-medium text-gray-500 w-20 mr-4">Headcount</label>
          <Select
            value={selectedHeadcount}
            isMulti
            name="headcount"
            components={animatedComponents}
            options={headcountOptions}
            className="flex-grow"
            onChange={handleHeadcountChange}
            styles={customStyles}
          />
        </div>
        {/* Stage Filter */}
        <div className="flex items-center flex-grow">
          <RocketLaunchIcon className="w-5 h-5 text-indigo-500 mr-2" />
          <label className="text-sm font-medium text-gray-500 w-20 mr-4">Stage</label>
          <Select
            value={selectedStage}
            isMulti
            name="stage"
            components={animatedComponents}
            options={stageOptions}
            className="flex-grow"
            onChange={handleStageChange}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
