import { Menu } from '@headlessui/react';
import {
  ChevronDownIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/20/solid';
import { stats } from '../../util/MetricsConfig';

export default function MetricsDropdown({ hiddenMetrics, toggleMetricVisibility }) {
  const peopleMetrics = stats.filter(metric => metric.metricType === 'People');
  const talentMetrics = stats.filter(metric => metric.metricType === 'Talent');

  return (
    <div className="flex items-center space-x-4">
      <EyeIcon className="w-5 h-5 text-indigo-500 mr-2" />
      <label className="text-sm font-medium text-gray-500 w-30 mr-4">Show/Hide Metrics</label>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Configure
            <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-2 min-w-[15rem] max-w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <div className="px-4 py-2 text-xs font-semibold text-gray-500">People Metrics</div>
            {peopleMetrics.map((metric) => (
              <Menu.Item key={metric.id} as="div">
                {({ active }) => (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      toggleMetricVisibility(metric.name);
                    }}
                    className={`group flex items-center px-4 py-2 text-sm w-full text-left cursor-pointer truncate ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                  >
                    {hiddenMetrics.includes(metric.name) ? (
                      <EyeSlashIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    ) : (
                      <EyeIcon aria-hidden="true" className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500" />
                    )}
                    <span className="truncate">{metric.name}</span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            <div className="px-4 py-2 text-xs font-semibold text-gray-500">Talent Metrics</div>
            {talentMetrics.map((metric) => (
              <Menu.Item key={metric.id} as="div">
                {({ active }) => (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      toggleMetricVisibility(metric.name);
                    }}
                    className={`group flex items-center px-4 py-2 text-sm w-full text-left cursor-pointer truncate ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                  >
                    {hiddenMetrics.includes(metric.name) ? (
                      <EyeSlashIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    ) : (
                      <EyeIcon aria-hidden="true" className="mr-3 h-5 w-5 text-indigo-400 group-hover:text-indigo-500" />
                    )}
                    <span className="truncate">{metric.name}</span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}
