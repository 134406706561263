import React from 'react'
import { useEffect, useState } from 'react';
import IndexPage from "./index";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "../util/router";
import { Redirect } from "react-router-dom";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import LayoutWithNavAndFooter from "../components/general/LayoutWithNavAndFooter";
import OnboardingPage from "./onboarding";
import EditMetricsPage from "./editMetrics";
import TrendAnalysisPage from "./trendAnalysis";
import LoadingIcon from "../components/cosmetics/LoadingIcon";

import { useAuth } from "../util/auth";

import "../util/analytics";
import MetricDashboardPage from "./metricDashboard";
import WelcomePage from "./welcome";

function PageRoutes() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const isUserPaid = auth.user && ['active', 'trialing'].includes(auth.user.stripeSubscriptionStatus);

  useEffect(() => {
    // Wait until auth.user is not null to consider the auth state resolved
    if (auth.user !== null) {
      setLoading(false);

    }
  }, [auth.user]);

  if (loading) {
    return <LoadingIcon className="w-5" />;
  }

  return (
    <Router>
              <LayoutWithNavAndFooter>
                <Switch>
                  <Route exact path="/" component={IndexPage} />
                  <Route exact path="/pricing" component={PricingPage} />
                  <Route exact path="/welcome" component={WelcomePage} />
                  <ProtectedRoute exact path="/dashboard" component={DashboardPage} isPaid={isUserPaid} />
                  <ProtectedRoute path="/dashboard/metrics/:metricName" component={MetricDashboardPage} isPaid={isUserPaid} />
                  <ProtectedRoute exact path="/edit-metrics" component={EditMetricsPage} isPaid={isUserPaid} />
                  <ProtectedRoute exact path="/trend-analysis" component={TrendAnalysisPage} isPaid={isUserPaid} />
                  <ProtectedRoute exact path="/onboarding" component={OnboardingPage} isPaid={isUserPaid} />
                  <Route exact path="/auth/:type" component={AuthPage} />
                  <Route exact path="/settings/:section" component={SettingsPage} />
                  <Route exact path="/legal/:section" component={LegalPage} />
                  <Route exact path="/purchase/:plan" component={PurchasePage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </LayoutWithNavAndFooter>
            </Router>
  )
}

// HOC to protect routes that require payment
const ProtectedRoute = ({ component: Component, isPaid, ...rest }) => (
  <Route {...rest} render={(props) => {
    return isPaid
      ? <Component {...props} />
      : <Redirect to="/welcome" />;
  }} />
);

export default PageRoutes