import React from 'react';
import { motion } from 'framer-motion';

const FeatureTag = ({ type }) => {
  const isComingSoon = type === 'coming-soon';
  const text = isComingSoon ? 'Coming Soon' : 'Beta Feature';
  const defaultGradient = isComingSoon 
    ? 'bg-gradient-to-r from-purple-200 to-pink-200' 
    : 'bg-gradient-to-r from-blue-200 to-cyan-200';
  const hoverGradient = isComingSoon 
    ? 'bg-gradient-to-r from-purple-500 to-pink-500' 
    : 'bg-gradient-to-r from-blue-500 to-cyan-500';
  const defaultTextColor = isComingSoon ? 'text-purple-700' : 'text-blue-700';
  const hoverTextColor = 'text-white';

  return (
    <motion.div
      className={`inline-flex flex-shrink-0 items-center rounded-full ${defaultGradient} px-2 py-0.5 relative overflow-hidden`}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      whileHover={{ scale: 1.05 }}
    >
      <motion.span
        className={`absolute inset-0 ${hoverGradient} opacity-0`}
        initial={false}
        whileHover={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      />
      <motion.span
        className={`relative text-xs font-medium ${defaultTextColor}`}
        initial={{ y: 5, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.2 }}
        whileHover={{ color: hoverTextColor }}
      >
        {text}
      </motion.span>
      <motion.span
        className="absolute inset-0 rounded-full border border-current opacity-0"
        initial={false}
        whileHover={{ opacity: 1, scale: 1.1 }}
        transition={{ duration: 0.2 }}
      />
    </motion.div>
  );
};

export default FeatureTag;