import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";
import { regionOptions, columnMapping } from './MetricsConfig'; 



// Abandon all hope, ye who enter here

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that fetches data
    () =>
      supabase
        .from("users")
        .select(`*, customers ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}
// Save filter configuration
export async function saveFilterConfig(uid, filterConfig) {
  const { data, error } = await supabase
    .from("users")
    .update({ filter_config: filterConfig })
    .eq("id", uid);

  if (error) {
    throw error;
  }
  return data;
}

// Load filter configuration
export async function loadFilterConfig(uid) {
  const { data, error } = await supabase
    .from("users")
    .select("filter_config")
    .eq("id", uid)
    .single();
  
  if (error) throw error;
  return data.filter_config;
}

// Save dashboard configuration
export async function saveDashboardConfig(uid, dashboardConfig) {
  const { data, error } = await supabase
    .from("users")
    .update({ dashboard_config: dashboardConfig })
    .eq("id", uid);

  if (error) {
    throw error;
  }
  return data;
}

// Load dashboard configuration
export async function loadDashboardConfig(uid) {
  const { data, error } = await supabase
    .from("users")
    .select("dashboard_config")
    .eq("id", uid)
    .single();

  if (error) throw error;
  return data.dashboard_config;
}


// Update the onboardingCompletedAt timestamp for a user
export async function completeOnboarding(uid) {
  const response = await supabase
    .from("users")
    .update({ onboardingCompletedAt: new Date().toISOString() }) // Set the current timestamp
    .eq("id", uid)
    .then(handle);

  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);

  return response;
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    () => supabase.from("items").select().eq("id", id).single().then(handle),
    { enabled: !!id }
  );
}

// Fetch all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    () =>
      supabase
        .from("items")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}

// Create a new item
export async function createItem(data) {
  const response = await supabase.from("items").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["items"]);
  return response;
}

// Update an item
export async function updateItem(id, data) {
  const response = await supabase
    .from("items")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await supabase
    .from("items")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

//////// ORGANISATIONS /////////

//Fetch all organisations by owner

export function useOrganisationsByOwner(owner) {
  return useQuery(
    ["organisations", { owner }],
    () =>
      supabase
        .from("organisations")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}

// create new organisation

export async function createOrganisation(data) {
  const response = await supabase
    .from("organisations")
    .insert([data])
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["organisations"]);
  return response;
}

// update an organisation
export async function updateOrganisation(id, data) {
  try {
    // Log the ID and data for debugging purposes
    // console.log('Updating organisation with ID:', id);
    // console.log('Updated Data:', data);

    const response = await supabase
      .from("organisations")
      .update(data)
      .eq("id", id)
      .then(handle);

    // Log the response for debugging
    // console.log('Update Response:', response);

    // Invalidate and refetch queries that could have old data
    await Promise.all([
      client.invalidateQueries(["organisation", { id }]),
      client.invalidateQueries(["organisations"]),
    ]);

    return response;
  } catch (error) {
    // Log any errors that occur during the update
    console.error("Error updating organisation:", error);

    // If there's an error, you might want to throw it again or handle it accordingly
    throw error;
  }
}

// People Metrics



//fetch all metrics
export function useMetricsByOwner(owner) {
  return useQuery(
    ["metrics", { owner }],
    () =>
      supabase
        .from("metrics")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}



// Create a new metric
export async function createMetric(data) {
  const response = await supabase.from("metrics").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["metrics"]);
  return response;
}

//createOrUpdateMetric
export async function createOrUpdateMetric(ownerId, year, time_period, data) {
  try {
    const { data: existingMetrics, error: fetchError } = await supabase
      .from("metrics")
      .select("*")
      .eq("owner", ownerId)
      .eq("year", year)
      .eq("time_period", time_period);

    if (fetchError) throw fetchError;

    let response;
    if (existingMetrics.length > 0) {
      // Update existing metric
      response = await supabase
        .from("metrics")
        .update(data)
        .eq("id", existingMetrics[0].id);
    } else {
      // Create new metric
      data.owner = ownerId;
      response = await supabase
        .from("metrics")
        .insert(data);
    }

    return response;
  } catch (error) {
    console.error("Error creating or updating metric:", error);
    throw error;
  }
}


// Update a people metric

export async function updateMetric(ownerId, data) {
  try {
    // Fetch existing metrics for the given year and time period
    const { data: existingMetrics, error: fetchError } = await supabase
      .from("metrics")
      .select("*")
      .eq("owner", ownerId)
      .eq("year", data.year)
      .eq("time_period", data.time_period);

    if (fetchError) throw fetchError;

    let response;
    if (existingMetrics.length > 0) {
      // Update existing metric
      response = await supabase
        .from("metrics")
        .update(data)
        .eq("id", existingMetrics[0].id);
    } else {
      // Insert new metric
      data.owner = ownerId; // Ensure ownerId is set
      response = await supabase
        .from("metrics")
        .insert(data);
    }

    if (response.error) {
      console.error("Supabase error:", response.error);
      throw response.error;
    }

    return response;
  } catch (error) {
    console.error("Error updating metric:", error);
    throw error;
  }
}




// fetch metrics with period 
export async function fetchMetricsByOwnerAndPeriod(ownerId, year, time_period) {
  try {

    const { data, error } = await supabase
      .from("metrics")
      .select("*")
      .eq("owner", ownerId)
      .eq("year", year)
      .eq("time_period", time_period);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching metrics:", error);
    throw error;
  }
}

// Add this function to your db.js file

export async function fetchAggregateMetrics(stage = null) {
  try {
    let query = supabase.from("metrics_view").select("*");
    
    if (stage && stage !== 'All Stages') {
      query = query.ilike('stage', `%${stage}%`);
    }

    const { data, error } = await query;

    if (error) {
      console.error("Error fetching data:", error);
      throw error;
    }

    // console.log("Raw data from fetchAggregateMetrics:", data);
    // console.log("Number of rows:", data.length);

    if (data.length === 0) {
      return {};
    }

    // Group data by year and quarter
    const groupedData = data.reduce((acc, curr) => {
      const key = `${curr.year} ${curr.time_period}`;
      if (!acc[key]) acc[key] = {};
      Object.entries(curr).forEach(([metric, value]) => {
        if (!['year', 'time_period', 'owner', 'stage'].includes(metric)) {
          if (!acc[key][metric]) acc[key][metric] = [];
          if (value !== null && !isNaN(value)) {
            acc[key][metric].push(Number(value));
          }
        }
      });
      return acc;
    }, {});

    // console.log("Grouped data:", groupedData);
    return groupedData;
  } catch (error) {
    console.error("Error in fetchAggregateMetrics:", error);
    throw error;
  }
}


/////// MATHS STUFF ///////A

export const fetchAllMetrics = async () => {
  const { data, error } = await supabase
    .from("metrics_view")
    .select(
      "enps, engagement, regrettable_attrition, time_to_hire, cost_of_hire, revenue_per_employee, internal_mobility, absenteeism, tenure, offer_acceptance_rate, probation_period_pass_rate, people_team_ratio"
    )
    .not("enps", "is", null) // Exclude rows where 'enps' is null
    .not("engagement", "is", null) // Exclude rows where 'enps' is null
    .not("regrettable_attrition", "is", null) // Exclude rows where 'regrettable_attrition' is null
    .not("time_to_hire", "is", null) // Exclude rows where 'time_to_hire' is null
    .not("cost_of_hire", "is", null) // Exclude rows where 'cost_of_hire' is null
    .not("revenue_per_employee", "is", null) // Exclude rows where 'revenue_per_employee' is null
    .not("internal_mobility", "is", null) // Exclude rows where 'internal_mobility' is null
    .not("absenteeism", "is", null) // Exclude rows where 'absenteeism' is null
    .not("tenure", "is", null) // Exclude rows where 'tenure' is null
    .not("offer_acceptance_rate", "is", null) // Exclude rows where 'offer_acceptance_rate' is null
    .not("probation_period_pass_rate", "is", null) // Exclude rows where 'probation_period_pass_rate' is null
    .not("people_team_ratio", "is", null); // Exclude rows where 'manager_ratio' is null

  if (error) throw error;
  return data;
};

// fecth all data in view for filters
export const fetchFilterData = async (columns, columnToCheck) => {
  try {
    // Fetch data for the specified columns
    const { data, error } = await supabase.from("metrics_view").select(columns);
    //.in("stage", ["Series A"]);

    if (error) {
      throw error;
    }

    // Calculate the number of organizations with non-null values for the specified column
    const organizationsWithData = data.filter(
      (row) => row[columnToCheck] !== null
    );

    const organizationsWithDataCount = organizationsWithData.length;

    return {
      data,
      organizationsWithDataCount,
    };
  } catch (error) {
    throw error;
  }
};

// Function to convert region values to their corresponding country codes
export const convertRegionsToCountryCodes = (selectedRegionValues) => {
  const countryCodes = selectedRegionValues.flatMap(regionValue => {
      const region = regionOptions.find(option => option.value === regionValue);
      return region ? region.countries : [];
  });
  return countryCodes.flat();
};


export const fetchStageFilterData = async (headcountFilters, stageFilters, locationFilters) => {
  try {
     // Convert location filters from region values to country codes
     const locationCountryCodes = convertRegionsToCountryCodes(locationFilters || []);

       // Define filters for each column
       const stageFilter = stageFilters.includes('All') ? null : stageFilters;
       const headcountFilter = headcountFilters.includes('All') ? null : headcountFilters;
       const locationFilter = locationCountryCodes.includes('All') ? null : locationCountryCodes;

     const { data, error } = await supabase
       .from("metrics_view")
       .select("*")
       .in("stage", stageFilter)
       .in("headcount", headcountFilter)
       .in("location", locationFilter);

    if (error) {
      throw error;
    }

    // Example: Count non-null entries for each metric
    const sampleSizes = {
      enps: data.filter(row => row.enps !== null).length,
      engagement: data.filter(row => row.engagement !== null).length,
      regrettable_attrition: data.filter(row => row.regrettable_attrition !== null).length,
      time_to_hire: data.filter(row => row.time_to_hire !== null).length,
      cost_of_hire: data.filter(row => row.cost_of_hire !== null).length,
      revenue_per_employee: data.filter(row => row.revenue_per_employee !== null).length,
      internal_mobility: data.filter(row => row.internal_mobility !== null).length,
      absenteeism: data.filter(row => row.absenteeism !== null).length,
      tenure: data.filter(row => row.tenure !== null).length,
      offer_acceptance_rate: data.filter(row => row.offer_acceptance_rate !== null).length,
      probation_period_pass_rate: data.filter(row => row.probation_period_pass_rate !== null).length,
      people_team_ratio: data.filter(row => row.people_team_ratio !== null).length,
      org_transparency: data.filter(row => row.org_transparency !== null).length,
      early_attrition: data.filter(row => row.early_attrition !== null).length,
      manager_ic_ratio: data.filter(row => row.manager_ic_ratio !== null).length,
      time_to_fill: data.filter(row => row.time_to_fill !== null).length,
      non_regrettable_attrition: data.filter(row => row.non_regrettable_attrition !== null).length,
      ga_burn_rate: data.filter(row => row.ga_burn_rate !== null).length,
    };

    return { data, sampleSizes };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: [], sampleSizes: {} };
  }
};

// db.js

// utility.js or similar file
function getLastNQuarters(n) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-11
  const currentQuarter = Math.ceil(currentMonth / 3);
  const periods = [];

  for (let i = 0; i < n; i++) {
    const year = currentYear - Math.floor((currentQuarter - i - 1) / 4);
    const quarter = ((currentQuarter - i - 1) % 4 + 4) % 4 + 1; // 1 to 4

    periods.push({ year, time_period: `Q${quarter}` });
  }

  return periods.reverse();
}


// Fetch average metrics over the last 6 or 12 months
// db.js
export async function fetchAverageMetricsByOwnerAndPeriod(ownerId, period) {
  try {
    const quartersToFetch = period === 'last_6_months' ? 2 : 4; // 2 quarters for 6 months, 4 quarters for 12 months
    const periods = getLastNQuarters(quartersToFetch);

    const queries = periods.map(async ({ year, time_period }) => {
      const { data, error } = await supabase
        .from("metrics")
        .select("*")
        .eq("owner", ownerId)
        .eq("year", year)
        .eq("time_period", time_period);

      if (error) throw error;
      return data;
    });

    const results = await Promise.all(queries);
    const data = results.flat(); // Flatten the array of arrays

    // Calculate average metrics
    const averageMetrics = data.reduce((acc, metric) => {
      Object.keys(metric).forEach(key => {
        if (key !== 'id' && key !== 'owner' && key !== 'year' && key !== 'time_period') {
          if (!acc[key]) acc[key] = 0;
          acc[key] += metric[key];
        }
      });
      return acc;
    }, {});

    // Divide by the number of periods to get the average
    Object.keys(averageMetrics).forEach(key => {
      averageMetrics[key] /= periods.length;
    });

    return [averageMetrics];
  } catch (error) {
    console.error("Error fetching average metrics:", error);
    throw error;
  }
}



const calculateStandardDeviation = (values) => {
  const mean = values.reduce((acc, val) => acc + val, 0) / values.length;
  const variance =
    values.reduce((acc, val) => acc + (val - mean) ** 2, 0) / values.length;
  return Math.sqrt(variance);
};

export const computeMetricsStdDev = async () => {
  const allMetrics = await fetchAllMetrics();

  const tenureStdDev = calculateStandardDeviation(
    allMetrics.map((item) => item.tenure)
  );
  const enpsStdDev = calculateStandardDeviation(
    allMetrics.map((item) => item.enps)
  );
  // ... calculations for other metrics

  return { tenureStdDev, enpsStdDev /* ... other metrics */ };
};

// Fetch specific metric
export const fetchMetric = async (metric, stage_filters) => {
  const { data, error } = await supabase
    .from("metrics_view")
    .select(metric)
    .in("stage", stage_filters)
    .not(metric, "is", null); // Exclude rows where 'enps' is null

  if (error) throw error;
  return data;
};

// I just googled this havent checked it
// const percentile = (arr, val) =>
//   (100 *
//     arr.reduce(
//       (acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0),
//       0
//     )) /
//   arr.length;

const percentile = (arr, val) => {
  let count = 0;
  arr.forEach((v) => {
    if (v < val) {
      count++;
    } else if (v === val) {
      count += 0.5;
    }
  });
  return (100 * count) / arr.length;
};

export const computeMetricPercentile = (metric, value, all_values) => {
  var values = all_values.map((option) => option[metric]);

  const metric_percentile = percentile(values, value);
  return metric_percentile;
};

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) {
    // console.error('Supabase Error:', response.error); // Log the Supabase error
    throw response.error;
  } else {
    // console.log('Supabase Response Data:', response.data); // Log the response data
    return response.data;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

// A function to fetch the Stripe subscription status for a specific user
export async function fetchUserStripeSubscriptionStatus(uid) {
  const { data, error } = await supabase
      .from('customers')
      .select('stripeSubscriptionStatus') // Adjust based on your schema. Assuming `customers` is a foreign table or a nested JSON field
      .eq('id', uid)
      .single();

  if (error) {
      console.error('Error fetching subscription status:', error);
      throw error;
  }

  // Assuming `customers` is an object with a `stripeSubscriptionStatus` field
  // Adjust the path to the stripeSubscriptionStatus according to your actual data structure
  const stripeSubscriptionStatus = data?.customers?.stripeSubscriptionStatus;

  return stripeSubscriptionStatus;
}

// Add this new function
export async function fetchFilteredDataAndMedians(filterConfig) {
  const { selectedHeadcount, selectedStage, selectedLocation } = filterConfig;

  const { data, sampleSizes } = await fetchStageFilterData(
    selectedHeadcount.map(option => option.value),
    selectedStage.map(option => option.value),
    selectedLocation.map(option => option.value)
  );

  const medians = {};
  Object.keys(columnMapping).forEach(metricName => {
    const columnName = columnMapping[metricName];
    const values = data.map(item => parseFloat(item[columnName])).filter(val => !isNaN(val));
    values.sort((a, b) => a - b);
    const mid = Math.floor(values.length / 2);
    medians[metricName] = values.length % 2 !== 0 ? values[mid] : (values[mid - 1] + values[mid]) / 2;
  });

  return { data, sampleSizes, medians };
}

