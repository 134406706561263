import {
    ComputerDesktopIcon,
    CurrencyDollarIcon,
    AcademicCapIcon,
    CpuChipIcon,
    GlobeAltIcon,
    ShoppingCartIcon,
    BeakerIcon,
    CloudIcon,
    CameraIcon,
    MegaphoneIcon,
    ShieldCheckIcon,
    LightBulbIcon,
} from '@heroicons/react/24/solid';

export const industryOptions = [
    { name: "Technology", icon: <ComputerDesktopIcon className="h-5 w-5" /> },
    { name: "Fintech", icon: <CurrencyDollarIcon className="h-5 w-5" /> },
    { name: "EdTech", icon: <AcademicCapIcon className="h-5 w-5" /> },
    { name: "AI and Machine Learning", icon: <CpuChipIcon className="h-5 w-5" /> },
    { name: "GreenTech", icon: <GlobeAltIcon className="h-5 w-5" /> },
    { name: "E-Commerce", icon: <ShoppingCartIcon className="h-5 w-5" /> },
    { name: "Healthtech/Biotech", icon: <BeakerIcon className="h-5 w-5" /> },
    { name: "SaaS", icon: <CloudIcon className="h-5 w-5" /> },
    { name: "Gaming and Entertainment", icon: <CameraIcon className="h-5 w-5" /> },
    { name: "Marketing and Advertising Tech", icon: <MegaphoneIcon className="h-5 w-5" /> },
    { name: "Cybersecurity", icon: <ShieldCheckIcon className="h-5 w-5" /> },
    { name: "IoT (Internet of Things)", icon: <LightBulbIcon className="h-5 w-5" /> },
];

export const locationOptions = [
    { name: "🌎 Remote", code: "Remote" },
    { name: "🇺🇸 United States", code: "US" },
    { name: "🇬🇧 United Kingdom", code: "UK" },
    { name: "🇨🇦 Canada", code: "CA" },
    { name: "🇦🇺 Australia", code: "AU" },
    { name: "🇩🇪 Germany", code: "DE" },
    { name: "🇫🇷 France", code: "FR" },
    { name: "🇯🇵 Japan", code: "JP" },
    { name: "🇮🇳 India", code: "IN" },
    { name: "🇨🇳 China", code: "CN" },
    { name: "🇧🇷 Brazil", code: "BR" },
    { name: "🇸🇬 Singapore", code: "SG" },
    { name: "🇳🇱 Netherlands", code: "NL" },
    { name: "🇮🇪 Ireland", code: "IE" },
    { name: "🇸🇪 Sweden", code: "SE" },
    { name: "🇫🇮 Finland", code: "FI" },
    { name: "🇳🇴 Norway", code: "NO" },
    { name: "🇩🇰 Denmark", code: "DK" },
    { name: "🇿🇦 South Africa", code: "ZA" },
    { name: "🇮🇱 Israel", code: "IL" },
    { name: "🇷🇺 Russia", code: "RU" },
    { name: "🇰🇷 South Korea", code: "KR" },
    { name: "🇪🇸 Spain", code: "ES" },
    { name: "🇮🇹 Italy", code: "IT" },
    { name: "🇹🇷 Turkey", code: "TR" },
    { name: "🇦🇪 United Arab Emirates", code: "AE" },
    { name: "🇲🇽 Mexico", code: "MX" },
    { name: "🇧🇪 Belgium", code: "BE" },
    { name: "🇨🇭 Switzerland", code: "CH" },
    { name: "🇵🇱 Poland", code: "PL" },
    { name: "🇭🇺 Hungary", code: "HU" }
,
];

export const headcountOptions = ["0-10", "11-50", "51-100", "101-250", "251-500", "501-1000", "1001+"];

export const stageOptions = ["Bootstrapped", "Pre-seed", "Seed", "Series A", "Series B", "Series C+"];
