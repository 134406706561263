import React from "react";

const Button = React.forwardRef((props, ref) => {
  const {
    size = "md",
    variant = "primary",
    type = "button",
    children,
    onClick,
    className,
    disabled = false,
    href,
    target,
    isBlock = false,
    startIcon,
    endIcon,
    component,
    ...buttonProps
  } = props;

  // Element to render
  const Element = component ? component : props.href ? "a" : "button";

  // Tailwind classes
  const classes = {
    base:
      "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
    link: "whitespace-nowrap",
    block: "block w-full",
    size: {
      sm: "px-3 py-2 leading-5 text-xs space-x-2",
      md: "px-3 py-2 leading-6 space-x-3",
      lg: "px-4 py-3 leading-6 space-x-3",
      xl: "px-6 py-4 leading-6 space-x-3",
    },
    variant: {
      primary:
        "px-5 py-2 text-lg font-semibold text-white rounded-lg shadow-md transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-blue-500 bg-gradient-to-r from-blue-600 via-purple-600 to-pink-600",
      secondary:
        "border-indigo-200 bg-indigo-200 text-indigo-500 hover:text-indigo-700 hover:bg-indigo-300 hover:border-indigo-300 focus:ring-indigo-500 focus:ring-opacity-50 active:bg-indigo-200 active:border-indigo-200",
      simple:
        "border-gray-300 bg-white text-gray-600 font-normal shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white",
      light:
        "border-gray-200 bg-gray-200 text-gray-700 hover:text-gray-700 hover:bg-gray-300 hover:border-gray-300 focus:ring-gray-500 focus:ring-opacity-25 active:bg-gray-200 active:border-gray-200",
      dark:
        "border-gray-700 bg-gray-700 text-white hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:ring-gray-500 focus:ring-opacity-25 active:bg-gray-700 active:border-gray-700",
        gradient:
        "relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-200 bg-indigo-500 rounded-xl focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-900",
    },
  };

  return (
    <Element
      className={`${classes.base} ${classes.size[size]} ${classes.variant[variant]} ${Element === "a" ? classes.link : ""} ${isBlock ? classes.block : ""} ${className || ""}`}
      href={href}
      target={target}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
      ref={ref}
    >
      {variant === 'gradient' && (
        <span className="absolute transition-all duration-1000 opacity-70 -inset-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-xl blur-lg group-hover:opacity-100 group-hover:duration-200"></span>
      )}
      <div className="relative flex items-center justify-center space-x-2">
        {startIcon && <span>{startIcon}</span>}
        {children && <span>{children}</span>}
        {endIcon && <span>{endIcon}</span>}
      </div>
    </Element>
  );
});

export default Button;
