import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  Cog6ToothIcon,
  XMarkIcon,
  LockClosedIcon,
  Squares2X2Icon,
  CogIcon,
  RocketLaunchIcon,
  SparklesIcon,
  ChartBarSquareIcon
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { Link } from "../../util/router";
import DashboardSection from "./DashboardSection";
import { useAuth } from "../../util/auth";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import UserMetricsDashboard from "./UserMetricsDashboard";
import { useLocation, Switch, Route } from "../../util/router";
import EditMetricsTable from "./EditMetricsTable";
import MetricResults from "./MetricResults";
import Banner from "../cosmetics/Banner";
import { useMetricsByOwner } from "../../util/db";
import { useMetrics } from "../../util/UserMetricsContext";
import { fetchStageFilterData, loadFilterConfig, saveFilterConfig } from "../../util/db";
import { useFilters } from "../../util/FiltersContext";
import useFeatureGate from "../../util/FeatureGate";
import UpgradePlanModal from "../modals/UpgradePlanModal";
import Logo from '../../images/transparent-logo.png';
import ComingSoonModal from "../modals/ComingSoonModal";
import { useHistory } from "../../util/router";
import { FaSlack } from 'react-icons/fa';
import TrendAnalysis from "./TrendAnalysis";
import FeatureTag from "../cosmetics/FeatureTag";

const navigation = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: Squares2X2Icon,
    current: true,
  },
  {
    name: "Update Metrics",
    href: "/edit-metrics",
    icon: PencilSquareIcon,
    current: false,
  },
  { name: "Data Story", href: "#", icon: SparklesIcon, current: false },
  { name: "Trend Analysis", href: "/trend-analysis", icon: ChartBarSquareIcon, current: false },
];

const teams = [
  { id: 1, name: "Core Metrics", href: "#", initial: "M", current: false },
  { id: 2, name: "Heatmap", href: "#", initial: "H", current: false },
  { id: 3, name: "Experiments", href: "#", initial: "E", current: false },
];
const userNavigation = [
  { name: "Your profile", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const auth = useAuth(); // Assuming useAuth is used for authentication
  const history = useHistory();
  const ownerId = auth.user?.uid;
  const showReportsLabel = true;
  const location = useLocation(); // Get the current location
  const shouldShowButton = location.pathname === "/dashboard";
  // Update the navigation data to set 'current' based on the current route
  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));
  const [comingSoonModalOpen, setComingSoonModalOpen] = useState(false);
  const [activeMetric, setActiveMetric] = useState(null);

  const handleMetricClick = (metric) => {
    setActiveMetric(metric);
  };

  /// Data fetch 
  const { metricsData, setMetricsData } = useMetrics();
  const { data: metrics, error: metricsError, isFetching, } = useMetricsByOwner(ownerId);
  const [orgCount, setOrgCount] = useState(null);
  const [metricSampleSizes, setMetricSampleSizes] = useState({});
  const [selectedMetricData, setSelectedMetricData] = useState(null); // Store selected metric data
  const [dataSampleSize, setDataSampleSize] = useState(null); // State to store the global data sample size
  const [allMetricsData, setAllMetricsData] = useState(null); // Store filtered data for all metrics
  const { selectedHeadcount, selectedStage, selectedLocation, selectedTimePeriod, setSelectedHeadcount, setSelectedStage, setSelectedLocation, setSelectedTimePeriod } = useFilters();
  const { checkAccess, modalOpen, setModalOpen } = useFeatureGate(['active', 'trialing']);

  const handleUpdateMetricsClick = (event) => {
    // if (!checkAccess()) {
    //   event.preventDefault(); // Prevent link navigation
    //   setModalOpen(true); // Open the modal if the user doesn't have access
    // }
    history.push("/edit-metrics");
  };

  const handleComingSoonClick = (event, item) => {
    if (event) event.preventDefault();  // Only call preventDefault if event is provided
    setComingSoonModalOpen(true);
  };

  function navigate(item, event) {  // Add 'event' parameter
    if (item.href === "#" || item.name === "Data Story") {
        handleComingSoonClick(event);  // Pass 'event' to the handler
    } else {
        history.push(item.href);
    }
}


  return (
    <>
      <UpgradePlanModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />

      <ComingSoonModal
        isOpen={comingSoonModalOpen}
        onClose={() => setComingSoonModalOpen(false)}
        title='Coming Soon!'
        message='We are working hard to bring you this feature soon. Stay tuned!'
      />
      <Banner></Banner>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <Link
                        to="/"
                        className="inline-flex items-center space-x-2 text-lg font-bold tracking-wide text-white-600 hover:text-blue-400"
                      >
                        <img src={Logo} alt="Logo" className="opacity-75 inline-block w-6 h-6 text-blue-600"></img>
                        <span className="text-white">peoplemetrics.fyi</span>
                      </Link>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {updatedNavigation.map((item, index) => (
                              <li key={item.name}>
                                <button
                                  href={item.href}
                                  onClick={(e) => navigate(item, e)} 
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  {item.name === 'Trend Analysis' && (
                                    <FeatureTag type="beta" />
                                  )}
                                  {item.name === 'Data Story' && (
                                    <FeatureTag type="coming-soon" />
                                  )}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {/* <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Your teams
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                        <li className="mt-auto">
                          <a
                            href="https://peoplemetricsfyi.featurebase.app/"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          >
                            <RocketLaunchIcon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            Request A Feature
                          </a>
                          <a
                            href="https://join.slack.com/t/peoplemetricsgroup/shared_invite/zt-2oamcggs2-9iB9cE4t13nrUhEz9lROSg"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          >
                            <FaSlack
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            Need Help? Chat To John
                          </a>
                          <Link
                            to="/settings/general"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            Settings
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                alt="Your Company"
              /> */}
              <Link
                to="/"
                className="inline-flex items-center space-x-2 text-lg font-bold tracking-wide text-white-600 hover:text-blue-400"
              >
                <img src={Logo} alt="Logo" className="opacity-75 inline-block w-6 h-6 text-blue-600"></img>

                <span className="text-white">peoplemetrics.fyi</span>
              </Link>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {updatedNavigation.map((item, index) => (
                      <li key={item.name}>
                        <button
                          href={item.href}
                          onClick={(e) => navigate(item, e)} 
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}

                          {item.name === 'Trend Analysis' && (
                            <FeatureTag type="beta" />
                          )}
                          {item.name === 'Data Story' && (
                            <FeatureTag type="coming-soon" />
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* ADD SUB SECTIONS TO NAV FOR NEW FEATURES */}
                {/* <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your organisation</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li> */}
                <li className="mt-auto">
                  <a
                    href="https://peoplemetricsfyi.featurebase.app/"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <RocketLaunchIcon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Request A Feature
                  </a>
                  <a
                    href="https://join.slack.com/t/peoplemetricsgroup/shared_invite/zt-2oamcggs2-9iB9cE4t13nrUhEz9lROSg"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <FaSlack
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Need Help? Chat To John
                  </a>
                  <Link
                    to="/settings/general"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Settings
                  </Link>
                </li>

              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          {/* z index issue with modal here:  */}
          <div className="z-50 sticky top-0 flex h-16 shrink-0 items-center gap-x-4 bg-transparent px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className=" flex flex-1" action="#" method="GET">
                {/* <label htmlFor="search-field" className="sr-only"> */}
                {/* Search */}
                {/* </label> */}
                {/* <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                /> */}
                {/* <input */}
                {/* id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  // placeholder="Search..."
                  type="search"
                  name="search" */}
                {/* /> */}
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                {auth.user && (
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button
                        className="flex max-w-xs items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        style={{
                          backgroundColor: "transparent",
                          color: "#000",
                        }}
                      >
                        <span className="sr-only">Open user menu</span>
                        <div
                          className="h-8 w-8 rounded-full"
                          style={{
                            backgroundImage: auth.user.photoUrl
                              ? `url(${auth.user.photoUrl})`
                              : "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {!auth.user.photoUrl && (
                            <span className="text-white text-sm font-semibold flex items-center justify-center h-full">
                              {/* You can add initials or an icon here */}
                            </span>
                          )}
                        </div>
                        <ChevronDownIcon
                          className="ml-2 -mr-1 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/dashboard"
                              className={`${active ? "bg-gray-100" : ""
                                } block px-4 py-2 text-sm text-gray-700`}
                            >
                              <Squares2X2Icon
                                className="mr-3 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              Dashboard
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/settings/general"
                              className={`${active ? "bg-gray-100" : ""
                                } block px-4 py-2 text-sm text-gray-700`}
                            >
                              <CogIcon
                                className="mr-3 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                auth.signout();
                              }}
                              className={`${active ? "bg-gray-100" : ""
                                } block px-4 py-2 text-sm text-gray-700`}
                            >
                              <LockClosedIcon
                                className="mr-3 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <Switch>
                <Route path="/dashboard" exact>
                  <DashboardSection
                    title="Your People Metrics"
                    subtitle="Welcome to your peoplemetrics.fyi dashboard"
                    showReportsLabel={showReportsLabel}
                    renderComponent={UserMetricsDashboard}
                    showButton={shouldShowButton}
                    onMetricClick={handleMetricClick}
                    filterProps={{
                      selectedHeadcount,
                      setSelectedHeadcount,
                      selectedStage,
                      setSelectedStage,
                      selectedLocation,
                      setSelectedLocation,
                      selectedTimePeriod,
                      setSelectedTimePeriod,
                    }}
                  />
                </Route>
                <Route path="/edit-metrics">
                  <DashboardSection
                    title="Record This Quarter's Metrics"
                    subtitle="Record at least 2 quarters' worth of metrics to unlock trends."
                    showReportsLabel={showReportsLabel}
                    renderComponent={EditMetricsTable}
                  />
                </Route>
                <Route path="/dashboard/metrics/:metricId">
                  <DashboardSection
                    title=""
                    subtitle=""
                    renderComponent={MetricResults}
                  />
                </Route>
                <Route path="/trend-analysis">
                  <DashboardSection
                    title="Trend Analysis"
                    subtitle="View the trends in your metrics"
                    renderComponent={TrendAnalysis}
                  />
                </Route>
                {/* Add more routes as needed */}
              </Switch>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
