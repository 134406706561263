import algolia from '../../images/logos/Algolia-logo.svg.png';
import auror from '../../images/logos/auror.svg';
import etsy from '../../images/logos/Etsy_logo.svg.png';
import trint from '../../images/logos/Trint_Logo.svg.png';

export default function LogoCloud() {
    return (
      <div className="bg-transparent lg:pb-20 lg:pt-2 sm:pb-16 xs:pb-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-gray-700">
            Benchmark your metrics against 500+ startups & scaleups from Seed to Series C.
          </h2>
          <div className="pb-12 mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="grayscale col-span-2 max-h-8 w-full object-contain lg:col-span-1"
              src="https://www.globaliadigital.com/hubfs/vidyard_logo_noir.svg"
              alt="Vidyard"
              width={158}
              height={48}
            />
            <img
              className="grayscale col-span-2 max-h-8 w-full object-contain lg:col-span-1"
              src={etsy}
              alt="etsy"
              width={158}
              height={48}
            />
     
            <img
              className="grayscale col-span-2 max-h-8 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={trint}
              alt="trint"
              width={158}
              height={48}
            />
            <img
              className="grayscale col-span-2 max-h-8 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={algolia}
              alt="algolia"
              width={158}
              height={48}
            />
            <img
              className="grayscale col-span-2 max-h-8 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={auror}
              alt="auror"
              width={158}
              height={48}
            />
          </div>
        </div>
      </div>
    )
  }
  