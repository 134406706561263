// App.js
import React from "react";
import "./../styles/global.css";
import "./../util/analytics";
import { AuthProvider, useAuth } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import { Helmet } from "react-helmet";
import { FiltersProvider } from "../util/FiltersContext";
import { MetricsProvider } from "../util/UserMetricsContext";
import PageRoutes from "./page_routes";

// Define initializeSmartlook in the global scope
window.initializeSmartlook = function () {
  window.smartlook('init', '99e02547925d96fa36da18f09e317f9d40cb7db1', { region: 'eu' });
};

const App = (props) => {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <MetricsProvider>
          <AuthUserWrapper>
            <PageRoutes />
          </AuthUserWrapper>
        </MetricsProvider>
      </AuthProvider>
      <Helmet>
        <script async>
          {`
            window.smartlook||(function(d) {
              var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
              var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
              c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';
              c.onload=initializeSmartlook; // Call the initialization function when the script is loaded
              h.appendChild(c);
            })(document);
          `}
        </script>
      </Helmet>
    </QueryClientProvider>
  );
};

const AuthUserWrapper = ({ children }) => {
  const auth = useAuth();
  const userId = auth.user?.uid;

  return (
    <FiltersProvider userId={userId}>
      {children}
    </FiltersProvider>
  );
};

export default App;
