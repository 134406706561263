import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../util/auth';
import { updateUser, getUser } from '../../util/db'; // Update the import path as needed
import '../../styles/OnboardingForm.css';
import LoadingIcon from '../cosmetics/LoadingIcon';

export default function PersonalDetailsForm({ onNextStep }) {
  const auth = useAuth();
  const ownerId = auth.user?.id;

  const [pending, setPending] = useState(false);

  // Initialize form data state and use a separate useEffect to fetch existing data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    // Fetch existing user data if ownerId is available
    if (ownerId) {
      getUser(ownerId).then((userData) => {
        if (userData) {
          // Update form data with existing user data
          setFormData({
            firstName: userData.first_name || '',
            lastName: userData.last_name || '',
          });
        }
      });
    }
  }, [ownerId]);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    if (!ownerId) {
      console.error('No user ID available for updating user data');
      return;
    }
    setPending(true);

    try {
      const response = await updateUser(ownerId, {
        first_name: data.firstName,
        last_name: data.lastName,
      });

      if (response && response.error) {
        console.error('Error updating user:', response.error);
      } else {
        onNextStep(); // Proceed to the next step
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    } finally {
      setPending(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 form-styling">
     <div className="input-group">
  <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
    First Name
  </label>
  <div className="mt-2">
    <input
      type="text"
      id="firstName"
      name="firstName"
      ref={register({ required: 'First name is required' })}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={formData.firstName}
      onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
      placeholder="First Name"
    />
  </div>
  {errors.firstName && (
    <p className="error-message">{errors.firstName.message}</p>
  )}
</div>

<div className="input-group">
  <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
    Last Name
  </label>
  <div className="mt-2">
    <input
      type="text"
      id="lastName"
      name="lastName"
      ref={register({ required: 'Last name is required' })}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={formData.lastName}
      onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
      placeholder="Last Name"
    />
  </div>
  {errors.lastName && (
    <p className="error-message">{errors.lastName.message}</p>
  )}
</div>

      <button
        type="submit"
        className={`submit-button ${pending ? 'loading' : ''}`}
        disabled={pending}
      >
        {!pending && <>Next</>}
        {pending && <LoadingIcon className="w-6" />}
      </button>
    </form>
  );
}
