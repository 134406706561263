// useFeatureGate.js
import { useAuth } from './auth';
import { useState, useCallback } from 'react';

const useFeatureGate = (allowedStatuses, metricCount, maxMetrics, showModalCallback = null) => {
  const { user } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const hasSubscriptionAccess = user && allowedStatuses.includes(user.stripeSubscriptionStatus);

  const checkAccess = useCallback((isExistingMetric = false) => {
    const hasMetricAccess = isExistingMetric || metricCount < maxMetrics;
    if (!hasSubscriptionAccess && !hasMetricAccess) {
        if (showModalCallback) {
            showModalCallback();
        } else {
            setModalOpen(true);
        }
        return false;
    }
    return true;
}, [hasSubscriptionAccess, metricCount, maxMetrics, showModalCallback]);

return { checkAccess, hasSubscriptionAccess, modalOpen, setModalOpen };
};

export default useFeatureGate;
