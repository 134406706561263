import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function AlertPrompt() {
  return (
    <div className="rounded-md bg-indigo-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-indigo-700">To access your latest benchmarking results and trends please add this quarter's metrics to your dashboard.</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <a href="/edit-metrics" className="font-medium text-indigo-600 hover:text-indigo-500">
              Update metrics
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
