import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import poweredByImage from '../images/powered-by-people-metrics.png';

const PDFGenerationComponent = ({ triggerDownload, onGenerated, cardRefs, peopleCardRefs, talentCardRefs, titleRef, subtitleRef }) => {
    const [loading, setLoading] = useState(false); // Initialize loading state

    useEffect(() => {
        if (triggerDownload) {
            generatePDF();
        }
    }, [triggerDownload, peopleCardRefs, talentCardRefs]);

    const generatePDF = async () => {
        setLoading(true); // Set loading to true when PDF generation starts
        if ((!peopleCardRefs || peopleCardRefs.length === 0) && (!talentCardRefs || talentCardRefs.length === 0)) {
            console.error("No cardRefs provided for PDF generation");
            return;
        }

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });

        // Define the width and height of the white border (30mm)
        const borderWidth = 30; // in mm
        const borderHeight = 30; // in mm

        // Calculate the position for the white border
        const borderX = 0;
        const borderY = 0;

        // Add a white border to cover up existing content
        pdf.setFillColor(255, 255, 255); // White color
        pdf.rect(borderX, borderY, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F');

        // Add an indigo frame around the edge of the PDF
        pdf.setDrawColor(99, 102, 241); // Indigo color RGB(99, 102, 241)
        pdf.setLineWidth(5); // Set the width of the frame
        pdf.rect(borderX, borderY, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

        const addImageToFooter = async () => {
            const image = new Image();
            image.src = poweredByImage; // Use the imported image

            // Wait for the image to load before proceeding
            await new Promise((resolve) => {
                image.onload = resolve;
            });

            // Fixed image width and height
            const imageWidth = 40; // Set the desired width
            const imageHeight = 15; // Set the desired height for A4 page

            for (let i = 1; i <= pdf.internal.getNumberOfPages(); i++) {
                pdf.setPage(i);

                // Calculate y based on the page height and padding
                const pageHeight = pdf.internal.pageSize.getHeight();
                const padding = 6; // Adjust the padding as needed
                const y = pageHeight - imageHeight - padding;

                const x = (pdf.internal.pageSize.getWidth() - imageWidth) / 2; // Center the image horizontally
                const imageDataURL = await getImageDataURL(image); // Convert the image to a Data URL
                // Add the image with a clickable link
                pdf.link(x, y, imageWidth, imageHeight, { url: 'https://www.peoplemetrics.fyi/' });
                pdf.addImage(image, 'PNG', x, y, imageWidth, imageHeight);
            }
        };

        const getImageDataURL = async (image) => {
            return new Promise((resolve) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                // Convert the canvas to a Data URL
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            });
        };

        pdf.setDrawColor(0, 0, 0);
        pdf.setLineWidth(0.5);

        let yOffset = 5; // Initial Y-offset for the first element below the title and subtitle

        const captureAndAddToPDF = async (ref, yOffset, fixedWidth = null) => {
            if (ref && ref.current) {
                const canvas = await html2canvas(ref.current, { scale: 2 });
                const imgData = canvas.toDataURL('image/png');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = fixedWidth || pdf.internal.pageSize.getWidth();
                const aspectRatio = imgProps.width / imgProps.height;
                const pdfHeight = fixedWidth ? pdfWidth / aspectRatio : (imgProps.height * pdfWidth) / imgProps.width;
                const padding = 2; // Adjust padding as needed
                const x = padding; // Set the left padding
                pdf.addImage(imgData, 'PNG', x, yOffset, pdfWidth - (2 * padding), pdfHeight); // Adjust the width to account for both left and right padding
                return pdfHeight;
            }
            return 0;
        };

        const titleHeight = await captureAndAddToPDF(titleRef, yOffset);
        yOffset += titleHeight + 30;

        const numberOfColumns = 3;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const cardMaxWidth = (pageWidth / numberOfColumns) - 2; // Adjust based on the number of columns and desired margin
        const allCardRefs = [...peopleCardRefs, ...talentCardRefs];
        const cropWidth = 450; // Width to crop from the right side

        for (let i = 0; i < allCardRefs.length; i++) {
            const cardRef = allCardRefs[i];
            if (cardRef.current) {
                const canvas = await html2canvas(cardRef.current, { scale: 2 });
                const croppedCanvas = document.createElement('canvas');
                const ctx = croppedCanvas.getContext('2d');
        
                croppedCanvas.width = canvas.width - cropWidth;
                croppedCanvas.height = canvas.height;
                
                // Crop the image
                ctx.drawImage(canvas, 0, 0, canvas.width - cropWidth, canvas.height, 0, 0, canvas.width - cropWidth, canvas.height);
        
                const imgData = croppedCanvas.toDataURL('image/png');
                const imgProps = pdf.getImageProperties(imgData);
                const aspectRatio = imgProps.width / imgProps.height;
                const cardWidth = Math.min(cardMaxWidth, imgProps.width * 0.264583); // Convert pixels to mm
                const cardHeight = cardWidth / aspectRatio;
                const xOffset = 20; // Adjust padding left or right
                const xPosition = xOffset + (i % numberOfColumns) * (cardMaxWidth - 10); // Adjust xPosition based on the column
                const yPosition = Math.floor(i / numberOfColumns) * (cardHeight - 15) + yOffset / 1.5; // Adjust yPosition based on row
        
                pdf.addImage(imgData, 'PNG', xPosition, yPosition, cardWidth, cardHeight);
        
                if ((i + 1) % (numberOfColumns * Math.floor(pdf.internal.pageSize.getHeight() / (cardHeight + 10))) === 0 && i + 1 < cardRefs.length) {
                    pdf.addPage();
                    yOffset = 10; // Reset yOffset for the new page
                } else if (i === cardRefs.length - 1 || (i + 1) % numberOfColumns === 0) {
                    yOffset = yPosition + cardHeight + 10; // Adjust yOffset for the next row or page
                }
            }
        }
        

        // Add the image to the footer of every page
        for (let i = 1; i <= pdf.internal.getNumberOfPages(); i++) {
            pdf.setPage(i);

            await addImageToFooter();
        }

        setLoading(false);
        pdf.save('your-peoplemetrics-report.pdf');
        if (onGenerated) onGenerated();
    };

    return (
        <div>
            {/* This component does not render anything */}
            {loading ? null : null}
        </div>
    );
};

export default PDFGenerationComponent;
