import React from "react";
import { useEffect, useState } from "react";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import Button from "../cosmetics/Button";
import { computeMetricPercentile } from "../../util/db";
import { columnMapping, stats } from "../../util/MetricsConfig";
import PercentileStatusPill from "./PercentileStatusPill";
import { Link } from "../../util/router";

const ResultsDetail = ({
  userMetricsData,
  metricName,
  totalSampleSize,
  allMetricsData,
  minValue,
  maxValue,
  userPercentile,
  percentileRanges,
  metricSampleSize

}) => {
  // Find the human-readable name for the metric
  const metricNamePretty = Object.keys(columnMapping).find(key => columnMapping[key] === metricName);
  // Find the metric details using the human-readable name
  const metricDetail = stats.find(detail => detail.name === metricNamePretty);

  //find unit for metric from stat
  const metric = stats.find(stat => stat.name === metricNamePretty);
  const metricUnit = metric.unit;
  const metricUnitLocation = metric.unitLocation;
  const displayMetricUnitFront = metricUnitLocation === "front";
  const [percentile, setPercentile] = useState(null);

  
  useEffect(() => {
  
    if (metricName && userMetricsData[metricName] && allMetricsData) {
      const calc_percentile = computeMetricPercentile(
        metricName,
        userMetricsData[metricName],
        allMetricsData
      );
      setPercentile(calc_percentile);
    }
  }, [userMetricsData, metricName, allMetricsData, totalSampleSize]);

  useEffect(() => { }, [percentile]);

  const buttonClick = () => {
    window.open("https://calendly.com/open-org/peoplemetrics-coaching-call");
  };

  return (
    <div className="results-detail pt-6 bg-white">
      {/* Update all occurrences of formattedMetricName to metricDetail?.name */}
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
        Executive Summary
      </h2>
      <p className="text-gray-700 mb-4">
        Here's how your company compares to the startup/scale-up market on: {" "}
        <strong>{metricDetail?.name}</strong>.
      </p>
      <p className="text-gray-700 mb-4">
        Your current {metricDetail?.name} is:{" "}
        <strong>
          {metricUnitLocation === "front" ? metricUnit + " " + userMetricsData[metricName] : userMetricsData[metricName] + " " + metricUnit}
        </strong>.
      </p>
      <p className="text-gray-700 mb-4">
  Based on the filters you have selected, this puts you in the{" "}
  <PercentileStatusPill
    userPercentile={userPercentile}
    metricName={metricDetail?.name}
  />{" "}
  range within the current sample of: <strong>{metricSampleSize}</strong> startups and scale-ups who have contributed data to the {metricNamePretty} benchmark.
</p>

      <p className="text-gray-700 mb-4">
        For context, the highest value in the sample is:{" "}
        <strong>
          {metricUnitLocation === "front" ? metricUnit + " " + maxValue : maxValue + " " + metricUnit}
        </strong>.
      </p>

      <p className="text-gray-700 mb-4">
        The lowest value in the sample is:{" "}
        <strong>
          {metricUnitLocation === "front" ? metricUnit + " " + minValue : minValue + " " + metricUnit}
        </strong>.
      </p>
      <p className="text-gray-700 mb-4">
        The range for the 0-10th percentile is between:
        <strong>{percentileRanges?.low} </strong>.
      </p>
      <p className="text-gray-700 mb-4">
        The value at the 50th percentile is:
        <strong>{percentileRanges?.mid}</strong>.
      </p>
      <p className="text-gray-700 mb-4">
        The range for the 90-100th percentile is between:
        <strong>{percentileRanges?.high}</strong>.
      </p>

      <p className="text-gray-700 mb-4">
        Your result is based on comparison to other companies based on the filters you have selected, and may change when compared to a different sample.

      </p>
      <p className="text-gray-700 mb-4">
        Use this benchmark to make a compelling case for change, identify areas for improvement, set goals, and
        make data-driven decisions.
      </p>

      <p className="text-gray-700 mb-4">
        To edit your {metricDetail.name} data, or any other metric{" "}
        <Link className="text-bold underline cursor-pointer" to="/edit-metrics">
          click here
        </Link>
        .
      </p>

      {/* Grey callout box */}


      <div className="bg-gray-200 rounded-lg p-4 mt-10">
        {" "}
        {/* Increased the mt-4 to mt-6 */}
        <div className="flex items-center">
          <LightBulbIcon className="w-6 h-6 ml-1" />
          <p className="text-gray-700 font-semibold ml-4">Need some help?</p>
        </div>
        <p className="text-gray-600 mt-2 mb-4">
          Receive a personalized one-on-one guidance session, where our team
          will provided tailored insights and actionable recommendations to help you build the case for change you need.
        </p>
        <Button variant="secondary" onClick={buttonClick}>
          Book Coaching Call
        </Button>
      </div>

      {/* <div className="bg-indigo-200 rounded-lg p-4 mt-6">
        {" "}
        <div className="flex items-center">
          <RocketLaunchIcon className="w-6 h-6 ml-1" />
          <p className="text-gray-700 font-semibold ml-4">Product update</p>
        </div>
        <p className="text-gray-600 mt-2 mb-4">
          We've got a ton of great feedback from early adopters like you on this chart and so are now actively working to enhance it to show a more detailed percentile view. Hope to ship a new version in the next week, thanks for your patience.
        </p>

      </div> */}
    </div>
  );
};

export default ResultsDetail;
