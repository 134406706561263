import React from "react";
import Meta from "../components/settings/Meta";
import AuthSection from "../components/auth/AuthSection";
import { useRouter } from "./../util/router";
import { SlimLayout } from "../components/general/SlimLayout";

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <SlimLayout>
      <Meta title="Auth" />
      <AuthSection
        size="md"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        type={router.query.type}
        // providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || "/dashboard"}
      />
      </SlimLayout>
    </>
  );
}

export default AuthPage;
