
import Meta from "../components/settings/Meta";
import { useAuth, requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { WelcomeLayout } from "../components/onboarding/WelcomeLayout";

function WelcomePage(props) {
    const router = useRouter();
    const auth = useAuth();

    return (
        <>
            <WelcomeLayout>
                <Meta title="Welcome" />
                
            </WelcomeLayout>
        </>
    );
}

export default requireAuth(WelcomePage);
