import React from "react";
import Logo from "../../images/transparent-logo.png";
import { useLocation } from 'react-router-dom';


function SectionHeader({ title, subtitle, strapline, titleRef, subtitleRef, className }) {

  const location = useLocation();

  const showLogo = !['/auth/signin', '/auth/signup', '/auth/forgotpass', '/auth/changepass'].includes(location.pathname);



  // Render nothing if no title, subtitle, or strapline
  if (!title && !subtitle && !strapline) {
    return null;
  }

  return (
    <header className={`mb-2 last:mb-0 ${className || ''}`}>
      {strapline && (
        <div className="text-sm uppercase font-bold tracking-wider mb-1 text-blue-700">
          {strapline}
        </div>
      )}

      {title && (
        <div className="text-center">
          <div className="flex items-center justify-center">
            {/* Conditional rendering applied only to the logo */}
            {showLogo && <img src={Logo} alt="Logo" className="h-11 w-11 mr-2" />}
            <h1 ref={titleRef} className="text-3xl md:text-4xl font-extrabold p-2">
              {title}
            </h1>
          </div>
        </div>
      )}

      {subtitle && (
        // Apply the ref to the h2 element
        <h2 ref={subtitleRef} className="mt-4 inline-block text-lg md:text-xl md:leading-relaxed font-medium max-w-screen-md opacity-80">
          {subtitle}
        </h2>
      )}
    </header>
  );
}

export default SectionHeader;
