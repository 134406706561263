import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMetrics } from '../../util/UserMetricsContext';
import { columnMapping, stats } from '../../util/MetricsConfig';

const brandColors = {
  purple: "#7C3AED",
  lightPurple: "#A78BFA",
  pink: "#EC4899",
  lightPink: "#F9A8D4",
  blue: "#3B82F6",
  lightBlue: "#93C5FD"
};

const formatLargeNumber = (value) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1).replace('.0', '') + 'M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1).replace('.0', '') + 'K';
  }
  return value.toFixed(0);
};

const MetricTrendChart = ({ 
  metricId, 
  yAxisLabel, 
  yAxisUnit, 
  selectedStage, 
  showUserValue, 
  aggregateData, 
  percentiles, 
  bins, 
  showLegendButtons = true,
  showCallout = true,
  onCalloutToggle
}) => {

  const { metricsData } = useMetrics();
  const [chartData, setChartData] = useState([]);
  const [visibleLines, setVisibleLines] = useState({
    userValue: true,
    bottom25: true,
    lower25_50: true,
    median: true,
    upper50_75: true,
    top25: true
  });

  const handleLegendClick = useCallback((dataKey) => {
    if (dataKey === 'callout') {
      onCalloutToggle();
    } else {
      setVisibleLines(prev => ({
        ...prev,
        [dataKey]: !prev[dataKey]
      }));
    }
  }, [onCalloutToggle]);

  const renderLegend = useCallback((props) => {
    const { payload } = props;

    if (!showLegendButtons) {
      return null;
    }

    const legendItems = [
      { dataKey: 'callout', value: 'Stats Callout', color: brandColors.purple },
      { dataKey: 'userValue', value: 'Your Value', color: brandColors.purple },
      { dataKey: 'bottom25', value: 'Bottom 25%', color: brandColors.lightBlue },
      { dataKey: 'lower25_50', value: 'Lower 25-50%', color: brandColors.blue },
      { dataKey: 'median', value: 'Median', color: brandColors.pink },
      { dataKey: 'upper50_75', value: 'Upper 50-75%', color: brandColors.lightPink },
      { dataKey: 'top25', value: 'Top 25%', color: brandColors.lightPurple }
    ];

    return (
      <div className="flex flex-wrap justify-center gap-2 mt-4">
        {legendItems.map((entry, index) => (
          <button
            key={`item-${index}`}
            onClick={() => handleLegendClick(entry.dataKey)}
            className={`px-3 py-1 text-xs font-medium rounded-full transition-colors duration-200 ease-in-out ${
              entry.dataKey === 'callout'
                ? (showCallout ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200' : 'bg-gray-100 text-gray-400 hover:bg-gray-200')
                : (visibleLines[entry.dataKey]
                  ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                  : 'bg-gray-100 text-gray-400 hover:bg-gray-200')
            }`}
          >
            <span
              className="inline-block w-3 h-3 rounded-md mr-2"
              style={{ backgroundColor: entry.dataKey === 'callout' ? (showCallout ? entry.color : '#ccc') : (visibleLines[entry.dataKey] ? entry.color : '#ccc') }}
            ></span>
            {entry.value}
          </button>
        ))}
      </div>
    );
  }, [visibleLines, showCallout, handleLegendClick, showLegendButtons]);

  const Callout = ({ data, metricConfig, yAxisUnit }) => {
    if (!data || data.length === 0) return null;
  
    const latestData = data[data.length - 1];
    const previousData = data.length > 1 ? data[data.length - 2] : null;
    const trend = calculateTrend(latestData.userValue, previousData?.userValue);
  
    const timePeriod = `${data[0].quarter} - ${latestData.quarter}`;
  
    return (
      <div className="bg-indigo-50 p-2 rounded-lg shadow-sm border border-indigo-100 text-xs max-w-[200px]">
        <h3 className="text-indigo-500 font-semibold mb-1 text-xs">Overview</h3>
        <div className="space-y-1">
          <div className="flex justify-between">
            <span className="text-gray-600">Period:</span>
            <span className="text-gray-800 font-medium">{timePeriod}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">Latest:</span>
            <span className="text-gray-800 font-medium">
              {formatValue(latestData.userValue, yAxisUnit, metricConfig?.unitLocation)}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">Trend:</span>
            {trend !== null ? (
              <span className={`font-medium ${getTrendColor(trend)}`}>
                {trend > 0 ? '+' : ''}{trend}%
              </span>
            ) : (
              <span className="text-gray-500">N/A</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const formatValue = (value, unit, unitLocation) => {
    if (value === undefined || value === null) return '';

    // Round to 2 decimal places
    const roundedValue = Number(value).toFixed(2);

    // Remove trailing zeros after the decimal point
    const trimmedValue = parseFloat(roundedValue);

    if (unitLocation === 'front') {
      return `${unit}${trimmedValue}`;
    } else {
      // Add a space before the unit if it's not empty and not a percentage sign
      const space = unit && unit !== '%' ? ' ' : '';
      return `${trimmedValue}${space}${unit}`;
    }
  };

  const metricConfig = stats.find(stat => columnMapping[stat.name] === metricId);
  const isOpinionated = metricConfig?.opinionated ?? true; // Default to true if not specified

  const formatYAxis = (tickItem) => {
    let formattedValue = tickItem;
    if (yAxisUnit === '$') {
      formattedValue = `$${formatLargeNumber(tickItem)}`;
    } else if (yAxisUnit === '%') {
      formattedValue = `${tickItem}%`;
    } else {
      formattedValue = formatValue(tickItem, yAxisUnit, metricConfig?.unitLocation);
    }
    return formattedValue.replace(' ', '\u00A0');
  };

  const invertedMetrics = ['Time To Offer', 'Cost Of Hire', 'Regrettable Attrition', 'Absenteeism Rate', 'IC : Manager Ratio', 'Time To Fill', 'General & Administrative Burn Rate', 'Early Attrition'];
  const isInverted = invertedMetrics.includes(metricConfig?.name);

  const calculateTrend = (current, previous) => {
    if (current === null || previous === null || isNaN(current) || isNaN(previous) || previous === 0) {
      return null;
    }
    const trendValue = ((current - previous) / Math.abs(previous) * 100);
    return isNaN(trendValue) || !isFinite(trendValue) ? null : Number(trendValue.toFixed(2));
  };

  const getTrendColor = (trendValue) => {
    if (trendValue === null) return 'text-gray-500';
    if (!isOpinionated) return 'text-indigo-600'; // Neutral color for non-opinionated metrics
    const isPositiveTrend = isInverted ? trendValue < 0 : trendValue > 0;
    return isPositiveTrend ? 'text-green-600' : 'text-red-600';
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log("Tooltip data:", { label, metricId, percentiles, bins });
      
      const quarterData = chartData.find(item => item.quarter === label);
      console.log("Full quarter data:", quarterData);

      const userValueEntry = payload.find(entry => entry.dataKey === 'userValue');
      const currentIndex = chartData.findIndex(item => item.quarter === label);
      const previousValue = currentIndex > 0 ? chartData[currentIndex - 1].userValue : null;
      const trend = calculateTrend(userValueEntry?.value, previousValue);
      
      const userPercentile = percentiles && percentiles[label] ? percentiles[label][metricId] : null;
      console.log("User percentile:", userPercentile);

      const hasSufficientData = quarterData.sampleSize > 5;

      const renderTrend = () => {
        if (trend === null) {
          return <span className="text-gray-500 text-xs">Limited trend data</span>;
        }
        return (
          <>
            <span className={`font-semibold ${getTrendColor(trend)} text-xs`}>
              {trend > 0 ? '+' : ''}{trend}%
            </span>
            <span className="text-indigo-400 text-xs"> vs previous quarter</span>
          </>
        );
      };

      const renderPercentile = () => {
        if (!hasSufficientData || userPercentile === null || userPercentile === undefined) {
          return null;
        }
        const roundedPercentile = Math.round(userPercentile);
        const suffix = roundedPercentile === 1 ? 'st' : roundedPercentile === 2 ? 'nd' : roundedPercentile === 3 ? 'rd' : 'th';
        return (
          <div className="ml-5 mt-1">
            <span className="text-indigo-600 text-xs font-semibold">
              {roundedPercentile}{suffix} percentile
            </span>
          </div>
        );
      };

      return (
        <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 text-sm">
          {userValueEntry && (
            <div className="mb-3 pb-3 border-b border-gray-200 bg-indigo-50 -mx-4 px-4 py-2">
              <div className="flex items-center mb-1">
                <div className="w-3 h-3 mr-2 rounded" style={{ backgroundColor: userValueEntry.color }}></div>
                <span className="text-indigo-600 font-medium text-sm">
                  Your Value: <span className="font-bold text-base">{formatValue(userValueEntry.value, yAxisUnit, metricConfig?.unitLocation)}</span>
                </span>
              </div>
              <div className="ml-5 text-indigo-500">
                {renderTrend()}
              </div>
              {renderPercentile()}
            </div>
          )}
          {hasSufficientData ? (
            ['top25', 'upper50_75', 'median', 'lower25_50', 'bottom25'].map((dataKey, index) => {
              const fullData = quarterData[dataKey];
              const color = brandColors[Object.keys(brandColors)[index]];
              return (
                <React.Fragment key={dataKey}>
                  {dataKey === 'median' && index !== 0 && (
                    <div className="my-2 border-t border-gray-200"></div>
                  )}
                  <div className="flex items-center mb-2">
                    <div className="w-3 h-3 mr-2 rounded" style={{ backgroundColor: color }}></div>
                    <span className="text-gray-700 flex-grow">
                      {dataKey === 'bottom25' ? 'Bottom 25%' :
                        dataKey === 'lower25_50' ? 'Lower 25-50%' :
                          dataKey === 'median' ? 'Median (50th percentile)' :
                            dataKey === 'upper50_75' ? 'Upper 50-75%' :
                              dataKey === 'top25' ? 'Top 25%' : dataKey}:
                    </span>
                    <span className="font-semibold text-gray-900 ml-2">
                      {dataKey === 'median' 
                        ? formatValue(fullData, yAxisUnit, metricConfig?.unitLocation)
                        : `${formatValue(fullData.min, yAxisUnit, metricConfig?.unitLocation)} - ${formatValue(fullData.max, yAxisUnit, metricConfig?.unitLocation)}`
                      }
                    </span>
                  </div>
                  {dataKey === 'median' && index !== 4 && (
                    <div className="my-2 border-t border-gray-200"></div>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <div className="text-gray-500 text-xs mt-2">Insufficient data to calculate ranges</div>
          )}
          <div className="mt-2 pt-2 border-t border-gray-200">
            <p className="text-gray-400 text-xs">{label}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const prepareChartData = () => {
      if (!metricsData || !metricsData.quarterlyData || !aggregateData) {
        return [];
      }

      const allQuarters = new Set([
        ...Object.keys(metricsData.quarterlyData),
        ...Object.keys(aggregateData)
      ]);

      const chartData = Array.from(allQuarters).map(quarter => {
        const userValue = metricsData.quarterlyData[quarter]?.[metricId];
        const aggregateStats = aggregateData[quarter]?.[metricId];


        let dataPoint = {
          quarter,
          userValue
        };

        if (aggregateStats && aggregateStats.length > 5) { // Require at least 6 data points
          const sortedValues = aggregateStats.sort((a, b) => a - b);
          const len = sortedValues.length;
          const q1Index = Math.floor(len / 4);
          const medianIndex = Math.floor(len / 2);
          const q3Index = Math.floor(len * 3 / 4);
          const min = sortedValues[0];
          const max = sortedValues[len - 1];

          dataPoint = {
            ...dataPoint,
            bottom25: { min: min, max: sortedValues[q1Index] },
            lower25_50: { min: sortedValues[q1Index], max: sortedValues[medianIndex - 1] },
            median: sortedValues[medianIndex],
            upper50_75: { min: sortedValues[medianIndex + 1], max: sortedValues[q3Index] },
            top25: { min: sortedValues[q3Index], max: max },
            sampleSize: len
          };
        } else {
          dataPoint.sampleSize = aggregateStats ? aggregateStats.length : 0;
        }

        return dataPoint;
      }).sort((a, b) => {
        const [aY, aQ] = a.quarter.split(' ');
        const [bY, bQ] = b.quarter.split(' ');
        return aY - bY || aQ.localeCompare(bQ);
      });

      return chartData;
    };

    const data = prepareChartData();
    setChartData(data);
  }, [metricsData, aggregateData, metricId]);

  if (chartData.length === 0) {
    return <div className="text-center text-gray-500 mt-4">No data available for this metric.</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-left text-gray-800 font-semibold text-xl mb-4">
        {metricConfig?.name || 'Metric'} Results
      </h2>
      <div className="relative">
        {showCallout && (
          <div className="mb-4">
            <Callout data={chartData} metricConfig={metricConfig} yAxisUnit={yAxisUnit} />
          </div>
        )}
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={chartData} margin={{ top: 10, right: 45, left: 55, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
            <XAxis
              dataKey="quarter"
              stroke="#4B5563"
              tick={{ fontSize: 10, fontWeight: 'bold' }}
              tickMargin={10}
              tickFormatter={(value) => value.replace(' ', '\n')}
              height={50}
              
            />
            <YAxis
              stroke="#4B5563"
              tick={{ fontSize: 10, fontWeight: 'bold' }}
              tickFormatter={formatYAxis}
              width={60}
              label={{
                value: yAxisLabel,
                angle: -90,
                position: 'insideLeft',
                offset: -5,
                style: {
                  textAnchor: 'middle',
                  fontSize: 12,
                  fontWeight: 'bold'
                }
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderLegend} />
            {showUserValue && (
              <Line
                type="monotone"
                dataKey="userValue"
                stroke={visibleLines.userValue ? brandColors.purple : 'transparent'}
                strokeWidth={2}
                dot={{ r: 4, fill: visibleLines.userValue ? brandColors.purple : 'transparent' }}
                activeDot={{ r: 6, fill: visibleLines.userValue ? brandColors.purple : 'transparent', stroke: '#fff', strokeWidth: 2 }}
                name="Your Value"
                connectNulls
              />
            )}
            <Line type="monotone" dataKey="bottom25.max" stroke={visibleLines.bottom25 ? brandColors.lightBlue : 'transparent'} strokeWidth={1.5} dot={false} activeDot={{ r: 4, fill: visibleLines.bottom25 ? brandColors.lightBlue : 'transparent', stroke: '#fff', strokeWidth: 2 }} name="Bottom 25%" connectNulls />
            <Line type="monotone" dataKey="lower25_50.max" stroke={visibleLines.lower25_50 ? brandColors.blue : 'transparent'} strokeWidth={1.5} dot={false} activeDot={{ r: 4, fill: visibleLines.lower25_50 ? brandColors.blue : 'transparent', stroke: '#fff', strokeWidth: 2 }} name="Lower 25-50%" connectNulls />
            <Line type="monotone" dataKey="median" stroke={visibleLines.median ? brandColors.pink : 'transparent'} strokeWidth={1.5} dot={false} activeDot={{ r: 4, fill: visibleLines.median ? brandColors.pink : 'transparent', stroke: '#fff', strokeWidth: 2 }} name="Median" connectNulls />
            <Line type="monotone" dataKey="upper50_75.min" stroke={visibleLines.upper50_75 ? brandColors.lightPink : 'transparent'} strokeWidth={1.5} dot={false} activeDot={{ r: 4, fill: visibleLines.upper50_75 ? brandColors.lightPink : 'transparent', stroke: '#fff', strokeWidth: 2 }} name="Upper 50-75%" connectNulls />
            <Line type="monotone" dataKey="top25.min" stroke={visibleLines.top25 ? brandColors.lightPurple : 'transparent'} strokeWidth={1.5} dot={false} activeDot={{ r: 4, fill: visibleLines.top25 ? brandColors.lightPurple : 'transparent', stroke: '#fff', strokeWidth: 2 }} name="Top 25%" connectNulls />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MetricTrendChart;