import React from "react";
import Meta from "../components/settings/Meta";
import PricingSection from "../components/general/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title="Simple. Predictable. Built for you."
        subtitle="Unlock the full benchmarking suite with the pro tier."
        strapline="Pricing"
        size="md"
        bgColor=""
        bgImage="../images/background-faqs.jpg"
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;
