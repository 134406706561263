import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './auth';

const UserMetricsContext = createContext();

export const useMetrics = () => useContext(UserMetricsContext);

export const MetricsProvider = ({ children }) => {

    const [metricsData, setMetricsData] = useState({
        orgCount: 0,
        allMetricsData: [],
        metricSampleSizes: {},
        userMetricsData: {}, // Ensure this is part of your initial state
      });
    // console.log('userMetricsData in context', metricsData.userMetricsData);s

// Inside your MetricsProvider, right before returning the provider
useEffect(() => {
    // console.log('MetricsData in Context Updated:', metricsData);
  }, [metricsData]);

  return (
    <UserMetricsContext.Provider value={{ metricsData, setMetricsData }}>
      {children}
    </UserMetricsContext.Provider>
  );
};