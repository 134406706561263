import React, { useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import Prism from 'prismjs'; //codeblock theme
import '../../styles/prism-nord.css';


function processJSXNodes(node) {
    if (typeof node === 'string') {
        const regex = /(\b\d+\b)/;
        return node.split(regex).map((part, index) =>
            regex.test(part) ? <span key={index} className="text-pink-400">{part}</span> : part
        );
    } else if (Array.isArray(node)) {
        return node.map((childNode, index) => <Fragment key={index}>{processJSXNodes(childNode)}</Fragment>);
    } else if (React.isValidElement(node)) {
        return React.cloneElement(node, node.props, processJSXNodes(node.props.children));
    } else {
        return node;
    }
}


export default function HowToModal({ isOpen, onClose, title, message1, codeBlock, message2 }) {

    useEffect(() => {
        if (isOpen) {
            Prism.highlightAll();
        }
    }, [isOpen]);



    const processedCodeBlock = processJSXNodes(codeBlock);



    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <LightBulbIcon className="h-6 w-6 text-indigo-600 mr-2" aria-hidden="true" />
                                            <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                                {title}
                                            </Dialog.Title>
                                        </div>
                                        <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Close</span>
                                            {/* Insert close icon here */}
                                        </button>
                                    </div>

                                    {message1 && (
                                        <div className="mt-4">
                                            <p className="text-sm text-gray-500">{message1}</p>
                                        </div>
                                    )}

                                    {processedCodeBlock && (
                                        <div className="mt-4 bg-gray-600 text-white font-mono text-sm p-4 rounded-lg overflow-x-auto">
                                            <pre><code>{processedCodeBlock}</code></pre>
                                        </div>
                                    )}

                                    {message2 && (
                                        <div className="mt-4">
                                            <p className="text-sm text-gray-500">{message2}</p>
                                        </div>
                                    )}

                                    <div className="mt-5">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={onClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
