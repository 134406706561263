import React, { useState, useEffect } from 'react';
import { ClockIcon, CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import Button from '../cosmetics/Button';

function MetricInput({ label, name, value, noData, onInputChange, onCheckboxChange, onClickHelp, helperInputText, unit }) {
  // State to track if the input value is valid
  const [isValid, setIsValid] = useState(true);

  // Validate the input value
  useEffect(() => {
    setIsValid(/^-?\d*\.?\d*$/.test(value)); // Regex to allow only numeric values (including decimal)
  }, [value]);

  // Define inline styles for the gradient background
  const gradientBackground = {
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(169, 168, 243, 0.5))',
  };

  // Function to render the unit addon
  const renderUnitAddon = (position) => (
    <span className={`inline-flex items-center border border-gray-300 px-3 text-gray-500 text-sm ${position === 'left' ? 'rounded-l-md border-r-0' : 'rounded-r-md border-l-0'}`}>
      {unit}
    </span>
  );

  return (
    <div className="relative bg-white p-4 rounded-lg shadow-md" style={gradientBackground}>
      <div className="absolute top-0 right-0 mt-2 mr-2 text-xs text-gray-500 flex items-center">
        Most recent
        <ClockIcon className="w-4 h-4 ml-1" />
      </div>

      <div className="modal-header">
        <h3 className="text-lg font-semibold text-gray-700">{label}</h3>
      </div>

      <div className="my-4">
        <div className="mb-2 relative">
          <label htmlFor={name} className="block text-xs font-small text-gray-700">
            {helperInputText}
          </label>
          <div className="flex rounded-md shadow-sm">

            {['Days', 'Months'].includes(unit) ? (
              <>
                <input
                  type="text"
                  name={name}
                  id={name}
                  value={value}
                  onChange={(e) => onInputChange(name, e.target.value)}
                  disabled={noData}
                  className="block flex-1 min-w-0 rounded-none rounded-l-md border-gray-300 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                  placeholder="0.00"
                />
                {renderUnitAddon('right')}
              </>
            ) : (
              <>
                {renderUnitAddon('left')}
                <input
                  type="text"
                  name={name}
                  id={name}
                  value={value}
                  onChange={(e) => onInputChange(name, e.target.value)}
                  disabled={noData}
                  className="block flex-1 min-w-0 rounded-none rounded-r-md border-gray-300 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
                  placeholder="0.00"
                />
              </>
            )}
          </div>
          {/* Error message outside of the flex container */}
          {!isValid && (
            <>
              <div className="flex items-center">
                <span class="pt-1 relative flex h-4 w-4">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-4 w-4 bg-indigo-500"></span>
                  
                    {/* <ExclamationCircleIcon className="h-5 w-5 text-indigo-500 mt-2"></ExclamationCircleIcon> */}
                </span>
              

                <p className="mt-2 text-sm text-indigo-600 pl-2">
                  Please enter a number (no commas).
                </p>
              </div>
            </>
          )}

        </div>

        {/* Checkbox and how-to-calculate link */}
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">

            <input
              type="checkbox"
              name={name}
              id={`no-data-${name}`}
              checked={noData}
              onChange={onCheckboxChange}
              className="rounded text-indigo-600 focus:ring-indigo-500"
            />
            <label htmlFor={`no-data-${name}`} className="ml-2 text-xs text-gray-600">We don't track</label>


          </div>


          <Button
            onClick={onClickHelp}
            variant="simple"
            size="sm"
            startIcon={<CursorArrowRaysIcon className="w-4 h-4" />}
            className="text-indigo-600"
          >
            How to calculate
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MetricInput;
