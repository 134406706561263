import React, { useEffect, useState, useContext, useMemo } from "react";
import { AdjustmentsHorizontalIcon, EyeIcon, WrenchIcon } from "@heroicons/react/20/solid";
import { useMetricsByOwner, fetchStageFilterData, fetchMetricsByOwnerAndPeriod, fetchAverageMetricsByOwnerAndPeriod, loadFilterConfig, loadDashboardConfig, saveDashboardConfig, fetchFilteredDataAndMedians } from "../../util/db";
import { useAuth } from "../../util/auth";
import UserMetricCard from "./UserMetricCard";
import "../../styles/global.css";
import { stats, columnMapping, headcountOptions, options, regionOptions, calculateQuarterlyTrend, calculateUserPercentile, checkForCurrentQuarterMetrics, getCurrentQuarter } from "../../util/MetricsConfig";
import PDFGenerationComponent from "../../util/PDFGenerationComponent";
import RefsContext from "../../util/RefsContext";
import Filters from "./Filters";
import { useFilters } from "../../util/FiltersContext";
import { useMetrics } from "../../util/UserMetricsContext";
import { useHistory } from "../../util/router";
import OnboardingPromptModal from "../modals/OnboardingPromptModal";
import AlertPrompt from "./AlertPrompt";
import MetricsDropdown from "./MetricsDropdown"; // Import the new Dropdown component
import NewFeatureFlag from "../general/NewFeatureFlag";
import { ExportDataButton } from './ExportDataButton';

export default function UserMetricsDashboard() {
  const auth = useAuth();
  const { metricsData, setMetricsData } = useMetrics();
  const history = useHistory();
  const ownerId = auth.user?.uid;
  const [dataSampleSize, setDataSampleSize] = useState(null);
  const [allMetricsData, setAllMetricsData] = useState([]);
  const [selectedMetricData, setSelectedMetricData] = useState([]);
  const [orgCount, setOrgCount] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [cardRefs, setCardRefs] = useState([]);
  const [peopleCardRefs, setPeopleCardRefs] = useState([]);
  const [talentCardRefs, setTalentCardRefs] = useState([]);
  const [refsReady, setRefsReady] = useState(false);
  const { titleRef, subtitleRef } = useContext(RefsContext);
  const { selectedHeadcount, selectedStage, selectedLocation, selectedTimePeriod } = useFilters();
  const [metricSampleSizes, setMetricSampleSizes] = useState({});
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [hasCurrentQuarterMetrics, setHasCurrentQuarterMetrics] = useState(true);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter());
  const [previousMetricsState, setPreviousMetricsState] = useState({});
  const [metricCount, setMetricCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterConfig, setFilterConfig] = useState(null);
  const [hiddenMetrics, setHiddenMetrics] = useState([]); // State to manage hidden metrics

  const { data: metrics, error: metricsError, isFetching } = useMetricsByOwner(ownerId);
  const defaultFilterConfig = {
    selectedHeadcount: headcountOptions.filter(option => option.label === 'All'),
    selectedStage: options.filter(option => option.label === 'All'),
    selectedLocation: regionOptions.filter(option => option.label === 'All'),
    selectedTimePeriod: "Current Quarter"
  };

  const [filteredData, setFilteredData] = useState(null);
  const [medians, setMedians] = useState(null);

  useEffect(() => {
    const fetchFilteredDataAndMediansEffect = async () => {
      if (!filterConfig) return;

      try {
        setIsLoading(true);
        const { data, medians } = await fetchFilteredDataAndMedians(filterConfig);
        setFilteredData(data);
        setMedians(medians);
      } catch (error) {
        console.error("Error fetching filtered data and medians:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilteredDataAndMediansEffect();
  }, [filterConfig]); // This effect will run whenever filterConfig changes

  useEffect(() => {
    const fetchFilterConfiguration = async () => {
      if (!ownerId) return;
      try {
        const config = await loadFilterConfig(ownerId);
        setFilterConfig(config || defaultFilterConfig);
      } catch (err) {
        console.error("Error fetching filter configuration:", err);
        // Apply default config if there's an error
        setFilterConfig(defaultFilterConfig);
      }
    };

    fetchFilterConfiguration();
  }, [ownerId]);

  useEffect(() => {
    const fetchDashboardConfiguration = async () => {
      if (!ownerId) return;
      try {
        const config = await loadDashboardConfig(ownerId);
        setHiddenMetrics(config?.hiddenMetrics || []);
      } catch (err) {
        console.error("Error fetching dashboard configuration:", err);
        // Apply default config if there's an error
        setHiddenMetrics([]);
      }
    };

    fetchDashboardConfiguration();
  }, [ownerId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!ownerId || !filterConfig) return;

      setIsLoading(true);
      try {
        const [fetchedMetrics, stageFilterData] = await Promise.all([
          fetchMetricsByOwnerAndPeriod(ownerId, selectedYear, selectedQuarter),
          fetchStageFilterData(
            selectedHeadcount.map(option => option.value),
            selectedStage.map(option => option.value),
            selectedLocation.map(option => option.value)
          ),
        ]);

        const { data, sampleSizes } = stageFilterData;

        if (!data || !sampleSizes) {
          console.error("Fetched data or sample sizes are undefined or null");
          setIsLoading(false);
          return;
        }

        const previousQuarter = selectedQuarter === "Q1" ? "Q4" : `Q${parseInt(selectedQuarter.slice(1)) - 1}`;
        const previousYear = selectedQuarter === "Q1" ? selectedYear - 1 : selectedYear;
        const fetchedPreviousMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, previousYear, previousQuarter);

        setOrgCount(data.length);
        setAllMetricsData(data);
        setMetricSampleSizes(sampleSizes);
        setMetricsData(prevState => ({
          ...prevState,
          orgCount: data.length,
          allMetricsData: data,
          metricSampleSizes: sampleSizes,
          userMetricsData: fetchedMetrics.length > 0 ? fetchedMetrics[0] : prevState.userMetricsData,
        }));

        setPreviousMetricsState(fetchedPreviousMetrics);
        setHasCurrentQuarterMetrics(true);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [ownerId, selectedYear, selectedQuarter, selectedHeadcount, selectedStage, selectedLocation, setMetricsData, filterConfig]);

  useEffect(() => {
    const checkRefs = () => {
      if (titleRef.current && subtitleRef.current) {
        setRefsReady(true);
      } else {
        setRefsReady(false);
      }
    };

    checkRefs();
  }, [titleRef, subtitleRef]);

  useEffect(() => {
    setPeopleCardRefs(metricsData.userMetricsData ? stats.filter(metric => metric.metricType === 'People').map(() => React.createRef()) : []);
  }, [metricsData.userMetricsData]);

  useEffect(() => {
    setTalentCardRefs(metricsData.userMetricsData ? stats.filter(metric => metric.metricType === 'Talent').map(() => React.createRef()) : []);
  }, [metricsData.userMetricsData]);

  useEffect(() => {
    setCardRefs(metricsData.userMetricsData ? stats.map(() => React.createRef()) : []);
  }, [metricsData.userMetricsData]);

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }

    return true;
  }

  useEffect(() => {
    const mergedCardRefs = metricsData.userMetricsData ? stats.map((_, index) => cardRefs[index]) : [];

    if (!arraysEqual(mergedCardRefs, cardRefs)) {
      setCardRefs(mergedCardRefs);
    }
  }, [metricsData.userMetricsData, cardRefs]);

  const handleGeneratePDFClick = () => {
    if (refsReady) {
      setShowPDF(true);
    }
  };

  const fetchDataByTimePeriod = async () => {
    if (!ownerId) return;
    setIsLoading(true);
    try {
      const timePeriodMapping = {
        'Current Quarter': selectedQuarter,
        'Previous Quarter': selectedQuarter === 'Q1' ? 'Q4' : `Q${parseInt(selectedQuarter.slice(1)) - 1}`,
        'Previous Quarter minus 1': selectedQuarter === 'Q1' ? 'Q3' : `Q${parseInt(selectedQuarter.slice(1)) - 2}`,
        'Previous Quarter minus 2': selectedQuarter === 'Q1' ? 'Q2' : `Q${parseInt(selectedQuarter.slice(1)) - 3}`,
        'Average of last 6 months': 'last_6_months',
        'Average of last 12 months': 'last_12_months'
      };

      const period = timePeriodMapping[selectedTimePeriod];
      let fetchedMetrics;

      if (period === 'last_6_months' || period === 'last_12_months') {
        fetchedMetrics = await fetchAverageMetricsByOwnerAndPeriod(ownerId, period);
      } else {
        fetchedMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, selectedYear, period);
      }


      setMetricsData(prevState => ({
        ...prevState,
        userMetricsData: fetchedMetrics.length > 0 ? fetchedMetrics[0] : prevState.userMetricsData,
      }));
    } catch (error) {
      console.error("Error fetching data by time period:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataByTimePeriod();
  }, [selectedTimePeriod]);

  const updatedStats = useMemo(() => {
    if (!metricsData.userMetricsData) return [];

    const userMetricsData = metricsData.userMetricsData;
    const data = allMetricsData;

    return stats.map((metric) => {
      const columnName = columnMapping[metric.name];
      const metricValue = parseFloat(userMetricsData[columnName]);
      const previousMetricValue = parseFloat(previousMetricsState[0]?.[columnName] ?? null);

      const validMetricValue = !isNaN(metricValue) ? metricValue : null;
      const validPreviousMetricValue = !isNaN(previousMetricValue) ? previousMetricValue : null;

      const percentile = validMetricValue !== null
        ? calculateUserPercentile(validMetricValue, data.map(dataItem => parseFloat(dataItem[columnName])))
        : null;
      const quarterlyTrend = selectedTimePeriod === 'Current Quarter'
        ? calculateQuarterlyTrend(validMetricValue, validPreviousMetricValue)
        : null;

      return {
        ...metric,
        db_col: columnName,
        stat: validMetricValue !== null ? validMetricValue : 'N/A',
        percentile,
        sampleSize: metricSampleSizes[columnName],
        quarterlyTrend,
        opinionated: metric.opinionated // Ensure this is included
      };
    });
  }, [metricsData.userMetricsData, allMetricsData, previousMetricsState, metricSampleSizes, selectedTimePeriod]);

  const toggleMetricVisibility = (metricName) => {
    setHiddenMetrics(prev => {
      const updatedHiddenMetrics = prev.includes(metricName)
        ? prev.filter(name => name !== metricName)
        : [...prev, metricName];

      saveDashboardConfig(ownerId, { hiddenMetrics: updatedHiddenMetrics });
      return updatedHiddenMetrics;
    });
  };

  const [historicalMetrics, setHistoricalMetrics] = useState([]);
  const [isHistoricalDataLoading, setIsHistoricalDataLoading] = useState(true);

  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (!ownerId) return;

      setIsHistoricalDataLoading(true);
      try {
        const currentYear = new Date().getFullYear();
        const allMetrics = [];

        for (let year = currentYear; year > currentYear - 5; year--) {
          for (let quarter = 1; quarter <= 4; quarter++) {
            const data = await fetchMetricsByOwnerAndPeriod(ownerId, year, `Q${quarter}`);
            if (data && data.length > 0) {
              allMetrics.push(...data);
            }
          }
        }

        setHistoricalMetrics(allMetrics);
      } catch (error) {
        console.error("Error fetching historical metrics:", error);
      } finally {
        setIsHistoricalDataLoading(false);
      }
    };

    fetchHistoricalData();
  }, [ownerId]);

  return (
    <div className="w-full">
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-2">
          <AdjustmentsHorizontalIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
          <h3 className="text-m font-bold text-gray-700">Filters</h3>
        </div>
        
        {filterConfig && (
          <Filters
            headcountOptions={headcountOptions}
            stageOptions={options}
            locationOptions={regionOptions}
            initialFilterConfig={filterConfig}
          />
        )}
        
        <div className="text-xs font-xs text-gray-500">
          <p>Compared to <strong>{orgCount}</strong> startups at your size and stage</p>
        </div>
        
        <div className="flex items-center space-x-2">
          <WrenchIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
          <h3 className="text-m font-bold text-gray-700">Configure</h3>
        </div>
        
        <div className="flex items-center justify-between">
          <MetricsDropdown hiddenMetrics={hiddenMetrics} toggleMetricVisibility={toggleMetricVisibility} />
          <ExportDataButton
            userMetricsData={historicalMetrics}
            hiddenMetrics={hiddenMetrics}
            filteredData={filteredData}
            medians={medians}
            isLoading={isLoading || isHistoricalDataLoading}
          />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center space-x-8 mt-8 mb-6">
          <h2 className="text-xl font-semibold text-gray-600">People Metrics</h2>
          {!hasCurrentQuarterMetrics && (
            <AlertPrompt className="h-4 w-4 text-gray-700" aria-hidden="true" />
          )}
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {updatedStats.length > 0 && updatedStats.filter(metric => metric.metricType === 'People' && !hiddenMetrics.includes(metric.name)).map((metric, index) => (
            <UserMetricCard
              key={metric.id}
              metric={metric}
              selectedStage={selectedStage}
              selectedHeadcount={selectedHeadcount}
              selectedLocation={selectedLocation}
              userPercentile={metric.percentile}
              ref={peopleCardRefs[index] ? peopleCardRefs[index] : null}
              sampleSize={metric.sampleSize}
              setSelectedMetricData={setSelectedMetricData}
              quarterlyTrend={metric.quarterlyTrend}
              isOpinionated={metric.opinionated} // Pass the opinionated flag
            />
          ))}
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center space-x-8 mt-8 mb-6">
          <h2 className="text-xl font-semibold text-gray-600">Talent Metrics</h2>
          {!hasCurrentQuarterMetrics && (
            <AlertPrompt className="h-4 w-4 text-gray-700" aria-hidden="true" />
          )}
        </div>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {updatedStats.length > 0 && updatedStats.filter(metric => metric.metricType === 'Talent' && !hiddenMetrics.includes(metric.name)).map((metric, index) => (
            <UserMetricCard
              key={metric.id}
              metric={metric}
              selectedStage={selectedStage}
              selectedHeadcount={selectedHeadcount}
              selectedLocation={selectedLocation}
              userPercentile={metric.percentile}
              ref={talentCardRefs[index] ? talentCardRefs[index] : null}
              sampleSize={metric.sampleSize}
              setSelectedMetricData={setSelectedMetricData}
              quarterlyTrend={metric.quarterlyTrend}
              isOpinionated={metric.opinionated} // Pass the opinionated flag
            />
          ))}
        </div>
      </div>

      {updatedStats.length === 0 && metrics && metrics.length === 0 && !isFetching ? (
        <OnboardingPromptModal
          isOpen={true}
          onClose={() => setShowOnboardingModal(false)}
        />
      ) : updatedStats.length === 0 ? (
        <p> </p>
      ) : null}
      {metricsError && <p>Error fetching your metrics: {metricsError.message}</p>}

      {showPDF && (
        <PDFGenerationComponent
          triggerDownload={showPDF}
          onGenerated={() => setShowPDF(false)}
          titleRef={titleRef}
          subtitleRef={subtitleRef}
          cardRefs={cardRefs}
          peopleCardRefs={peopleCardRefs}
          talentCardRefs={talentCardRefs}
        />
      )}
    </div>
  );
}
