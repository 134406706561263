import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import useFeatureGate from '../../util/FeatureGate'; // Import useFeatureGate


export default function MetricCountAlert({ hasAccess }) {
  if (hasAccess) {
    // If the user has access, don't display anything
    return null;
}

  return (
    <div className="border-l-4 border-indigo-400 bg-indigo-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-indigo-700">
            Free members can unlock up to 3 metrics and benchmarks.  
            <a href="/pricing" className="font-medium text-indigo-700 underline hover:text-indigo-600 pl-2">
               Upgrade your membership to unlock all metrics and benchmarks.
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}