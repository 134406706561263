import React from "react";

function LegalTerms(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
     
      <h1>Terms of Service</h1>
      <p><strong>Effective date:</strong> 01/02/2024</p>
      <p><strong>Last updated:</strong> 27/08/2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Open Org Group Ltd (“Company”, “we”, “our”, “us”).</p>
      <p>These Terms of Service (“Terms”, “Terms of Service”) govern your use of our web pages located at peoplemetrics.fyi (the Service) operated by Open Org Group Ltd.</p>
      <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard, and disclose information that results from your use of our web pages. Please read it <a href="https://www.peoplemetrics.fyi/legal/privacy-policy" target="_blank" rel="noreferrer">here</a>.</p>
      <p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood the Agreements, and agree to be bound by them.</p>
      <p>If you do not agree with (or cannot comply with) the Agreements, then you may not use the Service, but please let us know by emailing hello@openorg.fyi so we can try to find a solution. These Terms apply to all visitors, users, and others who wish to access or use the Service.</p>
      <p>Thank you for being responsible.</p>

      <h2>2. Communications</h2>
      <p>By creating an Account on our Service, you agree to subscribe to transactional product emails. All other emails e.g. newsletters, marketing or promotional materials and other information we may send will have an explicit opt in. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing hello@openorg.fyi.</p>

      <h2>3. Purchases</h2>
      <p>If you wish to purchase any product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
      <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>
      <p>We will employ the use of third-party services to facilitate payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>
      <p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: (i) product or service availability (ii) errors in the description or price of the product or service or (iii) errors in your order.</p>
      <p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>

      <h2>4. Promotions</h2>
      <p>Any Promotions made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.</p>
      <h2>5. Subscriptions</h2>
      <p>Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set on an annual basis.</p>
      <p>At the end of each Billing Cycle, your Subscription will automatically renew under the same conditions unless you cancel it or Open Org Group Ltd cancels it. You may cancel your Subscription renewal either through your account management page or by contacting Open Org Group Ltd customer support team on hello@openorg.fyi.</p>
      <p>A valid payment method, including credit card, is required to process the payment for your subscription. You shall provide Open Org Group Ltd with accurate and complete billing information including full name, address, state, post code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorise Open Org Group Ltd to charge all Subscription fees incurred through your account to any such payment instruments.</p>
      <p>Should automatic billing fail to occur for any reason, Open Org Group Ltd will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>

      <h2>6. Free Trial</h2>
      <p>Open Org Group Ltd may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”).</p>
      <p>You may be required to enter your billing information in order to sign up for Free Trial.</p>
      <p>If you do enter your billing information when signing up for Free Trial, you will not be charged by Open Org Group Ltd until the Free Trial has expired. On the last day of the Free Trial period, unless you cancel your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>
      <p>At any time and without notice, Open Org Group Ltd reserves the right to (i) modify the Terms of Service of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>

      <h2>7. Fee Changes</h2>
      <p>Open Org Group Ltd, at its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
      <p>Open Org Group Ltd will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
      <p>Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>

      <h2>8. Refunds</h2>
      <p>Except when required by law, paid Subscription fees are non-refundable.</p>

      <h2>9. Content</h2>
      <p>Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>

      <p>By posting Content on or through the Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>

      <p>You retain any and all of your rights to any Content you submit, post, or display on or through Service, and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</p>

      <p>Open Org Group Ltd has the right but not the obligation to monitor and edit all Content provided by users.</p>

      <p>In addition, Content found on or through this Service is the property of Open Org Group Ltd or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>

      <h2>10. Prohibited Uses</h2>
      <p>You may use the Service only for lawful purposes and in accordance with the Terms. You agree not to use the Service:</p>

      <p>(a) In any way that violates any applicable national or international law or regulation.</p>
      <p>(b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
      <p>(c) To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
      <p>(d) To impersonate or attempt to impersonate Open Org Group Ltd, an Open Org Group Ltd employee, another user, or any other person or entity.</p>
      <p>(e) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
      <p>(f) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm or offend Open Org Group Ltd or users of the Service or expose them to liability.</p>

      <p>Additionally, you agree not to:</p>

      <p>(a) Use the Service in any manner that could disable, overburden, damage, or impair the Service or interfere with any other party’s use of the Service, including their ability to engage in real-time activities through the Service.</p>
      <p>(b) Use any robot, spider, or other automatic device, process, or means to access the Service for any purpose, including monitoring or copying any of the material on the Service.</p>
      <p>(c) Use any manual process to monitor or copy any of the material on the Service or for any other unauthorized purpose without our prior written consent.</p>
      <p>(d) Use any device, software, or routine that interferes with the proper working of the Service.</p>
      <p>(e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
      <p>(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to the Service.</p>
      <p>(g) Attack the Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
      <p>(h) Take any action that may damage or falsify Company rating.</p>
      <p>(i) Otherwise attempt to interfere with the proper working of the Service.</p>

      <h2>11. Analytics</h2>
      <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

      <p><strong>Google Analytics</strong></p>
      <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its advertising network.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a></p>
      <p>We also encourage you to review Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer">https://support.google.com/analytics/answer/6004245</a></p>

      <p><strong>Mixpanel</strong></p>
      <p>Mixpanel is provided by Mixpanel Inc.</p>
      <p>You can prevent Mixpanel from using your information for analytics purposes by opting out. To opt-out of the Mixpanel service, please visit this page: <a href="https://mixpanel.com/optout/" target="_blank" rel="noreferrer">https://mixpanel.com/optout/</a></p>
      <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <a href="https://mixpanel.com/terms/" target="_blank" rel="noreferrer">https://mixpanel.com/terms/</a></p>

      <h2>12. No Use By Minors</h2>
      <p>The Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of the Service.</p>

      <h2>13. Accounts</h2>
      <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
      <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
      <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
      <p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>

      <h2>14. Intellectual Property</h2>
      <p>The Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of Open Org Group Ltd and its licensors. The Service is protected by copyright, trademark, and other laws of the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Open Org Group Ltd.</p>

      <h2>15. Error Reporting and Feedback</h2>
      <p>You may provide us either directly at hello@openorg.fyi or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to the Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
      <p>The third-party sites and tools mentioned above include the following:</p>
      <p><strong>Sentry</strong></p>
      <p>Sentry is an open-source error tracking solution provided by Functional Software Inc. More information is available here: <a href="https://sentry.io/privacy/" target="_blank" rel="noreferrer">https://sentry.io/privacy/</a></p>

      <h2>16. Links To Other Web Sites</h2>
      <p>The Service may contain links to third party web sites or services that are not owned or controlled by Open Org Group Ltd.</p>

      <p>Open Org Group Ltd has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>

      <p>YOU ACKNOWLEDGE AND AGREE THAT OPEN ORG GROUP LTD SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>

      <p>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD-PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>

      <h2>17. Disclaimer Of Warranty</h2>
      <p>THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>

      <p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>

      <p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>

      <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

      <h2>18. Limitation Of Liability</h2>
      <p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

      <h2>19. Termination</h2>
      <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>

      <p>If you wish to terminate your account, you may email hello@openorg.fyi.</p>

      <p>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
      <h2>20. Governing Law</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of the United Kingdom without regard to its conflict of law provisions.</p>

      <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>

      <h2>21. Changes To Service</h2>
      <p>We reserve the right to withdraw or amend the Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Service, or the entire Service, to users, including registered users.</p>

      <h2>22. Amendments To Terms</h2>
      <p>We may amend the Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>

      <p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>

      <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorised to use Service.</p>

      <h2>23. Waiver And Severability</h2>
      <p>No waiver by Company of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>

      <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>

      <h2>24. Acknowledgement</h2>
      <p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>

      <h2>25. Contact Us</h2>
      <p>Please send your feedback, comments, requests for technical support:</p>
      <p>By email: hello@openorg.fyi.</p>
    </div>
  );
}

export default LegalTerms;
