import React from "react";

function LegalPrivacy(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h1>Privacy Policy</h1>
      <p><strong>Effective date:</strong> 01/02/2024</p>
      <p><strong>Last updated:</strong> 01/02/2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to Open Org Group Ltd.</p>
      <p>Open Org Group Ltd (“us”, “we”, or “our”) operates peoplemetrics.fyi (hereinafter referred to as the “Service”).</p>
      <p>Our Privacy Policy governs your visit to peoplemetrics.fyi, and explains how we collect, safeguard and disclose information that results from your use of the Service.</p>
      <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
      <p>Our Terms and Conditions (<strong>“Terms”</strong>) govern all use of the Service and together with the Privacy Policy constitutes your agreement with us (<strong>“agreement”</strong>).</p>

      <h2>2. Definitions</h2>
      <p><strong>THE SERVICE</strong> means the peoplemetrics.fyi website operated by Open Org Group Ltd.</p>

      <p><strong>PERSONAL DATA</strong> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>

      <p><strong>COMPANY DATA</strong> refers to information related to a specific company or organization that is provided by users of the Service. This data typically includes various metrics, statistics, or other information about the company's operations, practices, or performance. Company Data may encompass details such as workforce demographics, transparency scores across different dimensions, retention rates, cost of hire and any other non-personal information specific to a company’s people strategy. It is important to note that Company Data does not include personally identifiable information (Personal Data) about individual employees or users of the Service.</p>
      <p>Company Data is the collective data that helps assess and analyse the transparency and performance of a company across various aspects without revealing any individual's personal information.</p>

      <p><strong>USAGE DATA</strong> is data collected automatically either generated by use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>

      <p><strong>COOKIES</strong> are small files stored on your device (computer or mobile device).</p>

      <p><strong>DATA CONTROLLER</strong> refers to an individual or legal entity who, either independently or in collaboration with others, establishes the objectives and methods for processing personal data. In the context of this Privacy Policy, we act as the Data Controller for your data.</p>

      <p><strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> refers to any individual or legal entity responsible for processing data on behalf of the Data Controller. We may engage the services of different Service Providers to enhance the efficiency of your data processing.</p>

      <p><strong>DATA SUBJECT</strong> is any living individual who is the subject of Personal Data.</p>

      <p><strong>THE USER</strong> is the individual using the Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>

      <h2>3. Information Collection and Use</h2>
      <p>We collect several different types of information for various purposes to provide and improve the Service to you.</p>

      <h2>4. Types of Data Collected</h2>

      <h3>Personal Data</h3>
      <p>While using the Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
      <ul>
        <li>(a) Email address</li>
        <li>(b) First name and last name</li>
        <li>(c) Cookies and Usage Data</li>
      </ul>
      <p>We will use your email address to contact you with product update emails, newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link in emails or by emailing at hello@openorg.fyi.</p>

      <h3>Company Data</h3>
      <p>While using the Service, you will have the option to enter your company’s people metrics into our platform to access benchmarks and reports based on aggregated and anonymised data. We take your privacy and data security seriously, and we want to explain how this data is handled.</p>
      <p>The data you provide for benchmarking and reporting purposes is anonymized and aggregated. This means that individual company-specific information is not visible to other users of the platform. The types of data collected for benchmarking and reporting may include, but are not limited to:</p>
      <ul>
        <li>(a) Company size, stage, location and website url</li>
        <li>(b) Company people metrics, such as workforce diversity, employee engagement scores, retention rates, cost of hire, revenue per employee, time to hire, ENPS, internal mobility, absenteeism, average tenure, offer acceptance rate, probation rates, people team ratio.</li>
        <li>(c) Metrics related to your Company's transparency across various dimensions.</li>
      </ul>
      <p>The anonymized and aggregated benchmarking and reporting data is used solely for the purpose of generating insights and providing valuable benchmarks to you and other users of the platform. These insights help you assess your company's performance in comparison to industry standards and best practices.</p>
      <p>Please note that you are responsible for the accuracy and legality of the data you provide. Ensure that any data you input into our platform complies with applicable laws and regulations.</p>
      <p>By using the Service and entering benchmarking and reporting data, you acknowledge and consent to the collection, use, and retention of this anonymized data for the purposes described above.</p>
      <p>If you have any questions or concerns regarding the handling of benchmarking and reporting data, please contact us at hello@openorg.fyi.</p>

      <h3>Usage Data</h3>
      <p>We may also collect information that your browser sends whenever you visit the Service or when you access the Service by or through a mobile device (“Usage Data”).</p>
      <p>This Usage Data may include information such as your computer's Internet Protocol address (IP address), browser type, browser version, the pages of the Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
      <p>When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device’s unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>

      <h3>Location Data</h3>
      <p>We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of the Service, to improve and customize the Service.</p>
      <p>You can enable or disable location services when you use the Service at any time by way of your device settings.</p>

      <h3>Tracking Cookies Data</h3>
      <p>We use cookies and similar tracking technologies to track the activity on the Service and we hold certain information.</p>
      <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyse the Service.</p>
      <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the Service.</p>

      <p>Examples of Cookies we use:</p>
      <ul>
        <li>(a) Session Cookies: We use Session Cookies to operate the Service.</li>
        <li>(b) Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</li>
        <li>(c) Security Cookies: We use Security Cookies for security purposes.</li>
        <li>(d) Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
      </ul>
      <h2>5. Use of Data</h2>
      <p>Open Org Group Ltd uses the collected data for various purposes:</p>
      <ul>
        <li>(a) to provide and maintain the Service;</li>
        <li>(b) to notify you about changes to the Service;</li>
        <li>(c) to allow you to participate in interactive features of the Service when you choose to do so;</li>
        <li>(d) to provide customer support;</li>
        <li>(e) to gather analysis or valuable information so that we can improve the Service;</li>
        <li>(f) to monitor the usage of the Service;</li>
        <li>(g) to detect, prevent and address technical issues;</li>
        <li>(h) to fulfil any other purpose for which you provide it;</li>
        <li>(i) to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
        <li>(j) to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</li>
        <li>(k) to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li>
        <li>(l) in any other way we may describe when you provide the information;</li>
        <li>(m) for any other purpose with your consent.</li>
      </ul>

      <h2>6. Retention of Data</h2>
      <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <p>We will also retain Usage Data and Location Data for internal analysis purposes. Usage Data and Location Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of the Service, or we are legally obligated to retain this data for longer time periods.</p>
      <p>We will retain your anonymized Company Data indefinitely to provide benchmarking and reporting services. We implement robust security measures to protect this data from unauthorized access.</p>

      <h2>7. Transfer of Data</h2>
      <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
      <p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
      <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
      <p>Open Org Group Ltd will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

      <h2>8. Disclosure of Data</h2>
      <p>We may disclose personal information that we collect, or you provide:</p>
      <ul>
        <li>(a) Disclosure for Law Enforcement. Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</li>
        <li>(b) Business Transaction. If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</li>
        <li>(c) Other cases. We may disclose your information also:</li>
        <ul>
          <li>(i) to fulfil the purpose for which you provide it;</li>
          <li>(ii) for any other purpose disclosed by us when you provide the information;</li>
          <li>(iii) with your consent in any other cases;</li>
        </ul>
      </ul>

      <h2>9. Security of Data</h2>
      <p>While we employ industry-standard security measures to safeguard your Personal Data, it's important to note that no method of transmission over the Internet or electronic storage can guarantee 100% security. We are committed to protecting your data to the best of our abilities, but absolute security cannot be assured.</p>

      <h2>10. Your Data Protection Rights under the UK Data Protection Act (DPA 2019)</h2>
      <p>If you are a resident of the United Kingdom (UK), you have certain data protection rights under the Data Protection Act (DPA), which enshrines the UK General Data Protection Regulation (UK GDPR).</p>
      <p>We aim to take reasonable steps to enable you to correct, amend, delete, or limit the use of your Personal Data.</p>
      <p>If you wish to know what Personal Data we hold about you or if you want it to be removed from our systems, please email us at hello@openorg.fyi.</p>
      <p>In certain circumstances, you have the following data protection rights:</p>
      <ul>
        <li>(a) The Right to Access: You have the right to access, update, or request deletion of the information we hold about you.</li>
        <li>(b) The Right of Rectification: You have the right to have your information corrected if it is inaccurate or incomplete.</li>
        <li>(c) The Right to Object: You have the right to object to our processing of your Personal Data.</li>
        <li>(d) The Right of Restriction: You have the right to request the restriction of processing your personal information.</li>
        <li>(e) The Right to Data Portability: You have the right to receive a copy of your Personal Data in a structured, machine-readable, and commonly used format.</li>
        <li>(f) The Right to Withdraw Consent: If we rely on your consent to process your personal information, you have the right to withdraw your consent at any time.</li>
      </ul>
      <p>Please note that we may ask you to verify your identity before responding to such requests. Also, please be aware that we may not be able to provide our services without certain necessary data.</p>
      <p>You also have the right to lodge a complaint with the Information Commissioner's Office (ICO), the UK's Data Protection Authority, about our collection and use of your Personal Data. For more information, please visit the ICO website at <a href="https://ico.org.uk/">https://ico.org.uk/</a>.</p>

      <h2>11. Analytics</h2>
      <p>We may use third-party Service Providers to monitor and analyse the use of the Service.</p>

      <h3>Google Analytics</h3>
      <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of the Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
      <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
      <p>We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.</p>

      <h3>Mixpanel</h3>
      <p>Mixpanel is provided by Mixpanel Inc.</p>
      <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: <a href="https://mixpanel.com/optout/">https://mixpanel.com/optout/</a></p>
      <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <a href="https://mixpanel.com/terms/">https://mixpanel.com/terms/</a></p>

      <h3>PostHog</h3>
      <p>PostHog is a product analytics platform built for the modern enterprise, with the differentiators of being open source and having a broader view of the tools needed to make a product successful.</p>
      <p>For more information about PostHog, please visit their Privacy Policy: <a href="https://posthog.com/privacy">https://posthog.com/privacy</a></p>

      <h2>12. CI/CD tools</h2>
      <p>We may use third-party Service Providers to automate the development process of our Service.</p>

      <h3>GitHub</h3>
      <p>GitHub is provided by GitHub, Inc.</p>
      <p>GitHub is a development platform to host and review code, manage projects, and build software.</p>
      <p>For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub Privacy Policy page: <a href="https://help.github.com/en/articles/github-privacy-statement">https://help.github.com/en/articles/github-privacy-statement</a>.</p>

      <h2>13. Payments</h2>
      <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
      <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
      <p>The payment processors we work with are:</p>
      <ul>
        <li>Stripe: Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></li>
      </ul>

      <h2>14. Links to Other Sites</h2>
      <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites or services.</p>

      <h2>15. Children's Privacy</h2>
      <p>The Service is not intended for use by children under the age of 18 (“Child” or “Children”).</p>
      <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>

      <h2>16.	Changes to This Privacy Policy</h2>

      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <p>We will let you know via email and/or a prominent notice on the Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>

      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

      <h2>17.	Contact Us</h2>

      <p>If you have any questions about this Privacy Policy, please contact us:</p>

      <p>By email: hello@openorg.fyi.</p>


    </div>
  );
}

export default LegalPrivacy;
