import React from 'react';
import { useLocation } from '../../util/router';
import Navbar from '../landingPage/Navbar';
import Footer from '../landingPage/Footer';

const LayoutWithNavAndFooter = ({ children }) => {
  const location = useLocation();
  
  // Determine if Navbar and Footer should be hidden based on the current route
  const shouldHideNavAndFooter = location.pathname === ('/purchase/pro') || location.pathname.startsWith('/dashboard') || location.pathname === ('/edit-metrics') || location.pathname.startsWith('/dashboard')|| location.pathname.startsWith('/trend-analysis') || location.pathname.startsWith('/onboarding') || location.pathname.startsWith('/auth');

  const isLandingPage = location.pathname === '/';

  return (
    <>
      {isLandingPage && (
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      )}
      {!shouldHideNavAndFooter && <Navbar />}
      {children}
      {!shouldHideNavAndFooter && <Footer />}
    </>
  );
};

export default LayoutWithNavAndFooter;