import React, { useState, useEffect, useMemo } from 'react';
import { createOrUpdateMetric, useMetricsByOwner } from '../../util/db';
import { useAuth } from '../../util/auth';
import '../../styles/OnboardingForm.css';
import '../../styles/ModalStyles.css';
import Modal from 'react-modal';
import MetricInput from './MetricInput';
import ErrorModal from '../modals/ErrorModal';
import HowToModal from '../modals/HowToModal';
import { stats, columnMapping } from '../../util/MetricsConfig';
import { getCurrentQuarter } from "../../util/MetricsConfig";
import SuccessModal from '../modals/SuccessModal';

Modal.setAppElement('#root');

const initialMetricsState = stats.reduce((acc, { name }) => {
  const metricKey = columnMapping[name];
  acc[metricKey] = { value: '', noData: false };
  return acc;
}, {});

export default function PeopleMetricsForm({ onNextStep, selectedMetrics }) {
  const auth = useAuth();
  const ownerId = auth.user?.uid;
  const [metricsState, setMetricsState] = useState(initialMetricsState);
  const [openedModal, setOpenedModal] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [successModalTitle, setSuccessModalTitle] = useState('');
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [savingStatus, setSavingStatus] = useState({});
  const { data: metrics, error: metricsError } = useMetricsByOwner(ownerId);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter());

  const displayedMetrics = useMemo(() => stats.filter(stat => selectedMetrics.includes(stat.id)), [selectedMetrics]);

  useEffect(() => {
    if (metrics && metrics.length > 0) {
      const fetchedMetrics = metrics[0];
      const updatedState = { ...initialMetricsState };

      Object.keys(initialMetricsState).forEach(key => {
        if (fetchedMetrics.hasOwnProperty(key)) {
          updatedState[key] = {
            value: fetchedMetrics[key] || '',
            noData: fetchedMetrics[key] === null || fetchedMetrics[key] === '',
          };
        }
      });

      setMetricsState(updatedState);
    }
  }, [metrics]);

  const handleCheckboxChange = (name, checked) => {
    setMetricsState(prevState => ({
      ...prevState,
      [name]: { ...prevState[name], noData: checked, value: checked ? '' : prevState[name].value },
    }));
  };

  const handleInputChange = (name, value) => {
    setMetricsState(prevState => ({
      ...prevState,
      [name]: { ...prevState[name], value },
    }));
  };

  const handleSave = async (metricKey) => {
    const data = {
      [metricKey]: metricsState[metricKey].value === '' ? null : Number(metricsState[metricKey].value),
      year: selectedYear,
      time_period: selectedQuarter,
    };

    setSavingStatus(prev => ({ ...prev, [metricKey]: 'saving' }));
    try {
      await createOrUpdateMetric(ownerId, selectedYear, selectedQuarter, data);
      setSavingStatus(prev => ({ ...prev, [metricKey]: 'saved' }));
      setTimeout(() => setSavingStatus(prev => ({ ...prev, [metricKey]: '' }), 2000));
    } catch (error) {
      console.error('Error updating metric', error);
      setSavingStatus(prev => ({ ...prev, [metricKey]: 'error' }));
    }
  };

  const handleSaveAll = async () => {
    const data = Object.entries(metricsState).reduce((acc, [key, valueObj]) => {
      acc[key] = valueObj.noData ? null : (valueObj.value === '' ? null : Number(valueObj.value));
      return acc;
    }, {});

    data.year = selectedYear;
    data.time_period = selectedQuarter;

    try {
      await createOrUpdateMetric(ownerId, selectedYear, selectedQuarter, data);
      setSuccessModalTitle('Success!');
      setSuccessModalMessage('All your metrics have been saved successfully!');
      setSuccessModalOpen(true);
    } catch (error) {
      console.error('Failed to save metrics', error);
      setSuccessModalTitle('Error!');
      setSuccessModalMessage('There was a problem saving your metrics. Please try again.');
      setSuccessModalOpen(true);
    }
  };

  const openModal = (metricKey) => {
    setOpenedModal(metricKey);
  };

  const closeModal = () => {
    setOpenedModal(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = Object.keys(metricsState).reduce((acc, key) => {
      const { value, noData } = metricsState[key];
      acc[key] = noData ? null : (value === '' ? null : Number(value));
      return acc;
    }, {});

    data.year = selectedYear;
    data.time_period = selectedQuarter;

    try {
      await createOrUpdateMetric(ownerId, selectedYear, selectedQuarter, data);
      onNextStep();
    } catch (error) {
      console.error('Error submitting metrics:', error);
      setErrorModalMessage('Error submitting metrics. Please try again.');
      setErrorModalOpen(true);
    }
  };

  const [textState, setTextState] = useState('Add your people metrics to gain access to your benchmark results');

  const completedMetricsCount = useMemo(() => selectedMetrics.reduce((count, metricId) => {
    const metricKey = columnMapping[stats.find(stat => stat.id === metricId).name];
    const metric = metricsState[metricKey];
    return metric && !metric.noData && metric.value !== '' ? count + 1 : count;
  }, 0), [metricsState, selectedMetrics]);

  const totalMetricsCount = selectedMetrics.length;
  const progressBarWidth = `${(completedMetricsCount / totalMetricsCount) * 100}%`;

  useEffect(() => {
    if (completedMetricsCount === 0) {
      setTextState(`Add your people metrics to gain access to your benchmark results`);
    } else if (completedMetricsCount < totalMetricsCount) {
      setTextState(`🎉 Keep going! You're doing great.`);
    } else {
      setTextState(`🎉 All done! Hit the button below to see your results.`);
    }
  }, [completedMetricsCount, totalMetricsCount]);

  const renderHowToModal = (metricKey) => {
    const metric = stats.find(stat => columnMapping[stat.name] === metricKey);
    if (!metric || !metric.howToCalculate) return null;

    const { description, example, conclusion, externalAssessment } = metric.howToCalculate;

    return (
      <HowToModal
        isOpen={openedModal === metricKey}
        onClose={closeModal}
        title={`${metric.name} Calculation`}
        message1={
          <p className="mb-4">{description}</p>
        }
        codeBlock={
          <>
            {example && (
              <>
                <p className="mb-4">📝 Example</p>
                {example.scenario && <p className="mb-4">{example.scenario}</p>}
                {example.steps && example.steps.map((step, index) => (
                  <p key={index}>
                    {step.includes("Take the org transparency assessment here") ? (
                      <>
                        Take the org transparency assessment <a href={externalAssessment.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 font-bold">here</a> to get your score
                      </>
                    ) : (
                      step
                    )}
                  </p>
                ))}
                {example.result && (
                  <>
                    <p>------------------------------</p>
                    <p>{example.result}</p>
                  </>
                )}
              </>
            )}
            {!example && <p>No example available for this metric.</p>}
          </>
        }
        message2={
          <p className="mb-4">👉 {conclusion || "No additional conclusion available."}</p>
        }
      />
    );
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-center text-lg font-semibold text-gray-700">🚀 To unlock your benchmark dashboard, add your most recent metrics or check: 'we don't track'.</h1>
      <div className="py-4 rounded-t-lg">
        <div className="container mx-auto text-white text-center relative">
          <div className="h-6 bg-[#cbd5e1] rounded-full mb-2">
            <div
              className="h-full rounded-full bg-gradient-to-r from-pink-300 to-indigo-500 transition-all duration-500"
              style={{ width: progressBarWidth }}
            ></div>
          </div>
          <div className="text-sm text-gray-600">
            <p className="mb-1">{textState}</p>
            <p className="mb-1 font-semibold">{`${completedMetricsCount}/${totalMetricsCount} metrics added!`}</p>
          </div>
        </div>
      </div>

      {/* Render HowToModals */}
      {stats.map(({ name }) => {
        const metricKey = columnMapping[name];
        return renderHowToModal(metricKey);
      })}

      <form onSubmit={handleSubmit} className="max-w-7xl mx-auto p-4 bg-white shadow-md rounded">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 pb-4">
          {displayedMetrics.map((stat) => {
            const metricKey = columnMapping[stat.name];
            const metric = metricsState[metricKey] ?? { value: '', noData: false };

            return (
              <MetricInput
                key={stat.id}
                label={stat.name}
                unit={stat.unit}
                name={metricKey}
                value={metric.value}
                noData={metric.noData}
                onInputChange={(name, value) => handleInputChange(name, value)}
                onCheckboxChange={(e) => handleCheckboxChange(metricKey, e.target.checked)}
                onClickHelp={() => openModal(metricKey)}
              />
            );
          })}
        </div>

        <button type="submit" className="submit-button mt-4">
          🌈 Go to my shiny dashboard!
        </button>
      </form>

      <div className="container mx-auto p-4">
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          title={successModalTitle}
          message={successModalMessage}
        />
      </div>
      <ErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        title="Whoops!"
        message={errorModalMessage}
      />
    </div>
  );
}


