import { Img } from 'react-image';
import backgroundImage from '../../images/background-auth.jpg';
import testimonialAvatar from '../../images/holly_avatar.jpeg';

export function Testimonial() {
    return (
        <>
            <div className="max-w-lg p-8 text-center bg-white bg-opacity-80 backdrop-filter backdrop-blur-sm rounded-lg shadow-lg">
                <figure className="mt-10 flex flex-auto flex-col justify-between items-center"> {/* Center items */}
                    <blockquote className="text-lg leading-8 text-gray-900">
                        <p>
                            "Peoplemetrics.fyi came at the perfect time for us. As a People team, we have wanted to be more data focused and try to decide which were the best metrics to use, benchmark and feedback to the Founders on a regular basis."
                        </p>
                    </blockquote>
                </figure>
                <figcaption className="mt-10 flex items-center justify-center gap-x-6"> {/* Center items */}
                    <img
                        className="h-14 w-14 rounded-full bg-gray-50"
                        src={testimonialAvatar} 
                        alt="holly testimonial"
                    />
                    <div className="text-base text-center"> {/* Center text */}
                        <div className="font-semibold text-gray-900">Holly Leckenby Rye</div>
                        <div className="mt-1 text-gray-500">People @ Lottie</div>
                    </div>
                </figcaption>
            </div>
        </>
    );
}
