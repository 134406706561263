import React from "react";
import Meta from "../components/settings/Meta";
import DashboardLayout from "../components/dashboard/DashboardLayout"; // Import DashboardLayout
import { requireAuth } from "./../util/auth";

function MetricDashboardPage(props) {
  return (
    <>
      <Meta title="Your Metric" />
      <DashboardLayout>
      </DashboardLayout>
    </>
  );
}

export default requireAuth(MetricDashboardPage);
