import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useMetrics } from '../../util/UserMetricsContext';
import MetricTrendChart from './MetricTrendChart';
import { useAuth } from '../../util/auth';
import { fetchMetricsByOwnerAndPeriod, fetchAggregateMetrics } from '../../util/db';
import { getCurrentQuarter } from '../../util/MetricsConfig';
import { columnMapping, stats } from '../../util/MetricsConfig';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import DownloadChartModal from '../modals/DownloadChartModal';
import NewFeatureFlag from '../general/NewFeatureFlag';

const TrendAnalysis = () => {
  const auth = useAuth();
  const { metricsData, setMetricsData } = useMetrics();
  const [selectedMetric, setSelectedMetric] = useState('engagement');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const ownerId = auth.user?.uid;
  const [selectedStage, setSelectedStage] = useState('All Stages');

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const chartRef = useRef(null);
  const [showCallout, setShowCallout] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!ownerId) return;
      
      setIsLoading(true);
      try {
        const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
        const currentYear = new Date().getFullYear();
        const currentQuarter = getCurrentQuarter();
        const quarterlyData = {};

        for (let i = 0; i < 4; i++) {
          let year = currentYear;
          let quarterIndex = quarters.indexOf(currentQuarter) - i;
          if (quarterIndex < 0) {
            year--;
            quarterIndex += 4;
          }
          const quarter = quarters[quarterIndex];
          const fetchedMetrics = await fetchMetricsByOwnerAndPeriod(ownerId, year, quarter);
          if (fetchedMetrics.length > 0) {
            quarterlyData[`${year} ${quarter}`] = fetchedMetrics[0];
          }
        }

        const aggregateData = await fetchAggregateMetrics(selectedStage);

        setMetricsData(prevState => ({
          ...prevState,
          quarterlyData: quarterlyData,
          aggregateData: aggregateData,
        }));

      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [ownerId, setMetricsData, selectedStage, selectedMetric]);

  const getMetricOptions = () => {
    if (!metricsData.quarterlyData) return [];

    const allKeys = Object.keys(metricsData.quarterlyData).reduce((keys, quarter) => {
      const quarterKeys = Object.keys(metricsData.quarterlyData[quarter] || {});
      return [...new Set([...keys, ...quarterKeys])];
    }, []);

    const filteredKeys = allKeys.filter(key => 
      !['id', 'createdAt', 'owner', 'time_period', 'year', 'updatedAt'].includes(key)
    );

    const mappedOptions = filteredKeys.map(key => {
      const prettyName = Object.keys(columnMapping).find(k => columnMapping[k] === key) || key;
      const metricStats = stats.find(stat => stat.name === prettyName) || {};
      const label = metricStats.name || prettyName.charAt(0).toUpperCase() + prettyName.slice(1);
      const unit = metricStats.unit || '';
      return {
        id: key,
        name: label,
        label: label,
        unit: unit,
        icon: metricStats.icon
      };
    });

    return mappedOptions.sort((a, b) => a.name.localeCompare(b.name));
  };

  const metricOptions = getMetricOptions();
  const selectedMetricOption = metricOptions.find(option => option.id === selectedMetric);

  const peopleMetrics = metricOptions.filter(metric => 
    stats.find(stat => stat.name === metric.name && stat.metricType === 'People')
  );
  const talentMetrics = metricOptions.filter(metric => 
    stats.find(stat => stat.name === metric.name && stat.metricType === 'Talent')
  );

  const stageOptions = ['All Stages', 'Seed', 'Series A', 'Series B', 'Series C+'];

  const LoadingOverlay = () => (
    <div className="absolute inset-x-0 bottom-0 h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
      <div className="text-center transform -translate-y-1/4">
        <ArrowPathIcon className="h-12 w-12 text-indigo-500 animate-spin mx-auto" />
        <p className="mt-2 text-sm font-medium text-gray-700">Loading data...</p>
      </div>
    </div>
  );

  const handleMetricChange = (metricId) => {
    setIsLoading(true);
    setSelectedMetric(metricId);
  };

  const handleStageChange = (stage, close) => {
    setIsLoading(true);
    setSelectedStage(stage);
    close(); // This will close the menu
  };

  const handleCalloutToggle = useCallback(() => {
    setShowCallout(prev => !prev);
  }, []);

  const renderChartForDownload = useCallback(() => {
    return (
      <MetricTrendChart 
        metricId={selectedMetric} 
        yAxisLabel={metricOptions.find(option => option.id === selectedMetric)?.label}
        yAxisUnit={metricOptions.find(option => option.id === selectedMetric)?.unit}
        selectedStage={selectedStage}
        showUserValue={true}
        aggregateData={metricsData.aggregateData}
        showLegendButtons={false}
        showCallout={showCallout}
        onCalloutToggle={handleCalloutToggle}
      />
    );
  }, [selectedMetric, metricOptions, selectedStage, metricsData.aggregateData, showCallout, handleCalloutToggle]);

  const calculatePercentiles = useMemo(() => {
    if (!metricsData.quarterlyData || !metricsData.aggregateData) {
      return {};
    }

    const percentiles = {};
    const bins = {};

    Object.entries(metricsData.aggregateData).forEach(([quarterKey, metrics]) => {
      percentiles[quarterKey] = {};
      bins[quarterKey] = {};

      Object.entries(metrics).forEach(([metricId, values]) => {
        if (values.length > 0) {
          values.sort((a, b) => a - b);
          const len = values.length;

          // Calculate percentile for user's value
          const userValue = metricsData.quarterlyData[quarterKey][metricId];
          const userPercentile = values.findIndex(v => v >= userValue) / len * 100;
          percentiles[quarterKey][metricId] = userPercentile;

          // Calculate bins for histogram
          const binCount = 5; // You can adjust this
          const binSize = (values[len - 1] - values[0]) / binCount;
          bins[quarterKey][metricId] = Array(binCount).fill(0);
          values.forEach(value => {
            const binIndex = Math.min(Math.floor((value - values[0]) / binSize), binCount - 1);
            bins[quarterKey][metricId][binIndex]++;
          });
        }
      });
    });

    // console.log("Calculated percentiles:", percentiles);
    // console.log("Calculated bins:", bins);
    return { percentiles, bins };
  }, [metricsData.quarterlyData, metricsData.aggregateData]);

  if (error) {
    return <div>Error loading metrics data: {error.message}</div>;
  }

  if (!metricsData || !metricsData.quarterlyData || Object.keys(metricsData.quarterlyData).length === 0) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
        <p className="text-gray-500">No metrics data available. Please check your data source or try again later.</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-visible sm:rounded-lg relative">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mr-6">Trend Analysis</h3>
          <NewFeatureFlag tooltipText="💡 This is a beta feature and some combinations of metric and stage may not have enough data to give a comprehensive time series. We are working hard to onboard more startups and scaleups to improve this service for you." />
        </div>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">View trends for your metrics over time</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="py-4 sm:py-5 sm:px-3">
          <div className="flex items-center space-x-4">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 group">
                  {selectedMetricOption ? (
                    <>
                      {selectedMetricOption.icon && (
                        <selectedMetricOption.icon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
                      )}
                      {selectedMetricOption.name}
                    </>
                  ) : (
                    'Select a metric'
                  )}
                  <ChevronDownIcon aria-hidden="true" className="ml-2 -mr-1 h-5 w-5 text-gray-400 group-hover:text-indigo-600" />
                </Menu.Button>
              </div>

              <Menu.Items className="absolute right-0 z-10 mt-2 min-w-[15rem] max-w-full max-h-60 overflow-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform -translate-y-full sm:translate-y-0">
                <div className="py-1">
                  <div className="px-4 py-2 text-xs font-semibold text-gray-500">People Metrics</div>
                  {peopleMetrics.map((metric) => (
                    <Menu.Item key={metric.id}>
                      {({ active }) => (
                        <div
                          onClick={() => handleMetricChange(metric.id)}
                          className={`group flex items-center px-4 py-2 text-sm w-full text-left cursor-pointer truncate ${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                          }`}
                        >
                          {metric.icon && (
                            <metric.icon className={`mr-3 h-5 w-5 ${
                              active ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
                            }`} aria-hidden="true" />
                          )}
                          <span className="truncate">{metric.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
                <div className="py-1">
                  <div className="px-4 py-2 text-xs font-semibold text-gray-500">Talent Metrics</div>
                  {talentMetrics.map((metric) => (
                    <Menu.Item key={metric.id}>
                      {({ active }) => (
                        <div
                          onClick={() => handleMetricChange(metric.id)}
                          className={`group flex items-center px-4 py-2 text-sm w-full text-left cursor-pointer truncate ${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                          }`}
                        >
                          {metric.icon && (
                            <metric.icon className={`mr-3 h-5 w-5 ${
                              active ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
                            }`} aria-hidden="true" />
                          )}
                          <span className="truncate">{metric.name}</span>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>

            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                  {selectedStage}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {stageOptions.map((stage) => (
                    <Menu.Item key={stage}>
                      {({ active, close }) => (
                        <a
                          href="#"
                          className={`${
                            active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600'
                          } block px-4 py-2 text-sm`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleStageChange(stage, close);
                          }}
                        >
                          {stage}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>
        <div className="py-4 sm:py-5 sm:px-6 relative h-[400px]">
          {isLoading && <LoadingOverlay />}
          <div ref={chartRef} className="relative">
            <MetricTrendChart 
              metricId={selectedMetric} 
              yAxisLabel={metricOptions.find(option => option.id === selectedMetric)?.label}
              yAxisUnit={metricOptions.find(option => option.id === selectedMetric)?.unit}
              selectedStage={selectedStage}
              showUserValue={true}
              aggregateData={metricsData.aggregateData}
              percentiles={calculatePercentiles.percentiles}
              bins={calculatePercentiles.bins}
              showCallout={showCallout}
              onCalloutToggle={handleCalloutToggle}
            />
            <button
              onClick={() => setIsDownloadModalOpen(true)}
              className="absolute top-0 right-2 p-1"
            >
              <ArrowDownTrayIcon className="h-5 w-5 text-gray-600 hover:text-indigo-500" />
            </button>
          </div>
        </div>
      </div>
      {isDownloadModalOpen && (
        <DownloadChartModal
          isOpen={isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
          title="Download Trend Analysis Chart"
          message="Download your trend analysis chart as an image."
          renderChart={renderChartForDownload}
          fileName="trend_analysis_chart.png"
        />
      )}
    </div>
  );
};

export default TrendAnalysis;