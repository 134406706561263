import React, { useState } from 'react';
import { stats } from '../../util/MetricsConfig'; // Adjust the import path as needed
import { CheckCircleIcon } from "@heroicons/react/20/solid"; // Import icons as needed

const MetricsSelectionForm = ({ onNextStep, onMetricsSelect }) => {
    const [selectedMetrics, setSelectedMetrics] = useState([]);

    const toggleMetricSelection = (id) => {
        setSelectedMetrics((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((metricId) => metricId !== id);
            } else if (prevSelected.length < 3) {
                return [...prevSelected, id];
            }
            return prevSelected;
        });
    };

    const handleNext = () => {
        onMetricsSelect(selectedMetrics);
        onNextStep();
    };

    // Define inline styles for the gradient background
    const gradientBackground = {
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(169, 168, 243, 0.5))',
    };

    // Calculate the progress as a percentage
    const progressBarWidth = `${(selectedMetrics.length / 3) * 100}%`;

    // Determine the prompt text based on the number of selected metrics
    const textState = selectedMetrics.length === 3
        ? "Great! You've selected 3 metrics."
        : `Please select ${3 - selectedMetrics.length} more.`;

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-center text-lg font-semibold text-gray-700">🚀 Choose 3 metrics you want to unlock benchmarks for.</h1>
            <div className="py-4 rounded-t-lg">
                <div className="container mx-auto text-white text-center relative">
                    <div className="h-6 bg-[#cbd5e1] rounded-full mb-2">
                        <div
                            className="h-full rounded-full bg-gradient-to-r from-pink-300 to-indigo-500 transition-all duration-500"
                            style={{ width: progressBarWidth }}
                        ></div>
                    </div>
                    <div className="text-sm text-gray-600">
                        <p className="mb-1">{textState}</p>
                        <p className="mb-1 font-semibold">{`${selectedMetrics.length}/3 metrics selected`}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap gap-4 justify-center">
                {stats.map((metric) => (
                    <div key={metric.id} style={gradientBackground} className={`p-4 hover:cursor-pointer max-w-m bg-white rounded-lg border shadow-md ${selectedMetrics.includes(metric.id) ? 'border-indigo-500' : 'border-gray-200'}`} onClick={() => toggleMetricSelection(metric.id)}>
                        <div className="flex items-center mb-2 space-x-2"> {/* Adjusted for spacing and alignment */}
                            {/* Render the icon to the left of the title */}
                            {metric.icon && React.createElement(metric.icon, { className: "h-4 w-4 text-indigo-500" })}

                            <h5 className="text-xl font-bold tracking-tight text-gray-900">{metric.name}</h5>

                            {/* Check icon to indicate selection */}
                            {selectedMetrics.includes(metric.id) && <CheckCircleIcon className="h-6 w-6 text-indigo-500" />}
                        </div>
                        {metric.metricType === 'People' ? (
                      <span className="inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-xs font-small text-blue-700">
                        {metric.metricType}
                      </span>
                    ) : metric.metricType === 'Talent' ? (
                      <span className="inline-flex items-center rounded-md bg-purple-100 px-2.5 py-0.5 text-xs font-small text-purple-700">
                        {metric.metricType}
                      </span>
                    ) : null}
                    </div>
                ))}

                <button
                    onClick={handleNext}
                    className="submit-button mt-4"
                    disabled={selectedMetrics.length !== 3}
                >
                    🚀 Next
                </button>
            </div>
        </div>
    );
};

export default MetricsSelectionForm;
