import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Who is this for?",
    answer:
      "Peoplemetrics.fyi is for People and Talent Leaders who want to get a startup specific benchmark of their core people and talent metrics. Most of our customers are Series A-C People or Talent Leaders who want to improve their internal story-telling credibility and increase their team's data intelligence.",
  },
  {
    question: "What metrics are currently available to benchmark?",
    answer:
      "Currently there are 19 x metrics available to benchmark. These are: ENPS, Engagement Score, Regrettable Attrition, Time To Offer, Cost Of Hire, Annual Revenue Per Employee, Promotion Percentage, Absenteeism Rate, Average Tenure, Offer Acceptance Rate, Probation Pass Rate, People Team Percentage, Org Transparency, Early Attrition, IC : Manager Ratio, Time To Fill, Non-Regrettable Attrition, and General & Administrative Burn Rate",
  },
  {
    question: "What regions are available to benchmark?",
    answer:
      "Currently you can get a specific benchmark for either Global, Europe or U.S and Canada as this represents the majority of organisations contributing to the data set. We are working on adding more regions.",
  },
  {
    question: "What is the cost?",
    answer:
      "Peoplemetrics.fyi is available for £1500 + 20% VAT per year. We are currently offering a 60% discount on year 1 for early adopters which equates to £500 + 20% VAT.",
  },
  {
    question: "Can I pay monthly?",
    answer:
      "Currently peoplemetrics.fyi is only available on an annual subscription.",
  },
  
  {
    question: "I'm a VC or investor and I want to get access to the benchmarks, how can I do that?",
    answer:
      "Peoplemetrics.fyi is designed for in-role People and Talent Leaders. However, we can produce bespoke benchmark reports for VCs, investors and journalists. Please email john@peoplemetrics.fyi if you are interested in this.",
  },

  {
    question: "How many startups are contributing to the benchmark?",
    answer:
     "There are currently 578 startups contributing to the benchmark. 80% of organisations are between Series A and Series C, with the remaining 20% being Series C+ or Seed and Pre-seed. ",
  },
  {
    question: "Why don't you benchmark compensation?",
    answer:
     "Compensation benchmarking is a complex area and a distinct product in its own right. We may do something around a market comparison on the percentile startups pay at but we don't intend to go deep here. Recommend checking out figures.hr if this is something you are interested in. Great platform.",
  },

]

export default function FAQSection() {
  return (
    <div className="bg-transparent">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
