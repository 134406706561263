import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';
import { columnMapping, stats } from '../../util/MetricsConfig';

const HistogramChart = ({ userMetricsData, metricName, metricsData, onMinMaxChange, onUserPercentileChange, onPercentileRangeChange }) => {
  const [userScore, setUserScore] = useState(getInitialUserScore());
  const [extractedMetricData, setExtractedMetricData] = useState([]);
  const [bins, setBins] = useState([]);
  const d3Container = useRef(null);
  const svgRef = useRef(null);

  function getInitialUserScore() {
    const storedUserScore = localStorage.getItem("userScore");
    return storedUserScore ? parseFloat(storedUserScore) : null;
  }

  // Function to calculate bins for histogram
  function calculateBins(data, numBins) {
    const [minValue, maxValue] = d3.extent(data);
    const binWidth = (maxValue - minValue) / numBins;
    const thresholds = d3.range(minValue, maxValue + binWidth, binWidth);
    // Adjust the last threshold to ensure it includes the max value
    if (thresholds[thresholds.length - 1] < maxValue) {
      thresholds[thresholds.length - 1] = maxValue;
    }
    return d3.histogram().thresholds(thresholds)(data);
  }

  // Calculate percentage ranges for status pill
  function calculatePercentileRange(data, startPercentile, endPercentile) {
    if (!Array.isArray(data) || data.length === 0) {
      return [null, null]; // Return null values if the data is not valid
    }

    const sortedData = [...data].sort((a, b) => a - b); // Create a sorted copy
    const startIndex = Math.floor((startPercentile / 100) * sortedData.length);
    const endIndex = Math.floor((endPercentile / 100) * sortedData.length);

    // Ensure that the indices are within bounds
    const startValue = sortedData[Math.max(0, startIndex)];
    const endValue = sortedData[Math.min(sortedData.length - 1, endIndex)];

    return [startValue, endValue];
  }



  useEffect(() => {
    if (metricsData && metricName && userMetricsData && userMetricsData[metricName]) {
      const newMetricData = metricsData
        .map(item => item[metricName])
        .filter(value => value !== null && value !== undefined); // Filter out null or undefined values

      setExtractedMetricData(newMetricData);

      const newScore = userMetricsData[metricName];
      setUserScore(newScore);
      localStorage.setItem("userScore", newScore.toString());
      setBins(calculateBins(newMetricData, 10));

      // Calculate percentages ranges for status pill
      const metricNamePretty = Object.keys(columnMapping).find(key => columnMapping[key] === metricName);
      const metric = stats.find(stat => stat.name === metricNamePretty);
      const metricUnit = metric.unit;
      const metricUnitLocation = metric.unitLocation;
      const displayMetricUnitFront = metricUnitLocation === "front";

      // Calculate percentage ranges for status pill
      const lowPercentileRange = calculatePercentileRange(newMetricData, 0, 10);
      const highPercentileRange = calculatePercentileRange(newMetricData, 90, 100);
      const midPercentileValue = calculatePercentileRange(newMetricData, 50, 50)[0]; // Get the value at 50th percentile

      // Construct the range strings based on metric.unit and displayMetricUnitFront
      const formatRange = (range) => {
        if (displayMetricUnitFront) {
          return ` ${metricUnit} ${range}`;
        } else {
          return ` ${range} ${metricUnit}`;
        }
      };
      //pass to parent
      onPercentileRangeChange({
        low: formatRange(`${lowPercentileRange[0]} and ${lowPercentileRange[1]}`),
        high: formatRange(`${highPercentileRange[0]} and ${highPercentileRange[1]}`),
        mid: formatRange(midPercentileValue)
      });
    }
  }, [metricsData, metricName, userMetricsData]);


  const drawHistogram = useCallback(() => {
    if (!bins.length || !svgRef.current) return;


    const container = d3Container.current;
    const containerWidth = container.getBoundingClientRect().width;
    const margin = { top: 100, right: 100, bottom: 80, left: 40 };
    const width = containerWidth - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const xDomainStart = Math.min(...bins.map(bin => bin.x0));
    const xDomainEnd = Math.max(...bins.map(bin => bin.x1));

    const xScale = d3.scaleLinear()
      .domain([xDomainStart, xDomainEnd])
      .range([0, width]);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(bins, d => d.length)])
      .range([height, 0]);

    const barWidth = width / bins.length;

    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale).tickFormat(d3.format("d"));

    //prettify metric name from db_col_name and get units
    const metricNamePretty = Object.keys(columnMapping).find(key => columnMapping[key] === metricName);
    const metric = stats.find(stat => stat.name === metricNamePretty);
    const metricUnit = metric.unit;
    const metricUnitLocation = metric.unitLocation;
    const displayMetricUnitFront = metricUnitLocation === "front";

    // After setting extractedMetricData in the useEffect:
    const sortedExtractedMetricData = [...extractedMetricData].sort((a, b) => a - b);

    // Calculate user percentile using the sorted dataset
    const rawPercentile = d3.bisectLeft(sortedExtractedMetricData, userScore) / sortedExtractedMetricData.length;
    const userPercentile = Math.round(rawPercentile * 100);
    onUserPercentileChange(userPercentile);

    // Function to get the appropriate suffix for a number
    function getNumberSuffix(number) {
      if (number >= 11 && number <= 13) {
        return 'th'; // 11th, 12th, and 13th have 'th' suffix
      }
      const lastDigit = number % 10;
      switch (lastDigit) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
    // Get the appropriate suffix for the percentile
    const percentileSuffix = getNumberSuffix(userPercentile);

    //calculate min and max values:
    const maxValue = d3.max(extractedMetricData);
    const minValue = d3.min(extractedMetricData);
    // lift state up to parent so other child components of /ChartModal can access it
    onMinMaxChange(minValue, maxValue);


    d3.select(container).selectAll("*").remove();

    const svg = d3.select(container)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    svg.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis);

    svg.append("g")
      .call(yAxis);

    //Chart Title

    svg.append("text")
      .attr("x", 0) // Start from the very left of the SVG
      .attr("y", -80)
      .attr("text-anchor", "start") // Start text from the x position
      .attr("fill", "black")
      .style("font-size", "16px")
      .html(`Your ${metricNamePretty} is: <tspan font-weight="bold">${displayMetricUnitFront ? metricUnit + " " + userScore : userScore + " " + metricUnit}</tspan>`);

    svg.append("text")
      .attr("x", 0) // Start from the very left of the SVG
      .attr("y", -50)
      .attr("text-anchor", "start") // Start text from the x position
      .attr("fill", "black")
      .style("font-size", "16px")
      .html(`This is in the <tspan font-weight="bold">${userPercentile}${percentileSuffix}</tspan> percentile.`);


    // Normal Bar Gradient definition
    const gradient = svg.append("defs")
      .append("linearGradient")
      .attr("id", "bar-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "rgba(102, 126, 234, 0.6)"); // Start color

    gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "rgba(142, 55, 215, 0.6)"); // End color

    //User Gradient
    // Define the user-specific gradient
    const userGradient = svg.append("defs")
      .append("linearGradient")
      .attr("id", "user-bar-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    userGradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#CA2A95") // Start color (orange)
      .attr("stop-opacity", "0.6"); // Opacity value

    userGradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#305CEB") // End color (pink)
      .attr("stop-opacity", "0.6"); // Opacity value


    // Function to determine the fill color of each bar
    const getBarFill = (bin) => {
      if (userScore !== null && userScore >= bin.x0 && userScore < bin.x1) {
        return "url(#user-bar-gradient)"; // Apply the user-specific gradient
      }
      return "url(#bar-gradient)"; // Use the original gradient for other bars
    };

    /////////////////////
    //////////
    ///////
    ////
    //
    // Bars
    svg.selectAll(".bar")
      .data(bins)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", d => xScale(d.x0)) // Position each bar without an offset
      .attr("width", barWidth)
      .attr("y", height) // Start at the bottom
      .attr("height", 0) // Start with height 0
      .attr("fill", d => getBarFill(d))
      .style("stroke", "#2e2e2e") // Apply the bar-gradient as a border
      .style("stroke-width", "0.5px")
      .attr("rx", 7) // Round top-left corner
      .attr("ry", 7) // Round top-right corner
      .transition() // Apply the transition
      .duration(750) // Duration of the animation
      .delay((_, i) => i * 110) // Stagger the animation
      .attr("y", d => yScale(d.length)) // Animate to the final y position
      .attr("height", d => height - yScale(d.length));



    // Draw the user's score and percentile
    if (userScore !== null) {
      let userBinIndex = bins.findIndex(bin => userScore >= bin.x0 && userScore < bin.x1);

      // Adjust for userScore being the max value
      const maxValue = d3.max(extractedMetricData);
      if (userBinIndex === -1 && userScore === maxValue) {
        userBinIndex = bins.length - 1;
      }

      if (userBinIndex !== -1) {
        const userBin = bins[userBinIndex];
        const userX = xScale(userBin.x0) + barWidth / 2;
        const userY = yScale(userBin.length);

        // Calculate the starting and ending y-coordinates for the vertical line
        const lineStartY = userY;
        const lineEndY = height; // Extend the line to the bottom of the chart

        // Draw the callout box
        svg.append("rect")
          .attr("x", userX - 50)
          .attr("y", userY - 30)
          .attr("width", 100)
          .attr("height", 24)
          .style("stroke", "url(#bar-gradient)")
          .style("stroke-width", "2px")
          .attr("rx", 8)
          .attr("ry", 8)
          .attr("fill", "#2e2e2e")
          .style("pointer-events", "all");

        // Add text inside the callout box
        svg.append("text")
          .attr("x", userX)
          .attr("y", userY - 14)
          .attr("text-anchor", "middle")
          .attr("fill", "white")
          .style("font-size", "12px")
          .style("font-family", "Arial, sans-serif")
          .text(`${userPercentile}${percentileSuffix} percentile`);

        // Draw the vertical line
        svg.append("line")
          .attr("x1", userX)
          .attr("y1", lineStartY)
          .attr("x2", userX)
          .attr("y2", lineEndY)
          .style("stroke", "black")
          .style("stroke-dasharray", "4");

        // Add a circle above the bar
        svg.append("circle")
          .attr("cx", userX)
          .attr("cy", userY)
          .attr("r", 3)
          .style("fill", "#2e2e2e");
      }
    }

    // Fixed y-coordinate for the x-axis label (30px below the x-axis)
    const xAxisLabelY = height + 40;

    // Append x-axis label
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", xAxisLabelY) // Position it 30px below the x-axis
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .style("font-size", "11px")
      .text(`${metricNamePretty} (${metricUnit})`)


  }, [bins, userScore, extractedMetricData]);

  useEffect(() => {
    drawHistogram();
  }, [drawHistogram]);

  useEffect(() => {
    const handleResize = () => {
      drawHistogram();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawHistogram]);

  return (
    <div ref={d3Container} style={{ width: "100%" }}>
      <svg ref={svgRef} style={{ width: "100%", height: "400px" }}></svg>
    </div>
  );
};

export default HistogramChart;


