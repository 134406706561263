import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from '../../util/router';
import { useMetrics } from '../../util/UserMetricsContext';
import ResultsDetail from "./ResultsDetail";
import HistogramChart from "./HistogramChart";
import WarningModal from "../modals/WarningModal";
import { useAuth } from '../../util/auth';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import DownloadChartModal from '../modals/DownloadChartModal';

const MetricResults = () => {
  const { metricId } = useParams();
  const { metricsData, setMetricsData } = useMetrics();
  const auth = useAuth();
  const ownerId = auth.user?.uid;

  const allData = metricsData.allMetricsData;
  const userMetricsData = metricsData.userMetricsData;
  const selectedMetric = metricsData.selectedMetric;
  const metricSampleSize = selectedMetric ? selectedMetric.sampleSize : null;
  const title = selectedMetric ? selectedMetric.name : null;
  const totalSampleSize = metricsData.orgCount;
  const unit = selectedMetric?.unit;
  const colName = selectedMetric?.db_col;

  const [minMax, setMinMax] = useState({ minValue: null, maxValue: null });
  const [userPercentile, setUserPercentile] = useState(null);
  const [percentileRanges, setPercentileRanges] = useState({ low: '', mid: '', high: '' });
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const modalRef = useRef(null);
  const chartRef = useRef(null);

  const handleMinMaxChange = useCallback((min, max) => {
    setMinMax({ minValue: min, maxValue: max });
  }, []);

  const handleUserPercentileChange = useCallback((newPercentile) => {
    setUserPercentile(newPercentile);
  }, []);

  const handlePercentileRangeChange = useCallback((ranges) => {
    setPercentileRanges(ranges);
  }, []);

  const renderChartForDownload = useCallback(() => {
    return (
      <HistogramChart
        userMetricsData={userMetricsData}
        metricName={colName}
        metricsData={allData}
        onMinMaxChange={handleMinMaxChange}
        onUserPercentileChange={handleUserPercentileChange}
        onPercentileRangeChange={handlePercentileRangeChange}
      />
    );
  }, [userMetricsData, colName, allData, handleMinMaxChange, handleUserPercentileChange, handlePercentileRangeChange]);

  useEffect(() => {
    const storedMetric = localStorage.getItem('selectedMetric');
    if (storedMetric) {
      const parsedMetric = JSON.parse(storedMetric);
      setMetricsData(prevMetricsData => ({
        ...prevMetricsData,
        selectedMetric: parsedMetric,
      }));
    }
  }, [setMetricsData]);

  useEffect(() => {
    if (metricSampleSize === undefined || metricSampleSize <= 10) {
      setShowWarningModal(true);
    } else {
      setShowWarningModal(false);
    }
  }, [metricSampleSize]);

  return (
    <div ref={modalRef}>
      <DownloadChartModal
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
        title="Export Chart"
        message=""
        renderChart={renderChartForDownload}
        fileName={`${title}_histogram.png`}
      />
      {userMetricsData ? (
        <div className="bg-indigo-50 rounded-lg relative h-full overflow-y-auto" style={metricSampleSize === undefined || metricSampleSize <= 10 ? { filter: 'blur(2px)' } : {}}>
          <div className="p-6">
            <div className="flex items-center">
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                {title} Benchmark Results
              </h3>
              <span className="bg-indigo-100 text-indigo-600 text-xs ml-4 mb-3 font-medium me-2 px-2.5 py-0.5 rounded">
                New Feature
              </span>
            </div>
            <div className="mt-8">
              {selectedMetric && (
                <div>
                  <div>
                    <div className="relative rounded-lg bg-white border border-slate-300 p-4 mb-6">
                      <div className="absolute top-0 right-0 m-4">
                        <button
                          onClick={() => setIsDownloadModalOpen(true)}
                          className="text-slate-600 hover:text-indigo-700 focus:outline-none hover:cursor-pointer"
                        >
                          <ArrowDownTrayIcon className="w-5 h-5" />
                        </button>
                      </div>
                      <div className="p-4 m-4" ref={chartRef}>
                        <HistogramChart
                          userMetricsData={userMetricsData}
                          metricName={colName}
                          metricsData={allData}
                          onMinMaxChange={handleMinMaxChange}
                          onUserPercentileChange={handleUserPercentileChange}
                          onPercentileRangeChange={handlePercentileRangeChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative rounded-lg border border-slate-300 bg-white p-8 ">
                    <ResultsDetail
                      userMetricsData={userMetricsData}
                      metricName={colName}
                      allMetricsData={allData}
                      minValue={minMax.minValue}
                      maxValue={minMax.maxValue}
                      userPercentile={userPercentile}
                      percentileRanges={percentileRanges}
                      metricSampleSize={metricSampleSize}
                      selectedMetric={selectedMetric}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}
      {showWarningModal && (
        <WarningModal
          isOpen={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          title="Benchmark Brewing"
          message="Not enough folks have contributed to this to provide a meaningful benchmark yet. Watch this space!"
        />
      )}
    </div>
  );
}

export default MetricResults;