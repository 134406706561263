import React from "react";
import SectionHeader from "../general/SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import { SlimLayout } from "../general/SlimLayout";
import { FaSlack } from 'react-icons/fa';

function AuthSection(props) {
  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: "🚀 Get yourself an account!",
      // Button text
      buttonAction: "Sign up",
      // Footer text and links
      showFooter: true,
      signinText: "Already have an account?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: "/legal/terms-of-service",
      privacyPolicyPath: "/legal/privacy-policy",
    },
    signin: {
      title: "🎉 Welcome back!",
      buttonAction: "Sign in",
      showFooter: true,
      // signupAction: "Create an account",
      // signupPath: "/auth/signup",
      forgotPassAction: "Forgot Password?",
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: "🚀 Get a new password",
      buttonAction: "Reset password",
      showFooter: true,
      signinText: "Remember it after all?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
    },
    changepass: {
      title: "🚀 Choose a new password",
      buttonAction: "Change password",
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <SlimLayout
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container max-w-md">
        <SectionHeader
          title={options.title}
          subtitle=""
          strapline=""
          className="text-center"
        />
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

    

        {options.showFooter && <AuthFooter type={type} {...options} />}

        {/* Add the "Need Help? Chat To John" button here */}
        <div className="mt-12 text-center">
          <a
            href="https://join.slack.com/t/peoplemetricsgroup/shared_invite/zt-2oamcggs2-9iB9cE4t13nrUhEz9lROSg"
            className="inline-flex items-center px-3 py-2 text-xs font-medium text-indigo-400 bg-indigo-50 border border-indigo-200 rounded-md shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-indigo-600 transition-all duration-200 ease-in-out transform hover:scale-105 active:scale-95"
          >
            <FaSlack className="w-4 h-4 mr-2 -ml-1 text-indigo-400" aria-hidden="true" />
            Need Help? Chat To John
          </a>
        </div>
      </div>
    </SlimLayout>
  );
}

export default AuthSection;