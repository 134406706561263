// FiltersContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { headcountOptions, options, regionOptions } from './MetricsConfig';
import { loadFilterConfig, saveFilterConfig } from './db'; // Ensure this path is correct

const FiltersContext = createContext();

export const useFilters = () => useContext(FiltersContext);

export const FiltersProvider = ({ children, userId }) => {
  const [selectedHeadcount, setSelectedHeadcount] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("Current Quarter");
  const [orgCount, setOrgCount] = useState(0);

  const defaultConfig = {
    selectedHeadcount: headcountOptions.filter(option => option.label === 'All'),
    selectedStage: options.filter(option => option.label === 'All'),
    selectedLocation: regionOptions.filter(option => option.label === 'All'),
    selectedTimePeriod: "Current Quarter"
  };

  useEffect(() => {
    const fetchFilterConfig = async () => {
      if (!userId) return;

      try {
        const config = await loadFilterConfig(userId) || defaultConfig;

        setSelectedHeadcount(config.selectedHeadcount || []);
        setSelectedStage(config.selectedStage || []);
        setSelectedLocation(config.selectedLocation || []);
        setSelectedTimePeriod(config.selectedTimePeriod || "Current Quarter");
      } catch (error) {
        console.error('Error loading filter configuration:', error);
        // Apply default config if there's an error
        setSelectedHeadcount(defaultConfig.selectedHeadcount);
        setSelectedStage(defaultConfig.selectedStage);
        setSelectedLocation(defaultConfig.selectedLocation);
        setSelectedTimePeriod(defaultConfig.selectedTimePeriod);
      }
    };

    fetchFilterConfig();
  }, [userId]);

  const saveConfig = async (newConfig) => {
    try {
      await saveFilterConfig(userId, newConfig);
    } catch (error) {
      console.error('Error saving filter configuration:', error);
    }
  };

  const handleHeadcountChange = (selectedOptions) => {
    const newConfig = {
      selectedHeadcount: selectedOptions || [],
      selectedStage,
      selectedLocation,
      selectedTimePeriod,
    };
    setSelectedHeadcount(newConfig.selectedHeadcount);
    saveConfig(newConfig);
  };

  const handleStageChange = (selectedOptions) => {
    const newConfig = {
      selectedHeadcount,
      selectedStage: selectedOptions || [],
      selectedLocation,
      selectedTimePeriod,
    };
    setSelectedStage(newConfig.selectedStage);
    saveConfig(newConfig);
  };

  const handleLocationChange = (selectedOptions) => {
    const newConfig = {
      selectedHeadcount,
      selectedStage,
      selectedLocation: selectedOptions || [],
      selectedTimePeriod,
    };
    setSelectedLocation(newConfig.selectedLocation);
    saveConfig(newConfig);
  };

  const handleTimePeriodChange = (selectedOption) => {
    const newConfig = {
      selectedHeadcount,
      selectedStage,
      selectedLocation,
      selectedTimePeriod: selectedOption?.value || "Current Quarter",
    };
    setSelectedTimePeriod(newConfig.selectedTimePeriod);
    saveConfig(newConfig);
  };

  const updateOrgCount = (newCount) => {
    setOrgCount(newCount);
  };

  return (
    <FiltersContext.Provider value={{
      selectedHeadcount,
      selectedStage,
      selectedLocation,
      selectedTimePeriod,
      handleLocationChange,
      handleTimePeriodChange,
      orgCount,
      handleHeadcountChange,
      handleStageChange,
      updateOrgCount,
      setSelectedHeadcount, // Add setter functions here
      setSelectedStage,
      setSelectedLocation,
      setSelectedTimePeriod,
    }}>
      {children}
    </FiltersContext.Provider>
  );
};
