import { Img } from 'react-image';
import backgroundImage from '../../images/background-auth.jpg';
import { Testimonial } from '../auth/Testimonial';

export function SlimLayout({ children }) {
  return (
    <>
      <div className="relative flex min-h-screen justify-center md:px-12 lg:px-0">
        {/* Left side containing the Auth section */}
        <div className="z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {children}
          </main>
        </div>
        {/* Right side containing the background image */}
        <div className="hidden lg:flex lg:flex-1 relative">
          <Img
            className="absolute inset-0 h-full w-full object-cover"
            src={backgroundImage}
            alt="gradient background"
            unoptimized={true}
          />
          
        </div>
        {/* Testimonial positioned in the center of the right half of the page */}
        <div className="absolute inset-y-0 right-0 flex items-center justify-center hidden lg:flex lg:w-3/5 xl:w-2/3">
          {/* Use responsive width classes like lg:pr-1/6 to adjust proportionately */}
          <Testimonial />
        </div>
      </div>
    </>
  );
}
