import React, { useState, useEffect } from 'react';
import { useAuth } from '../../util/auth';
import '../../styles/OnboardingForm.css';
import { industryOptions, locationOptions, headcountOptions, stageOptions } from './FormOptions';
import { useOrganisationsByOwner, createOrganisation, updateOrganisation } from '../../util/db';

export default function CompanyInfoForm({ onNextStep }) {
  const auth = useAuth();
  const ownerId = auth.user?.uid;

  const [formData, setFormData] = useState({
    website_url: '',
    headcount: '',
    stage: '',
    industry: '',
    other_industry: '',
    location: '🌎 Remote', // Default value
  });

  const [existingOrganisation, setExistingOrganisation] = useState(null); // Declare existingOrganisation state

  const { data: organisations, error: organisationsError } = useOrganisationsByOwner(ownerId);

  useEffect(() => {
    if (organisations) {
      setExistingOrganisation(organisations[0]); // Set existingOrganisation state
      const userOrganisations = organisations[0];
      setFormData({
        website_url: userOrganisations?.website_url || '',
        headcount: userOrganisations?.headcount || '',
        stage: userOrganisations?.stage || '',
        industry: userOrganisations?.industry || '',
        other_industry: userOrganisations?.other_industry || '',
        location: userOrganisations?.location || '',
      });
    }
  }, [organisations]);

  const handleCardSelect = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOtherIndustryChange = (e) => {
    setFormData({ ...formData, otherIndustry: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (organisationsError) {
        console.error('Organisations Error:', organisationsError);
        throw organisationsError;
      }

      const organisationsData = {
        website_url: formData.website_url,
        headcount: formData.headcount,
        stage: formData.stage,
        industry: formData.industry,
        other_industry: formData.other_industry,
        location: formData.location,
      };



      if (existingOrganisation) {
        const response = await updateOrganisation(existingOrganisation.id, organisationsData);
        if (response && response.error) {
          throw new Error(response.error.message);
        }
      } else {
        const response = await createOrganisation({ owner: ownerId, ...organisationsData });
        if (response && response.error) {
          throw new Error(response.error.message);
        }
      }


      onNextStep();
    } catch (error) {
      console.error('Error updating organisation:', error.message);
      alert('Failed to update organisation data');
    }
  };




  return (
    <form onSubmit={handleSubmit} className="form-styling space-y-6">
      {/* Row Container for Inputs */}
      <div className="row-container">
        
        {/* Website URL Input */}
        <div className="input-group half-width">
          <label htmlFor="websiteUrl" className="block text-sm font-medium leading-6 text-gray-900">
            Company Website
          </label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
              <input
                type="text"
                name="website_url"
                id="website_url"
                value={formData.website_url}
                onChange={handleInputChange}
                required
                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="www.example.com"
              />
            </div>
          </div>



        </div>

        {/* Location Dropdown Select */}
       
      </div>
      <fieldset className="mt-6 bg-white">
        <legend className="block text-sm font-medium leading-6 text-gray-900">Location</legend>
        <div className="mt-2 -space-y-px rounded-md shadow-sm">
          <div>
            <label htmlFor="country" className="sr-only">
              Country
            </label>
            <select
              id="location"
              name="location"
              value={formData.location}
            onChange={handleInputChange}
              className="block flex-1 rounded-md border-0 bg-transparent py-1.5 pl-1 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
             {locationOptions.map((option, index) => (
              <option key={index} value={option.code}>
                {option.name}
              </option>
            ))}
            </select>
          </div>
          </div>
          </fieldset>


      {/* Industry Card Select */}
      <div className="input-group">
        <label className="block text-sm font-medium leading-6 text-gray-900">Industry</label>
        <div className="card-select">
          {industryOptions.map((option, index) => (
            <div
              key={index}
              className={`card-option ${formData.industry === option.name ? 'selected' : ''
                }`}
              onClick={() => handleCardSelect('industry', option.name)}
            >
              {option.icon}
              <span className="ml-2">{option.name}</span>
            </div>
          ))}
          <div
            className={`card-option ${formData.industry === 'Other' ? 'selected' : ''
              }`}
            onClick={() => handleCardSelect('industry', 'Other')}
          >
            <span>Other</span>
          </div>
          {formData.industry === 'Other' && (
            <input
              type="text"
              id="other_industry"
              placeholder="e.g Media"
              name="other_industry"
              value={formData.other_industry}
              onChange={handleInputChange}
              required
              className="form-input"
            />

          )}
        </div>
      </div>

      {/* Headcount Card Select */}
      <div className="input-group">
        <label className="block text-sm font-medium leading-6 text-gray-900">Headcount</label>
        <div className="card-select">
          {headcountOptions.map((option) => (
            <div
              key={option}
              className={`card-option ${formData.headcount === option ? 'selected' : ''
                }`}
              onClick={() => handleCardSelect('headcount', option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>

      {/* Stage Card Select */}
      <div className="input-group">
        <label className="block text-sm font-medium leading-6 text-gray-900">Stage</label>
        <div className="card-select">
          {stageOptions.map((option) => (
            <div
              key={option}
              className={`card-option ${formData.stage === option ? 'selected' : ''
                }`}
              onClick={() => handleCardSelect('stage', option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>

      <button type="submit" className="submit-button">
        Next
      </button>
    </form>
  );
}
