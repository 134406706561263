import React from "react";
import { StarIcon, ShieldCheckIcon, ExclamationCircleIcon, SunIcon, BeakerIcon, BoltIcon  } from '@heroicons/react/20/solid';

const invertedMetrics = [
  'Time To Offer', 'Cost Of Hire', 'Regrettable Attrition', 'Absenteeism Rate',
  'IC : Manager Ratio', 'Time To Fill', 'General & Administrative Burn Rate', 'Early Attrition'
];

const getStatusForNormalMetric = (percentile) => {
  if (percentile >= 90) {
    return { bgColor: "bg-indigo-100", textColor: "text-indigo-700", hoverBgColor: "hover:bg-indigo-100", hoverTextColor: "hover:text-indigo-700", statusText: "Top 10%", Icon: StarIcon };
  } else if (percentile >= 75) {
    return { bgColor: "bg-green-100", textColor: "text-green-700", hoverBgColor: "hover:bg-green-100", hoverTextColor: "hover:text-green-700", statusText: "Top 25%", Icon: ShieldCheckIcon };
  } else if (percentile >= 50) {
    return { bgColor: "bg-yellow-100", textColor: "text-yellow-800", hoverBgColor: "hover:bg-yellow-100", hoverTextColor: "hover:text-yellow-800", statusText: "Above median", Icon: SunIcon };
  } else if (percentile >= 25) {
    return { bgColor: "bg-orange-100", textColor: "text-orange-700", hoverBgColor: "hover:bg-orange-100", hoverTextColor: "hover:text-orange-700", statusText: "Bottom 25%", Icon: BeakerIcon };
  } else {
    return { bgColor: "bg-red-100", textColor: "text-red-700", hoverBgColor: "hover:bg-red-100", hoverTextColor: "hover:text-red-700", statusText: "Bottom 10%", Icon: BoltIcon };
  }
};

const getStatusForInvertedMetric = (percentile) => {
  if (percentile >= 90) {
    return { bgColor: "bg-red-100", textColor: "text-red-700", hoverBgColor: "hover:bg-red-100", hoverTextColor: "hover:text-red-700", statusText: "Bottom 10%", Icon: BoltIcon };
  } else if (percentile >= 75) {
    return { bgColor: "bg-orange-100", textColor: "text-orange-700", hoverBgColor: "hover:bg-orange-100", hoverTextColor: "hover:text-orange-700", statusText: "Bottom 25%", Icon: BeakerIcon };
  } else if (percentile >= 50) {
    return { bgColor: "bg-yellow-100", textColor: "text-yellow-800", hoverBgColor: "hover:bg-yellow-100", hoverTextColor: "hover:text-yellow-800", statusText: "Above median", Icon: SunIcon };
  } else if (percentile >= 25) {
    return { bgColor: "bg-green-100", textColor: "text-green-700", hoverBgColor: "hover:bg-green-100", hoverTextColor: "hover:text-green-700", statusText: "Top 25%", Icon: ShieldCheckIcon };
  } else {
    return { bgColor: "bg-indigo-100", textColor: "text-indigo-700", hoverBgColor: "hover:bg-indigo-100", hoverTextColor: "hover:text-indigo-700", statusText: "Top 10%", Icon: StarIcon };
  }
};

const StatusPill = ({ userPercentile, metricName }) => {
  let defaultBgColor = "bg-gray-100";
  let defaultTextColor = "text-gray-600";
  let statusText = "Unavailable";
  let StatusIcon = ExclamationCircleIcon;

  let hoverBgColor = "";
  let hoverTextColor = "";

  if (userPercentile != null && !isNaN(userPercentile)) {
    const isMetricInverted = invertedMetrics.includes(metricName);
    const status = isMetricInverted ? getStatusForInvertedMetric(userPercentile) : getStatusForNormalMetric(userPercentile);

    defaultBgColor = "bg-gray-100";
    defaultTextColor = "text-gray-600";
    hoverBgColor = status.hoverBgColor;
    hoverTextColor = status.hoverTextColor;
    statusText = status.statusText;
    StatusIcon = status.Icon;
  }

  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-full ${defaultBgColor} px-2 py-1 text-xs font-medium ${defaultTextColor} ${hoverBgColor} ${hoverTextColor}`}>
      <StatusIcon className="h-4 w-4" aria-hidden="true" />
      {statusText}
    </span>
  );
};

export default StatusPill;
