import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { stats, columnMapping } from "../../util/MetricsConfig";
import poweredByPeopleMetrics from "../../images/powered-by-people-metrics.png";

const getMetricStatus = (value, benchmark) => {
  if (value === "" || value === undefined) return "Not measured";
  const numValue = parseFloat(value);
  const numBenchmark = parseFloat(benchmark);
  if (isNaN(numValue) || isNaN(numBenchmark)) return "Not measured";
  
  const difference = Math.abs(numValue - numBenchmark);
  const threshold = numBenchmark * 0.1; // 10% threshold

  if (difference <= threshold) return "On track";
  return numValue > numBenchmark ? "Doing well" : "Flagged";
};

const generateCSVData = (userMetricsData, hiddenMetrics, medians) => {
  const headers = ["Metric Name", "Area", "Benchmark", "Frequency"];
  
  // Get all unique year-quarter combinations
  const periods = [...new Set(userMetricsData.map(data => `${data.year} ${data.time_period}`))].sort();
  headers.push(...periods);

  const rows = stats
    .filter(metric => !hiddenMetrics.includes(metric.name))
    .map(metric => {
      const columnName = columnMapping[metric.name];
      const row = [
        metric.name,
        metric.metricType || "", // Add the Area column (People or Talent)
        medians ? medians[metric.name] || "" : "", // Use median as benchmark
        metric.frequency || "Quarterly"
      ];

      // Add data for each period
      periods.forEach(period => {
        const [year, quarter] = period.split(' ');
        const dataPoint = userMetricsData.find(data => 
          data.year.toString() === year && data.time_period === quarter
        );
        row.push(dataPoint ? dataPoint[columnName] || "" : "");
      });

      return row;
    });

  return [headers, ...rows];
};

const exportToExcel = async (userMetricsData, hiddenMetrics, medians) => {
  const csvData = generateCSVData(userMetricsData, hiddenMetrics, medians);
  
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Metrics Data', {
    views: [{ showGridLines: false }] // This removes gridlines
  });

  // Add data to worksheet
  worksheet.addRows(csvData);

  // Set column widths
  const colWidths = [30, 15, 20, 15, ...Array(csvData[0].length - 4).fill(15)];
  worksheet.columns = colWidths.map((width, index) => ({ width, key: index + 1 }));

  // Add a title row
  worksheet.spliceRows(1, 0, ['PeopleMetrics.fyi Dashboard']);
  const lastCol = worksheet.columnCount;
  worksheet.mergeCells(1, 1, 1, lastCol);
  const titleCell = worksheet.getCell('A1');
  titleCell.font = { size: 20, bold: true, color: { argb: 'FFFFFFFF' } };
  titleCell.alignment = { horizontal: 'center' };
  titleCell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF4F46E5' } // Reverted to match column header color (indigo)
  };

  // Style the header row
  const headerRow = worksheet.getRow(2);
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF4F46E5' } // Darker indigo color
    };
    cell.alignment = { horizontal: 'center' };
  });

  // Add borders to all cells
  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Style function
  const getStyle = (color) => ({
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: color }
  });

  // Color definitions
  const colors = {
    grey: 'FFD3D3D3',
    green: 'FF90EE90',
    blue: 'FFADD8E6',
    red: 'FFFFA07A'
  };

  // Color code the data cells based on their status
  csvData.forEach((row, rowIndex) => {
    if (rowIndex > 0) { // Skip header row
      const benchmark = row[2]; // Benchmark is now in the 3rd column
      for (let colIndex = 4; colIndex < row.length; colIndex++) { // Start from the 5th column (index 4)
        const value = row[colIndex];
        const status = getMetricStatus(value, benchmark);
        let fill;
        switch (status) {
          case "Not measured": fill = colors.grey; break;
          case "Doing well": fill = colors.green; break;
          case "On track": fill = colors.blue; break;
          case "Flagged": fill = colors.red; break;
          default: fill = 'FFFFFFFF'; // White
        }
        worksheet.getCell(rowIndex + 2, colIndex + 1).fill = getStyle(fill);
      }
    }
  });

  // Add conditional formatting (only for data cells, not headers)
  const dataRange = worksheet.getCell(`E3:${worksheet.getColumn(worksheet.columnCount).letter}${worksheet.rowCount}`);
  worksheet.addConditionalFormatting({
    ref: dataRange.address,
    rules: [
      {
        type: 'cellIs',
        operator: 'greaterThan',
        formulae: ['0'],
        style: { font: { color: { argb: 'FF006400' } } },
      },
      {
        type: 'cellIs',
        operator: 'lessThan',
        formulae: ['0'],
        style: { font: { color: { argb: 'FF8B0000' } } },
      }
    ]
  });

  // Add some space after the table
  const spacerRows = 2;
  for (let i = 0; i < spacerRows; i++) {
    worksheet.addRow([]);
  }

  // Add a more prominent key
  const keyRowStart = worksheet.rowCount + 1;
  worksheet.getCell(`A${keyRowStart}`).value = 'Key:';
  worksheet.getCell(`A${keyRowStart}`).font = { bold: true, size: 12 };

  const keyItems = [
    { text: 'Not measured', color: colors.grey },
    { text: 'Doing well compared to benchmark', color: colors.green },
    { text: 'On track - at or around benchmark', color: colors.blue },
    { text: 'Flagged against benchmark', color: colors.red }
  ];

  keyItems.forEach((item, index) => {
    const row = keyRowStart + index + 1;
    worksheet.getCell(`A${row}`).value = item.text;
    worksheet.getCell(`A${row}`).fill = getStyle(item.color);
    worksheet.getCell(`A${row}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });

  // Add image next to the key
  const response = await fetch(poweredByPeopleMetrics);
  const imageBuffer = await response.arrayBuffer();

  const imageId = workbook.addImage({
    buffer: imageBuffer,
    extension: 'png',
  });

  worksheet.addImage(imageId, {
    tl: { col: 2, row: keyRowStart }, // Moved 2 columns to the left
    ext: { width: 200, height: 67 } // Adjusted size to be more compact
  });

  // Add hyperlink next to the image
  worksheet.getCell(`F${keyRowStart + 2}`).value = { // Moved 2 columns to the left
    text: 'www.peoplemetrics.fyi',
    hyperlink: 'https://www.peoplemetrics.fyi'
  };
  worksheet.getCell(`F${keyRowStart + 2}`).font = { // Moved 2 columns to the left
    color: { argb: 'FF0000FF' }, // Blue color for the hyperlink
    underline: true
  };

  // Adjust column widths
  worksheet.getColumn('A').width = 40; // Key column
  for (let i = 2; i <= 5; i++) { // Adjusted range to match new position
    worksheet.getColumn(i).width = 10; // Columns for image
  }
  worksheet.getColumn('F').width = 25; // Link column (moved 2 columns to the left)

  // Align content
  worksheet.getCell(`F${keyRowStart + 2}`).alignment = { vertical: 'middle', horizontal: 'center' };

  // Generate and save Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, "peoplemetrics_dashboard_export.xlsx");
};

export const ExportDataButton = ({ userMetricsData, hiddenMetrics, filteredData, medians, isLoading }) => {
  const handleExport = async () => {
    if (isLoading) {
      alert("Please wait while the data is being prepared.");
      return;
    }
    if (!userMetricsData || userMetricsData.length === 0) {
      alert("No data available to export. Please ensure data is loaded.");
      return;
    }
    await exportToExcel(userMetricsData, hiddenMetrics, medians);
  };

  return (
    <button
      onClick={handleExport}
      className={`
        inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm
        ${isLoading || !userMetricsData || userMetricsData.length === 0
          ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
          : 'bg-white text-gray-500 hover:bg-indigo-400 hover:text-white hover:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400'
        }
        transition-colors duration-200
      `}
      disabled={isLoading || !userMetricsData || userMetricsData.length === 0}
    >
      {isLoading ? (
        <>
          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Preparing Data...
        </>
      ) : (
        <>
          <ArrowDownTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Download Dashboard .xls
        </>
      )}
    </button>
  );
};