import React from "react";
import Meta from "../components/settings/Meta";
import { requireAuth } from "./../util/auth";
import OnboardingSteps from "../components/onboarding/OnboardingSteps";

function OnboardingPage(props) {
  return (
    <>
      <Meta title="Onboarding" />
     <OnboardingSteps>
     </OnboardingSteps>
    </>
  );
}

export default requireAuth(OnboardingPage);