import {
    LockOpenIcon,
    CalculatorIcon,
    ChartBarSquareIcon
  } from '@heroicons/react/20/solid'

  import Button from "../cosmetics/Button";

const features = [
    {
      name: 'Choose your metrics',
      description: 'Select from 19+ People and Talent metrics to benchmark against.',
      icon: ChartBarSquareIcon,
    },
    {
      name: 'Add your data points',
      description: 'Calculate your key HR and people-related data.',
      icon: CalculatorIcon,
    },
    {
      name: 'Unlock benchmarks',
      description: 'See exactly where your opportunities lie and where you are excelling.',
      icon: LockOpenIcon,
    },
    
  ]

export default function HowItWorksSection() {
  return (
    <div className="bg-transparent mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8 pb-20">
    <div className="mx-auto max-w-2xl lg:text-center">
      <h2 className="text-base font-semibold leading-7 text-indigo-600">How it works</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Get started in 3 easy steps
      </p>
      <p className="mt-6 text-lg leading-8 text-gray-600">
        Start accessing a dynamic benchmark of over 500+ startups and scaleups.
      </p>
    </div>
    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
        {features.map((feature) => (
          <div key={feature.name} className="relative pl-16">
            <dt className="text-base font-semibold leading-7 text-gray-900">
              <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              {feature.name}
            </dt>
            <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  </div>
  )
}