import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const NewFeatureFlag = ({ tooltipText }) => {
  return (
    <div className="relative flex items-center group">
      <span className="bg-indigo-100 text-indigo-500 text-xs font-medium me-2 px-2.5 py-0.5 rounded inline-flex items-center">
        <InformationCircleIcon className="w-4 h-4 mr-1" />
        New Feature
      </span>
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block bg-indigo-400 text-white text-xs font-medium py-2 px-4 rounded-lg shadow-lg transition-opacity duration-300 opacity-0 group-hover:opacity-100 w-64 text-center">
        {tooltipText}
        <div className="absolute left-1/2 transform -translate-x-1/2 top-full w-0 h-0 border-l-8 border-r-8 border-t-8 border-transparent border-t-indigo-400"></div>
      </div>
    </div>
  );
};

export default NewFeatureFlag;
